import { GetOcrResultPagesByOcrResultIdQuery } from 'src/graphql/generated'

export type DocumentProps = {
  index: number
  documentId: number
  tenantId: number
  ocrResultId: number
  clientName: string | null | undefined
  documentTypeId: number | null | undefined
  documentNumber: string | null | undefined
  numberOfPages: number
  createdAt: number
  dealtAt: number | null | undefined
  totalMoneyAmount: number | null | undefined
  status: number
  isDownloadedCsv: number
  isChecked: number
  fileName: string
}

type Props = Omit<DocumentProps, 'index'>
export const factoryDocument = ({
  documentId,
  numberOfPages,
  createdAt,
  isDownloadedCsv,
  fileName,
  tenantId,
  ocrResultId,
  documentTypeId,
  documentNumber,
  clientName,
  dealtAt,
  totalMoneyAmount,
  isChecked,
  status,
}: Props) => {
  return {
    __typename: 'Document',
    id: documentId,
    numberOfPages: numberOfPages,
    createdAt: createdAt,
    isDownloadedCsv: isDownloadedCsv,
    fileName: fileName,
    documentOcr: {
      __typename: 'DocumentOcr',
      documentId: documentId,
      tenantId: tenantId,
      ocrResultId: ocrResultId,
      documentTypeId: documentTypeId,
      documentNumber: documentNumber,
      clientName: clientName,
      dealtAt: dealtAt,
      totalMoneyAmount: totalMoneyAmount,
      isChecked: isChecked,
      status: status,
    },
  } as const
}

export type ocrResultPagesType =
  GetOcrResultPagesByOcrResultIdQuery['ocrResultPagesByOcrResultId']
export type nonNullableOcrResultPagesType = Exclude<
  ocrResultPagesType,
  null | undefined
>
export type ocrResultPageType = nonNullableOcrResultPagesType[number]
