import { MenuItem } from '@chakra-ui/react'
import { FiCheckCircle } from 'react-icons/fi'
import { useSetRecoilState } from 'recoil'
import {
  GetDocumentsByPageQuery,
  useUpdateDocumentOcrIsCheckedMutation,
} from 'src/graphql/generated'
import { documentsState } from 'src/state'

type DetailMenuItemProps = {
  documentId: number
  isChecked: number
  isDisabled: boolean
}
function CheckMenuItem({
  documentId,
  isChecked,
  isDisabled,
}: DetailMenuItemProps) {
  const setDocuments = useSetRecoilState(documentsState)
  const updateIsChecked = useUpdateDocumentOcrIsCheckedMutation()
  type documentsType = GetDocumentsByPageQuery['documentsByPage']

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    // チェック済みフラグの更新
    updateIsChecked.mutate(
      {
        documentId: documentId,
        isChecked: isChecked === 1 ? 0 : 1,
      },
      {
        async onSuccess(data) {
          // メニューが閉じている間に再レンダリングされるとカクツクため待つ
          await new Promise((resolve) => setTimeout(resolve, 500))

          // 再レンダリング
          setDocuments((documents) => {
            const clonedDocuments: documentsType = structuredClone(documents)
            if (!clonedDocuments) return documents
            const index = clonedDocuments?.findIndex(
              (document) => document.id === documentId,
            )
            if (index === -1) return documents
            clonedDocuments[index].documentOcr = data.updateDocumentOcrIsChecked
            return clonedDocuments
          })
        },
      },
    )
  }
  return (
    <>
      <MenuItem
        icon={<FiCheckCircle />}
        isDisabled={isDisabled}
        onClick={(e) => handleClick(e)}
      >
        {isChecked === 1 ? '未確認にする' : '確認済にする'}
      </MenuItem>
    </>
  )
}

export default CheckMenuItem
