import {
  Box,
  Center,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getUserProfile } from 'src/api/auth'
import { useGetTenantByPageQuery } from '../../graphql/generated'
import TopHeading from '../templates/TopHeading'
import TableBody from '../views/bpo/tenant/TableBody'
import HeaderWithSidebar from '../views/HeaderWithSidebar'
import Pagination from '../views/Pagination'
import { userProfileQueryKey } from 'src/values'
import { profileState } from 'src/state'
import { useSetRecoilState } from 'recoil'

function TenantList() {
  const navigate = useNavigate()

  const setProfile = useSetRecoilState(profileState)

  useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async (profile) => {
      if (profile.tenantId.toString() != process.env.REACT_APP_TOMORAKU_ID) {
        window.scrollTo(0, 0)
        navigate('/documents')
      }

      setProfile(profile)
    },
    refetchOnWindowFocus: false,
  })

  const { page } = useParams()
  const pageNum = (() => {
    if (!page) return 1
    const pageNum = parseInt(page, 10)
    return isNaN(pageNum) ? 1 : pageNum
  })()

  const fetchTenants = (pageNum: number) => {
    const { data } = useGetTenantByPageQuery({
      page: pageNum,
    })
    return data
  }
  const tenantData = fetchTenants(pageNum)
  const tenants = tenantData?.tenantByPage
  const allTenantCount = tenantData?.tenantCount

  return (
    <HeaderWithSidebar>
      <TopHeading title="テナント一覧" />
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>企業名</Th>
              <Th>未確認件数</Th>
            </Tr>
          </Thead>
          <Tbody>{tenants && <TableBody tenant={tenants} />}</Tbody>
        </Table>
      </TableContainer>
      <Box mt={4}>
        <Center>
          {allTenantCount && (
            <Pagination
              allCount={allTenantCount}
              currentPage={pageNum}
              countPerPage={200}
              link="/bpo/tenant/list"
            />
          )}
        </Center>
      </Box>
    </HeaderWithSidebar>
  )
}

export default TenantList
