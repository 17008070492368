import { authFetchApi } from 'src/values'
import Encoding from 'encoding-japanese'

type PostDownloadCsvProps = {
  documentIds: number[]
  documentTypeId: number
  fileName: string
  tenantId?: number
}

const conv_string2shiftJis = (data: string) => {
  // ビュレット記号（•）を中点（・）に置き換え
  data = data.replace(/•/g, '・')
  data = data.replace(/·/g, '・')
  data = data.replace(/¥/g, '￥')

  return data
}

export const postDownloadCsv = async (data: PostDownloadCsvProps) => {
  const response = await authFetchApi(
    'download-csv',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    true,
  )

  return response
}

export const onSuccessDownloadCsv = async (
  data: Response,
  variables: PostDownloadCsvProps,
) => {
  new Promise((resolve) => {
    if (!data.body) return
    const reader = data.body.getReader()
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close()
              return
            }
            controller.enqueue(value)
            push()
          })
        }
        push()
      },
    })
    const result = new Response(stream, {
      headers: { 'Content-Type': 'text/csv' },
    }).text()
    result.then((data) => {
      if (data.length == 0) return
      const anchor = document.createElement('a')

      // csvデータはsift-jisに変換
      data = conv_string2shiftJis(data)

      const unicodeArray = Encoding.stringToCode(data)
      const shiftJisArray = Encoding.convert(unicodeArray, 'SJIS', 'UNICODE')
      const shiftJisUint8Array = new Uint8Array(shiftJisArray)

      anchor.href = URL.createObjectURL(
        new Blob([shiftJisUint8Array], { type: 'text/csv' }),
      )
      anchor.download = variables.fileName
      document.body.appendChild(anchor)
      anchor.click()
      URL.revokeObjectURL(anchor.href)
      document.body.removeChild(anchor)
      resolve
    })
  })
}
