import { Box, Flex, Input, Text } from '@chakra-ui/react'
import { useState, ChangeEvent } from 'react'
import { validateAccept } from 'src/values/accept'

interface UploadBoxProps {
  id: string
  label: string
  accept: string
  required: boolean
  multiple?: boolean
  onChangeProps: (e: ChangeEvent<HTMLInputElement>) => void
}

const useUploadBox = () => {
  const [isDragged, setIsDragged] = useState(false)
  const onDragEnter = () => setIsDragged(true)
  const onDragOver = () => {
    setIsDragged(true)
  }
  const onDragLeave = () => {
    setIsDragged(false)
  }
  const onDrop = () => {
    setIsDragged(false)
  }

  return {
    isDragged,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
  }
}
function UploadBox({
  id,
  label,
  accept,
  required,
  multiple,
  onChangeProps,
}: UploadBoxProps) {
  const { isDragged, onDragEnter, onDragOver, onDragLeave, onDrop } =
    useUploadBox()
  const [fileNameList, setFileNameList] = useState<string[]>([])
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setFileNameList([])
      return
    }
    const list = []
    for (let i = 0; i < e.target.files?.length; i++) {
      const file = e.target.files.item(i)
      const fileName = file?.name || ''
      const fileType = file?.type || ''
      if (!validateAccept(accept, fileName, fileType)) {
        // 1個でも accept されてないファイルがあれば全てクリアする
        setFileNameList([])
        return
      }
      list.push(fileName)
    }
    setFileNameList(list)
    onChangeProps(e)
  }
  return (
    <Box
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Box
        borderWidth="1px"
        borderRadius="lg"
        position={'relative'}
        p={'16px'}
        backgroundColor={isDragged ? 'blue.50' : ''}
      >
        <Flex>
          <Box textAlign={'center'} color={'gray.500'}>
            {fileNameList.length > 0 ? (
              fileNameList.map((fileName, i) => (
                <Text key={i} textAlign={'left'}>
                  {fileName}
                </Text>
              ))
            ) : (
              <Text>
                クリックかドラッグ＆ドロップで
                <br />
                {label}を追加する
              </Text>
            )}
          </Box>
        </Flex>
        <Input
          id={id}
          type="file"
          accept={accept}
          required={required}
          multiple={multiple}
          opacity={0}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          cursor={'pointer'}
          onChange={onChange}
        />
      </Box>
    </Box>
  )
}
export default UploadBox
