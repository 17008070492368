import { atom } from 'recoil'
import { GetDocumentsByPageQuery } from 'src/graphql/generated'

type Documents = GetDocumentsByPageQuery['documentsByPage']
export const documentsState = atom<Documents>({
  key: 'documentList.documents',
  default: [],
})

export const documentCountState = atom<number>({
  key: 'documentList.documentCount',
  default: 0,
})
