import { Button, Spinner } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { getOcrRawResultObject } from 'src/api/rawOcrResultJsonUrl'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { postOcrExecute } from 'src/api/ocrExecute'
import {
  GetOcrResultPagesByOcrResultIdQuery,
  useGetOcrResultPagesByOcrResultIdQuery,
  useGetOcrResultPageByOcrResultIdAndPageNumberQuery,
  GetOcrResultPageByOcrResultIdAndPageNumberQuery,
} from 'src/graphql/generated'
import {
  ocrResultPagesState,
  selectOcrFormatState,
  currentRotatestate,
  currentOcrResultIdState,
  canvasRatioState,
  boundingBoxType,
  bboxListState,
  currentPageNumState,
  ocrRetryPageIdState,
  ocrRetryCurrentPageNum,
  editStateInCurrentPage,
  profileState,
  allPageOcrParsers,
  numberOfPageState,
} from 'src/state'
import { getBpoTenantId } from 'src/values/bpoTenantId'
import { ocrResultPageType } from 'src/values/document'

type RetryOcrExecuteProps = {
  documentId: number
}

export const RetryOcrExecuteButton = ({ documentId }: RetryOcrExecuteProps) => {
  // 実行フラグ
  const [isExecute, setIsExecute] = useState(false)

  // ocr result id
  const currentOcrResutId = useRecoilValue(currentOcrResultIdState)

  // pdfをレンダリングしているcanvas情報
  const canvasRatio = useRecoilValue(canvasRatioState)

  // 新しいocr result id
  const [newOcrResultPageIdState, setNewOcrResultPageId] = useState({
    id: -1,
    status: 0,
  })

  // polling回数
  const [countPolling, setCountPolling] = useState(0)

  // tenant id
  const profile = useRecoilValue(profileState)
  const tenantId = getBpoTenantId() || profile.tenantId

  // 回転と帳票パターン情報
  const [ocrFormatInfo, setOcrFormatInfo] = useRecoilState(selectOcrFormatState)
  const currentRotate = useRecoilValue(currentRotatestate)

  // 座標情報
  const setBBoxState = useSetRecoilState(bboxListState)

  // current page number
  const [currentPageNum, setCurrentPageNum] =
    useRecoilState(currentPageNumState)
  const [tmpCurrentPageNum, setTmpCurrentPageNum] = useRecoilState(
    ocrRetryCurrentPageNum,
  )
  useEffect(() => {
    setTmpCurrentPageNum(currentPageNum)
  }, [currentPageNum])

  // ocr result page id
  const [currentOcrReultPages, setOcrResultPagesState] =
    useRecoilState(ocrResultPagesState)
  const [tmpOldOcrResultPageId, setCurrentOcrResultPageId] =
    useRecoilState(ocrRetryPageIdState)

  // 編集済みフラグの設定
  const markEdited = useSetRecoilState(editStateInCurrentPage)

  // 生のocr読み取り結果更新用のmutation
  const getJsonMutation = useMutation(getOcrRawResultObject, {})
  const setocrParsers = useSetRecoilState(allPageOcrParsers)

  // PDFの合計ページ数
  const numberOfPages = useRecoilValue(numberOfPageState)

  const cretateBBoxs = (ocrResultPage: ocrResultPageType | undefined) => {
    const ocrResultItems = ocrResultPage?.ocrResultItems
    const ocrReultBBoxs: boundingBoxType[] = []
    ocrResultItems?.forEach((data) => {
      if (
        data &&
        data.xMin &&
        data.xMax &&
        data.yMin &&
        data.yMax &&
        data.ocrItem?.id &&
        data.ocrFormatItemId &&
        data.ocrItemId
      ) {
        ocrReultBBoxs.push({
          id: data.id,
          text: data.displayText,
          ocrFormatItemId: data.ocrFormatItemId,
          ocrItemId: data.ocrItemId,
          ocrItemName: data.ocrItem?.name,
          xmin: data.xMin * canvasRatio.width,
          xmax: data.xMax * canvasRatio.width,
          ymin: data.yMin * canvasRatio.height,
          ymax: data.yMax * canvasRatio.height,
          rawXmin: data.xMin,
          rawXmax: data.xMax,
          rawYmin: data.yMin,
          rawYmax: data.yMax,
          isEdit: false,
          isMulchSelected: false
        })
      }
    })
    return ocrReultBBoxs
  }

  const setStates = (data: GetOcrResultPagesByOcrResultIdQuery) => {
    if (newOcrResultPageIdState.id == -1) return
    if (newOcrResultPageIdState.id == tmpOldOcrResultPageId) return

    if (isExecute && newOcrResultPageIdState.status == 20) {
      const ocrResultPages = !data.ocrResultPagesByOcrResultId
        ? {}
        : data.ocrResultPagesByOcrResultId.reduce(
            (acc: { [key: number]: typeof cur }, cur) => {
              if (!cur || !cur.pageNumber) return acc
              acc[cur.pageNumber] = cur
              return acc
            },
            {},
          )
      setOcrResultPagesState(ocrResultPages)

      const newBBoxInfo = Object()
      const newOcrFormatInfo = Object()

      for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
        const newResult = cretateBBoxs(ocrResultPages[pageNumber])
        newBBoxInfo[pageNumber] = newResult

        // 帳票パターン情報の更新
        const tmp_ocrResult = ocrResultPages[pageNumber]
        if (tmp_ocrResult) {
          newOcrFormatInfo[pageNumber] = {
            id: tmp_ocrResult.ocrFormatId,
            fileName: tmp_ocrResult.ocrFormat?.fileName,
            clientName: tmp_ocrResult.ocrFormat?.clientName,
            documentTypeId: tmp_ocrResult.documentTypeId
          }
        } else {
          newOcrFormatInfo[pageNumber] = {
            id: -1,
            fileName: '',
            clientName: '',
            documentTypeId: -1
          }
        }

        setBBoxState(newBBoxInfo)
        setOcrFormatInfo(newOcrFormatInfo)
        markEdited(true)
      }

      setIsExecute(false)
      setCurrentPageNum(tmpCurrentPageNum)
    }
  }

  const ocrResultId = currentOcrResutId
  const { refetch } = useGetOcrResultPagesByOcrResultIdQuery(
    {
      tenantId,
      ocrResultId,
    },
    {
      enabled: isExecute,
      onSuccess(data) {
        setStates(data)
      },
    },
  )

  useGetOcrResultPageByOcrResultIdAndPageNumberQuery(
    {
      tenantId: tenantId,
      ocrResultId: currentOcrResutId,
      pageNumber: currentPageNum,
    },
    {
      refetchInterval: isExecute ? 1000 : 0,
      onSuccess(data) {
        pollingDocumentOcrFunc(data)
      },
    },
  )

  const pollingDocumentOcrFunc = (
    ocrResultPageStateData:
      | GetOcrResultPageByOcrResultIdAndPageNumberQuery
      | undefined,
  ) => {
    /////////////////////
    // polling関数
    /////////////////////

    if (!isExecute) {
      return
    }

    setCountPolling(countPolling + 1)

    // ポーリング時間が3分以上であれば終了する
    if (countPolling > 180) {
      setIsExecute(false)
      return
    }

    if (tmpOldOcrResultPageId) {
      const pollingOcrResultPageData =
        ocrResultPageStateData?.ocrResultPagesByOcrResultIdAndPageNumber
      const newOcrResultPageId = pollingOcrResultPageData?.id
        ? pollingOcrResultPageData?.id
        : -1

      const pollingOcrStatus =
        pollingOcrResultPageData?.status != undefined
          ? pollingOcrResultPageData?.status
          : 0

      // 新しいocr result idが存在しない場合はポーリング続行
      if (newOcrResultPageId == -1) {
        return
      }

      if (newOcrResultPageId != tmpOldOcrResultPageId) {
        setNewOcrResultPageId({
          id: newOcrResultPageId,
          status: pollingOcrStatus,
        })

        if (pollingOcrStatus == 20) {
          refetch()

          // 生のocr読み取り結果を更新
          getJsonMutation.mutate(
            {
              tenantId: tenantId,
              ocrResultId: ocrResultId,
              pageNumber: currentPageNum,
            },
            {
              onSuccess: (data) => {
                setocrParsers((ocrParsers) => {
                  return {
                    ...ocrParsers,
                    [currentPageNum.toString()]: data,
                  }
                })
              },
            },
          )
        }
      }
    }
  }

  const executeOcr = async () => {
    // ocr 実行パラメータの初期化
    setIsExecute(true)
    setNewOcrResultPageId({ id: -1, status: 0 })
    setTmpCurrentPageNum(currentPageNum)

    // 解析に失敗して currentOcrReultPages?.[currentPageNum] がない場合は -1 をセットする
    setCurrentOcrResultPageId(
      currentOcrReultPages?.[currentPageNum]?.id === undefined
        ? -1
        : currentOcrReultPages?.[currentPageNum]?.id,
    )

    const body = {
      tenantId: tenantId,
      documentId: documentId,
      ocrFormatId: ocrFormatInfo[currentPageNum].id || -1, // -1でリクエストすると自動でパターンを検索するモードになる
      rotate: currentRotate[currentPageNum].editRotate,
      pageNumber: currentPageNum,
    }

    const executeResult = await postOcrExecute(body)

    if (!executeResult.isSuccess) {
      setIsExecute(false)
    } else {
      setBBoxState({})
    }
  }

  return (
    <>
      {isExecute && <Spinner />}
      <Button onClick={async () => await executeOcr()}>OCR再実行</Button>
    </>
  )
}
