import {
  Flex,
  FlexProps,
  Box,
  BoxProps,
  Link,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FiFileText,
  FiTool,
  FiChevronsLeft,
  FiList,
  FiUpload,
  FiSettings,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import AppHeader from '../templates/AppHeader'
import { ReactNode } from 'react'
import { useRecoilValue } from 'recoil'
import { profileState } from 'src/state'
import { useGetTenantQuery } from 'src/graphql/generated'

import LogRocket from 'logrocket'

interface LinkItemProps {
  name: string
  icon: IconType
  path: string
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: '新規帳票登録',
    icon: FiUpload,
    path: '/documents/register',
  },
  { name: '帳票一覧', icon: FiFileText, path: '/documents' },
  { name: 'パスワード変更', icon: FiSettings, path: '/settings/password' },
]

const BpoLinkItems: Array<LinkItemProps> = [
  {
    name: 'BPOテナント一覧',
    icon: FiList,
    path: '/bpo/tenant/list',
  },
  {
    name: 'パターン登録',
    icon: FiTool,
    path: '/bpo/pattern/register',
  },
]

export default function HeaderWithSidebar({
  children,
}: {
  children: ReactNode
}) {
  const { onClose } = useDisclosure()
  return (
    <>
      <AppHeader />
      <Box minH="100vh">
        <SidebarContent onClose={() => onClose} display="block" />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {children}
        </Box>
      </Box>
    </>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const profile = useRecoilValue(profileState)
  const isBpoUser =
    profile.tenantId.toString() === process.env.REACT_APP_TOMORAKU_ID

  const { isLoading, data } = useGetTenantQuery({})
  const tenantName = data?.tenant?.name

  LogRocket.identify(profile.userId.toString())

  return (
    <Box
      transition="3s ease"
      bg="#EDEDED"
      color="black"
      w="60"
      pos="fixed"
      h="full"
      top="4.5rem"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="4" justifyContent="space-between">
        <Box p="4">{!isLoading && <Text as="b">{tenantName}</Text>}</Box>
        <IconButton
          bg="#EDEDED"
          aria-label="Close Drawer"
          icon={<FiChevronsLeft />}
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          name={link.name}
          icon={link.icon}
          path={link.path}
        >
          {link.name}
        </NavItem>
      ))}
      {isBpoUser &&
        BpoLinkItems.map((link) => (
          <NavItem
            key={link.name}
            name={link.name}
            icon={link.icon}
            path={link.path}
          >
            {link.name}
          </NavItem>
        ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  name: string
  path: string
}
const NavItem = ({ icon, name, path, ...rest }: NavItemProps) => {
  return (
    <Link href={path} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'rgba(47, 107, 159, 0.31)',
          color: '#343FA1',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: '#343FA1',
            }}
            as={icon}
          />
        )}
        {name}
      </Flex>
    </Link>
  )
}
