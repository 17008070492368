import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { allCheckboxActionState, checkedListState } from 'src/state'
import { GetTenantByPageQuery } from '../../../../graphql/generated'
import TableItem from './TableItem'

type tenantType = GetTenantByPageQuery['tenantByPage']
type TableBodyProps = {
  tenant: tenantType
}
function TableBody({ tenant }: TableBodyProps) {
  const setCheckedList = useSetRecoilState(checkedListState)
  const dispatch = useSetRecoilState(allCheckboxActionState)
  useEffect(() => {
    setCheckedList(Array(tenant?.length).fill(false))
    dispatch({ action: 'off' })
  }, [tenant])

  return (
    <>
      {tenant &&
        tenant.map(
          (item, index) =>
            item &&
            item.name && (
              <TableItem key={index} index={item.id} tenantName={item.name} />
            )
        )}
    </>
  )
}

export default TableBody
