import {
  CloseButton,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import {
  useSetRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useRecoilCallback,
} from 'recoil'
import DocumentEditContainer from 'src/components/templates/documents/detailEditModal/DocumentEditContainer'
import {
  ocrResultPagesState,
  selectOcrFormatState,
  bboxListState,
  currentRotatestate,
  OcrFormatInterface,
  currentOcrResultIdState,
  allPageOcrParsers,
  numberOfPageState,
  detailModalPropsState,
  currentPageNumState,
  editStateInCurrentPage,
  isEditMulchBboxState,
  mulchEditBboxState,
  mulchEditBboxRatioState,
} from 'src/state'
import {
  useGetOcrResultPagesByOcrResultIdQuery,
  GetOcrResultPagesByOcrResultIdQuery,
} from 'src/graphql/generated'
import { formatTime } from 'src/util'
import { getDocumentOcrStatusLabel } from 'src/values'
import { useEffect } from 'react'
import {
  historyState,
  useBackButtonGuard,
} from 'src/hooks/detailEditModal/useBackButtonGuard'

type DetailModalProps = {
  isOpen: boolean
  onClose: () => void
}

function DetailModal({ isOpen, onClose }: DetailModalProps) {
  const confirmMessage = '編集から離れますか？未保存の変更は破棄されます。'

  const setNumberOfPages = useSetRecoilState(numberOfPageState)
  const setCurrentRotate = useSetRecoilState(currentRotatestate)
  const setOcrResultPagesState = useSetRecoilState(ocrResultPagesState)
  const setOcrFormatInfos = useSetRecoilState(selectOcrFormatState)
  const setboundingBoxTypes = useSetRecoilState(bboxListState)
  const setOcrParsers = useSetRecoilState(allPageOcrParsers)
  const resetCurrentPageNum = useResetRecoilState(currentPageNumState)
  // 編集済みフラグ
  const resetEditState = useResetRecoilState(editStateInCurrentPage)
  // 複数座標選択bbox
  const resetIsEditMulchBboxState = useResetRecoilState(isEditMulchBboxState)
  const resetMulchEditBboxState = useResetRecoilState(mulchEditBboxState)
  const resetMulchEditBboxRatioState = useResetRecoilState(
    mulchEditBboxRatioState,
  )

  const setCurrentOcrResultIdState = useSetRecoilState(currentOcrResultIdState)

  const detailModalProps = useRecoilValue(detailModalPropsState)
  if (!detailModalProps) return null
  const {
    tenantId,
    documentId,
    ocrResultId,
    fileName,
    createdAt,
    numberOfPages,
    status,
    isDownloadedCsv,
  } = detailModalProps

  useEffect(() => {
    setNumberOfPages(numberOfPages)
  }, [numberOfPages])

  useEffect(() => {
    // ocrResultIdが変わるたびに読み取り詳細情報を初期化
    setCurrentOcrResultIdState(ocrResultId)
    setCurrentRotate({})
    setboundingBoxTypes({})
    setOcrParsers(null)
  }, [ocrResultId])

  const setInitStates = (
    data: GetOcrResultPagesByOcrResultIdQuery | null | undefined,
  ) => {
    if (data) {
      const obj = !data.ocrResultPagesByOcrResultId
        ? {}
        : data.ocrResultPagesByOcrResultId.reduce(
            (acc: { [key: number]: typeof cur }, cur) => {
              if (!cur || !cur.pageNumber) return acc
              acc[cur.pageNumber] = cur
              return acc
            },
            {},
          )
      setOcrResultPagesState(obj)

      // 帳票パターン情報を登録
      const initOcrFormatInfo: { [key: number]: OcrFormatInterface } = {}

      data.ocrResultPagesByOcrResultId?.forEach((v) => {
        const ocrFormatId = v?.ocrFormatId ? v?.ocrFormatId : -1
        const ocrFormatFileName = '' + v?.ocrFormat?.fileName
        const ocrFormatClientName = '' + v?.ocrFormat?.clientName
        const ocrFormatDcumentType = v?.documentTypeId

        if (v?.pageNumber && ocrFormatDcumentType) {
          initOcrFormatInfo[v?.pageNumber] = {
            id: ocrFormatId,
            fileName: ocrFormatFileName,
            clientName: ocrFormatClientName,
            documentTypeId: ocrFormatDcumentType,
          }
        }
      })

      for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
        if (initOcrFormatInfo[pageNumber]) continue
        initOcrFormatInfo[pageNumber] = { id: -1, fileName: '', clientName: '' }
      }
      setOcrFormatInfos(initOcrFormatInfo)
    }
  }

  const { data, isLoading } = useGetOcrResultPagesByOcrResultIdQuery(
    {
      tenantId,
      ocrResultId,
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  )

  useEffect(() => {
    if (data) {
      setInitStates(data)
    }
  }, [data])

  const getEditStatus = useRecoilCallback<[], Promise<boolean>>(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(editStateInCurrentPage)
      },
    [],
  )

  const closeModalFunc = (onClose: () => void) => {
    onClose()

    // global stateの初期化
    resetCurrentPageNum()
    resetEditState()
    resetIsEditMulchBboxState()
    resetMulchEditBboxState()
    resetMulchEditBboxRatioState()
  }

  const handlePopstate = async () => {
    const hasEditItem = await getEditStatus()
    // ローカルではStrictモードONのためダイアログが2回表示される
    if (!hasEditItem || confirm(confirmMessage)) {
      // 破棄してよい場合、historyAPIで戻るを実行する
      window.history.back()
      return
    }
    // キャンセルの場合、 ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
    history.pushState(historyState, '', null)
  }
  useBackButtonGuard(handlePopstate)

  return (
    <>
      <Modal
        size="full"
        isOpen={isOpen}
        onClose={async () => {
          const hasEditItem = await getEditStatus()
          if (!hasEditItem || confirm(confirmMessage)) closeModalFunc(onClose)
        }}
      >
        <ModalOverlay />
        <ModalContent h="100%" overflow="hidden">
          <ModalHeader
            backgroundColor="black"
            color="white"
            pos="sticky"
            top="0"
          >
            <Flex justify={'space-between'}>
              <HStack spacing="24px">
                <Text as="span" fontSize="lg">
                  登録日時: {formatTime(new Date(createdAt))}
                </Text>
                <Text as="span" fontSize="lg">
                  枚数: {numberOfPages}
                </Text>
                <Text as="span" fontSize="lg">
                  OCR解析ステータス: {getDocumentOcrStatusLabel(status)}
                </Text>
                <Text as="span" fontSize="lg">
                  ダウンロード: {isDownloadedCsv ? '済' : '未'}
                </Text>
              </HStack>
              <CloseButton
                onClick={async () => {
                  const hasEditItem = await getEditStatus()
                  if (!hasEditItem || confirm(confirmMessage))
                    closeModalFunc(onClose)
                }}
              />
            </Flex>
          </ModalHeader>
          <ModalBody h="100%" px="0" py="0">
            {isLoading ? (
              <Spinner />
            ) : (
              <DocumentEditContainer
                tenantId={tenantId}
                documentId={documentId}
                fileName={fileName}
                closeModal={() => closeModalFunc(onClose)}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DetailModal
