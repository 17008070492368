import {
  Box,
  Button,
  Center,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import TopHeading from '../templates/TopHeading'
import HeaderWithSidebar from '../views/HeaderWithSidebar'
import { registerDocument } from 'src/api/registerDocument'
import { ChangeEvent, useState } from 'react'
import UploadBox from '../views/UploadBox'
import { getUserProfile } from 'src/api/auth'
import { useNavigate } from 'react-router-dom'
import { userProfileQueryKey } from 'src/values'
import { useSetRecoilState } from 'recoil'
import { profileState } from 'src/state'
import { compressFile } from 'src/pdfTools/compressionPdf'

function DocumentsRegister() {
  const navigate = useNavigate()
  const toast = useToast()

  const [isLoading, setLoading] = useState(false)
  const setProfile = useSetRecoilState(profileState)

  let fileList: FileList | undefined

  const registerDocumentMutation = useMutation(registerDocument, {})

  const clear = () => {
    const elem = document.getElementById('pdf') as HTMLInputElement
    elem.value = ''
    // value を空にしても onChange が発火しないため dispatch する
    elem.dispatchEvent(new Event('change', { bubbles: true }))
  }
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    fileList = e.target.files || []
  }

  const { isLoading: isProfileLoading, data: profileData } = useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async (profile) => {
      setProfile(profile)
    },
    onError: () => {
      window.scrollTo(0, 0)
      navigate('/login')
    },
    refetchOnWindowFocus: false,
  })

  const handleClickSubmit = async () => {
    setLoading(true)

    const tenantId = profileData?.tenantId // ログインしているテナントIDでリクエストする

    if (!tenantId || !fileList || fileList.length === 0) {
      toastError('ドキュメントが選択されていません')
      setLoading(false)
      return
    }

    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name
      const formData = new FormData()
      formData.append('tenantId', tenantId.toString())
      formData.append('filename', filename)

      // pdfデータはwafのblock対策で圧縮してリクエストする
      const compressionData = await compressFile(fileList[i])
      const blobPdfData = new Blob([compressionData], {
        type: 'application/zip',
      })
      formData.append('pdf', blobPdfData)

      await registerDocumentMutation.mutateAsync(formData, {
        onSuccess: () => {
          const description = `登録に成功しました。 ファイル名: ' + ${filename}`
          toastSuccess(description)
        },
        onError: () => {
          const description = `登録に失敗しました。 ファイル名: ' + ${filename}`
          toastError(description)
        },
      })
    }

    clear()
    setLoading(false)
  }

  const toastSuccess = (description: string) => {
    toast({
      description,
      status: 'success',
      isClosable: true,
    })
  }

  const toastError = (description: string) => {
    toast({
      description: description,
      status: 'error',
      isClosable: true,
    })
  }

  return (
    <HeaderWithSidebar>
      <TopHeading title="新規帳票登録" />
      {isProfileLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Box>
          <TableContainer>
            <Table align="center" variant="simple" w="1024px">
              <Tbody>
                <Tr>
                  <Td w="480px">ドキュメント（PDF）*</Td>
                  <Td w="480px">
                    <UploadBox
                      id="pdf"
                      label="ドキュメント（PDF）"
                      accept=".pdf"
                      required={true}
                      multiple={true}
                      onChangeProps={onChange}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Box mt={4}>
            <Center>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={handleClickSubmit}
              >
                新規登録
              </Button>
            </Center>
          </Box>
        </Box>
      )}
    </HeaderWithSidebar>
  )
}

export default DocumentsRegister
