import { authFetchApi } from 'src/values'

export type newOcrResultItem = {
  ocrFormatItemId: number
  ocrItemId: number
  text: string
  xmin: number
  xmax: number
  ymin: number
  ymax: number
}

export type retryPatternMatchBody = {
  tenantId: number
  documentId: number
  pageNumber: number
  documentTypeId: number
  ocrResultId: number
  ocrResultPageId: number
  newOcrResultItems: newOcrResultItem[]
}

export type retryResponce = {
  status: boolean
}

export const postRetryPatternMatch = async (
  data: retryPatternMatchBody,
): Promise<retryResponce> => {
  return await authFetchApi('retry-pattern-match', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}
