import {
  Box,
  FormLabel,
  Stack,
  HStack,
  VStack,
  CheckboxGroup,
} from '@chakra-ui/react'
import { FiChevronLeft, FiChevronRight, FiMoreHorizontal } from 'react-icons/fi'
import {
  ClientNameInput,
  DeliveryCheckbox,
  InvoiceCheckbox,
  OrderCheckbox,
  CreatedAtGteInput,
  CreatedAtLteInput,
  AnalyzingCheckbox,
  AnalyzedCheckbox,
  AnalyzeErrorCheckbox,
  IsDownloadedCsvCheckbox,
  NotIsDownloadedCsvCheckbox,
} from '../../../templates/documents/SearchPopover'
import { RefObject, forwardRef } from 'react'
import IsCheckedCheckbox from 'src/components/templates/documents/SearchPopover/IsCheckedCheckbox'
import NotIsCheckedCheckbox from 'src/components/templates/documents/SearchPopover/NotIsCheckedCheckbox'

type FormInputGroupProps = {
  clientName?: string
  createdAtGte?: number
  createdAtLte?: number
  clientNameInputRef: RefObject<HTMLInputElement>
  createdAtGteInputRef: RefObject<HTMLInputElement>
  createdAtLteInputRef: RefObject<HTMLInputElement>
}
const FormInputGroup = ({
  clientName,
  createdAtGte,
  createdAtLte,
  clientNameInputRef,
  createdAtGteInputRef,
  createdAtLteInputRef,
}: FormInputGroupProps) => {
  const ClientNameInputRef = forwardRef(ClientNameInput)
  const CreatedAtGteInputRef = forwardRef(CreatedAtGteInput)
  const CreatedAtLteInputRef = forwardRef(CreatedAtLteInput)
  return (
    <VStack spacing={4} align="left">
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          企業名
        </FormLabel>
        <ClientNameInputRef clientName={clientName} ref={clientNameInputRef} />
      </Box>
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          種別
        </FormLabel>
        <CheckboxGroup>
          <HStack spacing={5}>
            <DeliveryCheckbox />
            <InvoiceCheckbox />
            <OrderCheckbox />
          </HStack>
        </CheckboxGroup>
      </Box>
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          登録日時
        </FormLabel>
        <Stack spacing={2} direction="row">
          <CreatedAtGteInputRef
            createdAtGte={createdAtGte}
            ref={createdAtGteInputRef}
          />
          <HStack spacing={0}>
            <FiChevronLeft />
            <FiMoreHorizontal />
            <FiChevronRight />
          </HStack>
          <CreatedAtLteInputRef
            createdAtLte={createdAtLte}
            ref={createdAtLteInputRef}
          />
        </Stack>
      </Box>
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          解析ステータス
        </FormLabel>
        <CheckboxGroup>
          <HStack spacing={5}>
            <AnalyzingCheckbox />
            <AnalyzedCheckbox />
            <AnalyzeErrorCheckbox />
          </HStack>
        </CheckboxGroup>
      </Box>
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          ダウンロード
        </FormLabel>
        <CheckboxGroup>
          <HStack spacing={5}>
            <IsDownloadedCsvCheckbox />
            <NotIsDownloadedCsvCheckbox />
          </HStack>
        </CheckboxGroup>
      </Box>
      <Box>
        <FormLabel fontWeight="bold" color="gray.600">
          確認
        </FormLabel>
        <CheckboxGroup>
          <HStack spacing={5}>
            <IsCheckedCheckbox />
            <NotIsCheckedCheckbox />
          </HStack>
        </CheckboxGroup>
      </Box>
    </VStack>
  )
}

export default FormInputGroup
