import { Checkbox } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import { checkedListState, allCheckboxActionState } from 'src/state'

type TableItemCheckboxProps = {
  index: number
  isDisabled: boolean
}

const TableItemCheckbox = ({ index, isDisabled }: TableItemCheckboxProps) => {
  const [isChecked, setChecked] = useState(false)
  const actionState = useRecoilValue(allCheckboxActionState)
  useEffect(() => {
    switch (actionState.action) {
      case 'on':
        isDisabled ? setChecked(false) : setChecked(true)
        break
      case 'off':
        setChecked(false)
        break
    }
  }, [actionState])
  const setCheckedList = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const checkedList = await snapshot.getPromise(checkedListState)
        const list = checkedList.concat()
        list[index] = !list[index]
        set(checkedListState, list)
      },
    []
  )
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={() => {
          setChecked(!isChecked)
          setCheckedList()
        }}
      />
    </div>
  )
}

export default TableItemCheckbox
