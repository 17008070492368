import { Stack, Heading } from '@chakra-ui/react'

type TopHeadingProps = {
  title: string
}

function TopHeading({ title }: TopHeadingProps) {
  return (
    <Stack mb="4">
      <Heading color="#2F6B9F" as="h1" size="lg">
        {title}
      </Heading>
    </Stack>
  )
}

export default TopHeading
