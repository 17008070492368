const keys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  idToken: 'idToken',
}

export const storeTokens = ({
  accessToken,
  refreshToken,
  idToken,
}: {
  accessToken: string
  refreshToken: string
  idToken: string
}) => {
  // profile 取得時 undefined が返る場合もあるためトークンがある場合のみ保存
  if (accessToken) {
    localStorage.setItem(keys.accessToken, accessToken)
  }
  if (refreshToken) {
    localStorage.setItem(keys.refreshToken, refreshToken)
  }
  if (idToken) {
    localStorage.setItem(keys.idToken, idToken)
  }
}

export const getAccessToken = () => {
  return localStorage.getItem(keys.accessToken)
}

export const getRefreshToken = () => {
  return localStorage.getItem(keys.refreshToken)
}

export const getIdToken = () => {
  return localStorage.getItem(keys.idToken)
}

export const removeTokens = () => {
  localStorage.removeItem(keys.accessToken)
  localStorage.removeItem(keys.refreshToken)
  localStorage.removeItem(keys.idToken)
}
