import {
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Input,
  Box,
  Card,
  CardBody,
  Text,
  Divider,
  Center,
  List,
  ListItem,
  ListIcon,
  Button,
  useToast
} from '@chakra-ui/react'
import HeaderWithSidebar from 'src/components/views/HeaderWithSidebar'
import TopHeading from 'src/components/templates/TopHeading'
import { FiCheckCircle, FiCircle } from 'react-icons/fi'
import { useState } from 'react'
import { changePassword } from 'src/api/changePassword'
import { useMutation } from '@tanstack/react-query'
import { ApiError } from 'src/api/apiError'
import { useNavigate } from 'react-router-dom'

function Password() {
  const toast = useToast()
  const navigate = useNavigate()

  const [currentPassword, setCurrentPassword] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [isLoading, setLoading] = useState(false)
  const isSamePassword = currentPassword != '' && currentPassword == password1

  const changePasswordMutation = useMutation(changePassword)

  const hasDigit = /\d/.test(password1)
  const hasLowerCase = /[a-z]/.test(password1)
  const hasUpperCase = /[A-Z]/.test(password1)
  const hasSymbol = /[^a-zA-Z0-9]/.test(password1)
  const hasMinLength = password1.length >= 8
  const isInvalidPassword1 =
    !!password1 && (!hasDigit || !hasLowerCase || !hasUpperCase || !hasSymbol)
  const isInvalidPassword2 =
    !!password1 && !!password2 && password1 !== password2

  const CheckCircle = () => <ListIcon as={FiCheckCircle} color="green.500" />
  const Circle = () => <ListIcon as={FiCircle} color="gray.800" />

  const handleClickSubmit = () => {
    setLoading(true)
    changePasswordMutation.mutate(
      {
        oldPassword: currentPassword,
        newPassword: password1,
      },
      {
        onSuccess: () => {
          toast({
            description: 'パスワードを変更しました',
            status: 'success',
            isClosable: true,
          })
          navigate('/documents')
        },
        onError: (error) => {
          let description = 'パスワード変更に失敗しました'
          if (error instanceof ApiError) {
            switch (error.message) {
              case 'Attempt limit exceeded, please try after some time.':
                description =
                  'ログイン試行が5回失敗しました。1時間後に再試行してください。'
                break
              case 'Incorrect username or password.':
                description = '現在のパスワードを正しく入力してください。'
                break
            }
          }
          toast({
            description,
            status: 'error',
            isClosable: true,
          })
        },
        onSettled: () => {
          setLoading(false)
        },
      },
    )
  }
  return (
    <HeaderWithSidebar>
      <Box h="100%">
        <TopHeading title="パスワード" />
      </Box>
      <Box>
        <Center>
          <Card backgroundColor={'white'}>
            <CardBody>
              <Text>
                新しいパスワードを入力してください。少なくとも次のものを含めてください。
              </Text>
              <List spacing={3}>
                <ListItem>
                  {hasMinLength ? <CheckCircle /> : <Circle />}
                  8文字以上
                </ListItem>
                <ListItem>
                  {hasLowerCase ? <CheckCircle /> : <Circle />}
                  小文字1文字以上
                </ListItem>
                <ListItem>
                  {hasUpperCase ? <CheckCircle /> : <Circle />}
                  大文字1文字以上
                </ListItem>
                <ListItem>
                  {hasDigit ? <CheckCircle /> : <Circle />}
                  数字1文字以上
                </ListItem>
                <ListItem>
                  {hasSymbol ? <CheckCircle /> : <Circle />}
                  記号1文字以上
                </ListItem>
              </List>
            </CardBody>
          </Card>
        </Center>
      </Box>
      <Center height="50px">
        <Divider />
      </Center>
      <TableContainer>
        <Table align="center" variant="simple" w="1024px">
          <Tbody>
            <Tr>
              <Td w="480px">現在のパスワード*</Td>
              <Td w="64px"></Td>
              <Td w="480px">
                <Input
                  data-private
                  id="currentPassword"
                  type="password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </Td>
            </Tr>
            <Tr>
              <Td w="480px">新しいパスワード*</Td>
              <Td w="64px"></Td>
              <Td w="480px">
                <Input
                  data-private
                  id="newPassword1"
                  type="password"
                  isInvalid={isInvalidPassword1}
                  focusBorderColor={isInvalidPassword1 ? 'crimson' : 'blue.500'}
                  errorBorderColor={'crimson'}
                  onChange={(e) => setPassword1(e.target.value)}
                  required
                />
                {isSamePassword && (
                  <Text color={'crimson'}>現在のパスワードとは異なるパスワードを入力してください</Text>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td w="480px">新しいパスワードの確認*</Td>
              <Td w="64px"></Td>
              <Td w="480px">
                <Input
                  data-private
                  id="newPassword2"
                  type="password"
                  isInvalid={isInvalidPassword2}
                  focusBorderColor={isInvalidPassword2 ? 'crimson' : 'blue.500'}
                  errorBorderColor={'crimson'}
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                />
                {isInvalidPassword2 && (
                  <Text color={'crimson'}>パスワードが一致しません</Text>
                )}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Box mt={4}>
        <Center>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            isDisabled={
              !currentPassword ||
              !password1 ||
              !password2 ||
              isInvalidPassword1 ||
              isInvalidPassword2 ||
              isSamePassword
            }
            onClick={handleClickSubmit}
          >
            更新
          </Button>
        </Center>
      </Box>
    </HeaderWithSidebar>
  )
}

export default Password
