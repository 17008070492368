import { AzureOcrResult } from 'src/ocrParser/azureOcrType'
import { OcrParse } from 'src/ocrParser/ocrParser'
import { authFetchApi } from 'src/values'

export type GetRawOcrResultProps = {
  tenantId: number
  ocrResultId: number | null | undefined
  pageNumber: number
}

const getOcrRawResult = async (url: string): Promise<AzureOcrResult> => {
  const response = await fetch(url)
  const json = await response.json()

  return json
}

export const getOcrRawResultObject = async (
  data: GetRawOcrResultProps,
): Promise<OcrParse | null> => {
  if (data.ocrResultId) {
    const params = new URLSearchParams({
      tenantId: data.tenantId.toString(),
      ocrResultId: data.ocrResultId.toString(),
      pageNumber: data.pageNumber.toString(),
    })

    const response = await authFetchApi('ocr-raw-result?' + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const rawResult = await getOcrRawResult(response.presignedUrl)

    return new OcrParse(rawResult)
  }

  return null
}
