import { Box, Flex, HStack, Stack } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { getOcrRawResultObject } from 'src/api/rawOcrResultJsonUrl'
import { OcrParse } from 'src/ocrParser/ocrParser'
import {
  ocrResultPagesState,
  allPageOcrParsers,
  currentPageNumState,
  isRetryPatternState,
} from 'src/state'
import { OcrFormatSelector } from './OcrFormatSelecter'
import OcrResultItemList from './OcrResultItemList'
import PdfViewer from './PdfViewer'
import { RetryOcrExecuteButton } from './RetryOcrExecuteButton'
import { RetryOcrPatternMatchingButton } from './RetryOcrPatternMatchingButton'

type DocumentBoxProps = {
  pdfUrl: string
  tenantId: number
  documentId: number
  closeModal: () => void
}

function DocumentEditBox({
  pdfUrl,
  closeModal,
  tenantId,
  documentId,
}: DocumentBoxProps) {
  const currentPageNumber = useRecoilValue(currentPageNumState)
  const ocrResultPages = useRecoilValue(ocrResultPagesState)
  // パターンマッチング再実行フラグ
  const resetIsPatternMatch = useResetRecoilState(isRetryPatternState)

  const [ocrParsers, setocrParsers] = useRecoilState(allPageOcrParsers)
  const ocrResultId = ocrResultPages?.[currentPageNumber]?.ocrResultId

  const getOcrRawResultState = async (body: {
    tenantId: number
    ocrResultId: number | null | undefined
    pageNumber: number
  }): Promise<OcrParse | null> => {
    // すでにOCR結果を取得済みの場合はapiへのリクストをスキップします
    if (ocrParsers) {
      if (currentPageNumber in ocrParsers) {
        return null
      }
    }

    return await getOcrRawResultObject(body)
  }

  const setOcrRawStates = (data: OcrParse | null) => {
    const index = currentPageNumber
    const indexString = index.toString()

    if (data) {
      if (ocrParsers) {
        setocrParsers({
          ...ocrParsers,
          [indexString]: data,
        })
      } else {
        const newObject = Object()
        newObject[indexString] = data
        setocrParsers(newObject)
      }
    }
  }

  const { data } = useQuery({
    queryKey: [`ocr-raw-result-${ocrResultId}-${currentPageNumber}`],
    queryFn: async () =>
      await getOcrRawResultState({
        tenantId: tenantId,
        ocrResultId: ocrResultId,
        pageNumber: currentPageNumber,
      }),
  })

  useEffect(() => {
    if (data) {
      setOcrRawStates(data)
    }
  }, [data])

  useEffect(() => {
    // 帳票開く度にパターンマッチング再実行フラグリセット
    resetIsPatternMatch()
  }, [currentPageNumber])

  return (
    <>
      {pdfUrl && (
        <Flex h="calc(100vh - 64px);">
          <PdfViewer pdfUrl={pdfUrl} rawOcrResults={ocrParsers} />
          {ocrResultPages && (
            <Box
              w="40%"
              h="100%"
              overflowY="scroll"
              backgroundColor="white"
              m="0"
              id="ocrResultItemList"
            >
              <Stack>
                <OcrFormatSelector />
                <HStack>
                  <RetryOcrExecuteButton documentId={documentId} />
                  <RetryOcrPatternMatchingButton
                    ocrResultParser={ocrParsers?.[currentPageNumber.toString()]}
                  />
                </HStack>
                <OcrResultItemList
                  tenantId={tenantId}
                  documentId={documentId}
                  closeModal={closeModal}
                  ocrParser={ocrParsers?.[currentPageNumber.toString()]}
                />
              </Stack>
            </Box>
          )}
        </Flex>
      )}
    </>
  )
}

export default DocumentEditBox
