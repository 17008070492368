import {
  Box,
  Button,
  Center,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Tooltip,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getUserProfile } from 'src/api/auth'
import TopHeading from '../templates/TopHeading'
import HeaderWithSidebar from '../views/HeaderWithSidebar'
import { FiHelpCircle } from 'react-icons/fi'
import { registerPattern } from 'src/api/registerPattern'
import { useState } from 'react'
import { userProfileQueryKey } from 'src/values'
import { useSetRecoilState } from 'recoil'
import { profileState } from 'src/state'
import { compressFile } from 'src/pdfTools/compressionPdf'

function BpoPatternRegister() {
  const toast = useToast()

  const [isLoading, setLoading] = useState(false)
  const setProfile = useSetRecoilState(profileState)

  const navigate = useNavigate()
  useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async (profile) => {
      if (profile.tenantId.toString() != process.env.REACT_APP_TOMORAKU_ID) {
        window.scrollTo(0, 0)
        navigate('/documents')
      }

      setProfile(profile)
    },
    refetchOnWindowFocus: false,
  })

  const registerPatternMutation = useMutation(registerPattern, {})

  const clear = () => {
    ;(document.getElementById('tenantId') as HTMLInputElement).value = ''
    ;(document.getElementById('documentType') as HTMLInputElement).value = ''
    ;(document.getElementById('pdf') as HTMLInputElement).value = ''
    ;(document.getElementById('pageNumber') as HTMLInputElement).value = ''
    ;(document.getElementById('labelStudioId') as HTMLInputElement).value = ''
    ;(document.getElementById('pattern') as HTMLInputElement).value = ''
    ;(document.getElementById('patternName') as HTMLInputElement).value = ''
    ;(document.getElementById('companyName') as HTMLInputElement).value = ''
    ;(document.getElementById('keyword1') as HTMLInputElement).value = ''
    ;(document.getElementById('keyword2') as HTMLInputElement).value = ''
  }

  const handleClickSubmit = async () => {
    setLoading(true)

    const tenantId = document.getElementById('tenantId') as HTMLInputElement
    const documentType = document.getElementById(
      'documentType',
    ) as HTMLInputElement
    const pdf = document.getElementById('pdf') as HTMLInputElement
    const pageNumber = document.getElementById('pageNumber') as HTMLInputElement
    const labelStudioId = document.getElementById(
      'labelStudioId',
    ) as HTMLInputElement
    const pattern = document.getElementById('pattern') as HTMLInputElement
    const patternName = document.getElementById(
      'patternName',
    ) as HTMLInputElement
    const companyName = document.getElementById(
      'companyName',
    ) as HTMLInputElement
    const keyword1 = document.getElementById('keyword1') as HTMLInputElement
    const keyword2 = document.getElementById('keyword2') as HTMLInputElement

    if (
      !tenantId.value ||
      !documentType.value ||
      !pdf.files?.[0] ||
      !pageNumber.value ||
      !labelStudioId.value ||
      !pattern.files?.[0] ||
      !patternName.value ||
      !companyName.value
    ) {
      toastError('必須のものは全て入力してください')
      return
    }

    const formData = new FormData()
    formData.append('tenantId', tenantId.value)
    formData.append('documentType', documentType.value)
    formData.append('pageNumber', pageNumber.value)
    formData.append('labelStudioId', labelStudioId.value)
    formData.append('pattern', pattern.files[0])
    formData.append('patternName', patternName.value)
    formData.append('companyName', companyName.value)
    if (keyword1) formData.append('keyword1', keyword1.value)
    if (keyword2) formData.append('keyword2', keyword2.value)

    // pdfデータはwafのblock対策で圧縮してリクエストする
    const compressionData = await compressFile(pdf.files[0])
    const blobPdfData = new Blob([compressionData], { type: 'application/zip' })
    // backend側でファイル名が必要なので、file化
    const compressPdfFile = new File([blobPdfData], pdf.files[0].name)
    formData.append('pdf', compressPdfFile)

    registerPatternMutation.mutate(formData, {
      onSuccess: async (data) => {
        if (data.status === 20) {
          toastSuccess()
          clear()
        } else {
          toastError('登録に失敗しました')
        }
      },
      onError: () => {
        toastError('登録に失敗しました')
      },
      onSettled: () => {
        setLoading(false)
      },
    })
  }

  const toastSuccess = () => {
    toast({
      description: '登録に成功しました',
      status: 'success',
      isClosable: true,
    })
  }

  const toastError = (description: string) => {
    toast({
      description: description,
      status: 'error',
      isClosable: true,
    })
  }

  return (
    <HeaderWithSidebar>
      <TopHeading title="帳票パターン新規登録" />
      <TableContainer>
        <Table align="center" variant="simple" w="1024px">
          <Tbody>
            <Tr>
              <Td w="480px">テナントID*</Td>
              <Td w="64px"></Td>
              <Td w="480px">
                <Input
                  id="tenantId"
                  type="number"
                  required
                  placeholder="数字で入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td w="480px">帳票タイプ*</Td>
              <Td w="64px"></Td>
              <Td w="480px">
                <Select
                  id="documentType"
                  required
                  placeholder="選択してください"
                >
                  <option value="1">納品書</option>
                  <option value="2">請求書</option>
                  <option value="3">注文書</option>
                  <option value="4">検収書</option>
                </Select>
              </Td>
            </Tr>
            <Tr>
              <Td>ドキュメント（PDF）*</Td>
              <Td>
                <Tooltip
                  label="※Label Stuidioで座標を指定する際に使ったものと同じPDFを登録してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  id="pdf"
                  type="file"
                  accept=".pdf"
                  required
                  px="10px"
                  py="5px"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>対象ページ番号*</Td>
              <Td>
                <Tooltip
                  label="※登録したドキュメントの何ページ目か指定してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  id="pageNumber"
                  type="number"
                  required
                  placeholder="数字で入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>登録ID*</Td>
              <Td>
                <Tooltip
                  label="※Label StuidioのIDを入力してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  id="labelStudioId"
                  type="number"
                  required
                  placeholder="数字で入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>座標データ(JSON)*</Td>
              <Td>
                <Tooltip
                  label="※Label StuidioでExportしたJSONファイルをそのまま登録してください（全パターンの座標データをが含まれていても問題なし）"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  id="pattern"
                  type="file"
                  accept=".json"
                  required
                  px="10px"
                  py="5px"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>表示パターン名*</Td>
              <Td>
                <Tooltip
                  label="※会社名_帳票名_ページ番号_Versionを記載
                  　例：Keyence_invoice_P1_Ver1"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  data-private
                  id="patternName"
                  type="text"
                  required
                  placeholder="テキストで入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>検索キー社名*</Td>
              <Td>
                <Tooltip
                  label="※ドキュメントに記載されている実値を記入してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  data-private
                  id="companyName"
                  type="text"
                  required
                  placeholder="テキストで入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>検索キー_その他①</Td>
              <Td>
                <Tooltip
                  label="※ドキュメントに記載されている実値を記入してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  data-private
                  id="keyword1"
                  type="text"
                  placeholder="テキストで入力してください"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>検索キー_その他②</Td>
              <Td>
                <Tooltip
                  label="※ドキュメントに記載されている実値を記入してください"
                  fontSize="md"
                >
                  <span>
                    <FiHelpCircle />
                  </span>
                </Tooltip>
              </Td>
              <Td>
                <Input
                  data-private
                  id="keyword2"
                  type="text"
                  placeholder="テキストで入力してください"
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Box mt={4}>
        <Center>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            onClick={handleClickSubmit}
          >
            新規登録
          </Button>
        </Center>
      </Box>
    </HeaderWithSidebar>
  )
}

export default BpoPatternRegister
