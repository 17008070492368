import {
  Button,
  ButtonGroup,
  Center,
  Icon,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiCheckCircle, FiTrash2 } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useLogicalDeleteMutation } from 'src/graphql/generated'

type DetailMenuItemProps = {
  documentId: number
  tenantId: number
}
function DeleteMenuItem({ documentId, tenantId }: DetailMenuItemProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const deleteMutation = useLogicalDeleteMutation()

  type apiStatus = 'idle' | 'loading' | 'success' | 'error'
  const [apiStatus, setApiStatus] = useState<apiStatus>('idle')
  const openModal = () => {
    setApiStatus('idle')
    onOpen()
  }
  return (
    <>
      <MenuItem
        icon={<FiTrash2 />}
        onClick={(e) => {
          e.stopPropagation()
          openModal()
        }}
      >
        削除
      </MenuItem>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text align={'center'}>
              {apiStatus === 'success' ? '帳票削除完了' : '帳票の削除'}
            </Text>
          </ModalHeader>
          <ModalBody>
            <Center>
              {apiStatus === 'loading' && <Spinner />}
              {apiStatus === 'idle' && (
                <Text color={'red'} fontWeight={'bold'} align={'center'}>
                  この操作は取り消せません
                  <br />
                  本当に削除しますか？
                </Text>
              )}
              {apiStatus === 'success' && (
                <VStack>
                  <Text align={'center'}>帳票の削除が正常に完了しました</Text>
                  <Icon
                    as={FiCheckCircle}
                    color="blue.600"
                    height={'112px'}
                    width={'112px'}
                  />
                </VStack>
              )}
              {apiStatus === 'error' && (
                <VStack>
                  <Text align={'center'}>削除に失敗しました</Text>
                </VStack>
              )}
            </Center>
          </ModalBody>

          <ModalFooter justifyContent={'center'}>
            {apiStatus === 'idle' && (
              <ButtonGroup spacing={6}>
                <Button
                  colorScheme="blue"
                  variant="outline"
                  width={'192px'}
                  onClick={onClose}
                >
                  キャンセル
                </Button>
                <Button
                  colorScheme="red"
                  width={'192px'}
                  onClick={() => {
                    setApiStatus('loading')
                    deleteMutation.mutate(
                      {
                        tenantId,
                        id: documentId,
                      },
                      {
                        onSuccess: () => {
                          setApiStatus('success')
                        },
                        onError: () => {
                          setApiStatus('error')
                        },
                      }
                    )
                  }}
                >
                  削除
                </Button>
              </ButtonGroup>
            )}
            {(apiStatus === 'success' || apiStatus === 'error') && (
              <Button
                colorScheme={'blue'}
                width={'192px'}
                onClick={() => {
                  onClose
                  navigate(0) // 本当はデータだけ更新したいが、簡単のためリロードする
                }}
              >
                OK
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteMenuItem
