import Login from './components/pages/Login'
import Documents from './components/pages/Documents'
import TenantList from './components/pages/BpoTenantList'

import { createBrowserRouter } from 'react-router-dom'
import DocumentsSearch from './components/pages/DocumentsSearch'
import BpoPatternRegister from './components/pages/BpoPatternRegister'
import DocumentsRegister from './components/pages/DocumentsRegister'
import Password from './components/pages/settings/Password'
import Root from './components/pages/Root'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/documents/search',
    element: <DocumentsSearch />,
    children: [
      {
        path: ':page',
        element: <DocumentsSearch />,
      },
    ],
  },
  {
    path: '/bpo/tenant/list',
    element: <TenantList />,
    children: [
      {
        path: ':page',
        element: <TenantList />,
      },
    ],
  },
  {
    path: '/documents',
    element: <Documents />,
    children: [
      {
        path: ':page',
        element: <Documents />,
      },
    ],
  },
  {
    path: '/bpo/pattern/register',
    element: <BpoPatternRegister />,
    children: [
      {
        element: <BpoPatternRegister />,
      },
    ],
  },
  {
    path: '/documents/register',
    element: <DocumentsRegister />,
  },
  {
    path: '/settings/password',
    element: <Password />,
  },
])
