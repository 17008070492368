import { HStack, IconButton, Button } from '@chakra-ui/react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useSearchParams } from 'react-router-dom'

const PaginationButton = ({
  isActive,
  page,
  link,
}: {
  isActive: boolean
  page: number
  link: string
}) => {
  const variant = isActive ? 'solid' : 'outline'
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const onClick = () => {
    window.scrollTo(0, 0)
    const param = searchParams.toString() ? `?${searchParams.toString()}` : ''
    navigate(`${link}/${page}${param}`)
  }
  return (
    <Button variant={variant} colorScheme="blue" onClick={onClick}>
      {page}
    </Button>
  )
}

const Pagination = ({
  allCount,
  currentPage,
  countPerPage,
  link,
}: {
  allCount: number
  currentPage: number
  countPerPage: number
  link: string
}) => {
  const maxPage = Math.ceil(allCount / countPerPage)
  const defaultLimit = 10
  const limit = maxPage < defaultLimit ? maxPage : defaultLimit
  const innerLimit = Math.max(Math.min(currentPage - 5, maxPage - limit + 1), 1)
  const outerLimit = Math.max(Math.min(currentPage + 4, maxPage), limit)
  const list = []
  for (let i = innerLimit; i < outerLimit + 1; i++) {
    const isActive = i === currentPage
    list.push(
      <PaginationButton isActive={isActive} page={i} link={link} key={i} />
    )
  }

  const navigate = useNavigate()
  const onLeftClick = () => {
    window.scrollTo(0, 0)
    navigate(`${link}/${currentPage - 1}`)
  }
  const onRightClick = () => {
    window.scrollTo(0, 0)
    navigate(`${link}/${currentPage + 1}`)
  }
  return (
    <>
      <HStack>
        <IconButton
          isDisabled={currentPage === 1}
          variant="outline"
          colorScheme="blue"
          aria-label="previous"
          icon={<FiChevronLeft />}
          onClick={onLeftClick}
        />
        {list}
        <IconButton
          isDisabled={currentPage === maxPage}
          variant="outline"
          colorScheme="blue"
          aria-label="next"
          icon={<FiChevronRight />}
          onClick={onRightClick}
        />
      </HStack>
    </>
  )
}

export default Pagination
