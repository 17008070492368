import { useGetDocumentsByPageQuery } from 'src/graphql/generated'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { documentsState, documentCountState, profileState } from 'src/state'
import { useQuery } from '@tanstack/react-query'
import { getUserProfile } from 'src/api/auth'
import { useEffect } from 'react'
import DocumentList from './DocumentList'
import { userProfileQueryKey } from 'src/values'
import { getBpoTenantId } from 'src/values/bpoTenantId'
import LogRocket from 'logrocket'


function DocumentsFetch({ onOpen }: { onOpen: () => void }) {
  const { page } = useParams()
  const pageNum = (() => {
    if (!page) return 1
    const pageNum = parseInt(page, 10)
    return isNaN(pageNum) ? 1 : pageNum
  })()

  const [documents, setDocuments] = useRecoilState(documentsState)
  const [allDocumentCount, setAllDocumentCount] =
    useRecoilState(documentCountState)
  const setProfile = useSetRecoilState(profileState)

  const { isLoading: isProfileLoading, data: profileData } = useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async (profile) => {
      setProfile(profile)

      if (profile.userId != 0) {
        LogRocket.identify(profile.userId.toString())
      }
    },
    refetchOnWindowFocus: false,
  })

  const { isLoading: isDocumentLoading, data } = useGetDocumentsByPageQuery(
    {
      tenantId: getBpoTenantId() || (profileData?.tenantId as number),
      page: pageNum,
    },
    {
      refetchInterval: 60000, // 1min
      enabled: !isProfileLoading,
    },
  )

  useEffect(() => {
    if (data) {
      setDocuments(data.documentsByPage)
      setAllDocumentCount(data.documentCount)
    }
  }, [data])

  return (
    <>
      <DocumentList
        onOpen={onOpen}
        documents={documents}
        allDocumentCount={allDocumentCount}
        isLoading={isDocumentLoading}
        pageNum={pageNum}
        path={'/documents'}
      />
    </>
  )
}

export default DocumentsFetch
