import { useRef } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useRecoilState, useRecoilValue } from 'recoil'
import {
  currentPageNumState,
  OcrFormatInterface,
  profileState,
  selectOcrFormatState,
} from 'src/state'
import { useGetOcrFormatByTenantIdQuery } from '../../../../graphql/generated'
import { OcrFormatItem, OcrFormatNotSelectItem } from './OcrFormatItem'
import { getBpoTenantId } from 'src/values/bpoTenantId'

export const OcrFormatSelector = () => {
  const menuListRef = useRef<HTMLDivElement>(null)
  const menuItemRef = useRef<HTMLButtonElement>(null)
  const currentPageNumber = useRecoilValue(currentPageNumState)
  const [selectedItem, setSelectedItem] = useRecoilState(selectOcrFormatState)
  const profile = useRecoilValue(profileState)

  const tenantId = getBpoTenantId() || profile.tenantId
  const fetchTenants = (tenantId: number) => {
    const { data } = useGetOcrFormatByTenantIdQuery({
      tenantId: tenantId,
    })
    return data
  }
  const ocrFormatData = fetchTenants(tenantId)
  const ocrFormatInfo = ocrFormatData?.ocrFormatByTenantId?.sort(
    (a,b) => a.fileName.localeCompare(b.fileName)
  )

  const handleSelect = (item: OcrFormatInterface) => {
    setSelectedItem({
      ...selectedItem,
      [currentPageNumber]: item,
    })
  }

  const delay = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
  )

  const handleMenuOpen = async () => {
    if (menuListRef.current && menuItemRef.current) {
      await delay(50)
      menuListRef.current.scrollTop = menuItemRef.current.offsetTop
    }
  }

  return (
    <>
      {currentPageNumber in selectedItem && (
        <Menu onOpen={handleMenuOpen}>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {selectedItem[currentPageNumber].id == -1 && (
              <Text>帳票パターンは選択されていません</Text>
            )}
            {selectedItem[currentPageNumber].id != -1 && (
              <HStack>
                <Text>
                  帳票パターンID: {selectedItem[currentPageNumber].id}
                </Text>
                <VStack>
                  <Text>
                    取引先名: {selectedItem[currentPageNumber].clientName}
                  </Text>
                  <Text>
                    ファイル名: {selectedItem[currentPageNumber].fileName}
                  </Text>
                </VStack>
              </HStack>
            )}
          </MenuButton>
          <MenuList maxHeight="240px" overflowY="auto" ref={menuListRef}>
            <OcrFormatNotSelectItem
              onClick={() =>
                handleSelect({ id: -1, fileName: '', clientName: '' })
              }
            />
            {ocrFormatInfo &&
              ocrFormatInfo.map(
                (item) =>
                  item &&
                  item.clientName &&
                  item.fileName && (
                    <OcrFormatItem
                      key={item.id}
                      id={item.id}
                      selectedId={selectedItem[currentPageNumber].id}
                      clientName={item.clientName}
                      fileName={item.fileName}
                      onClick={() => handleSelect(item)}
                      selectedRef={menuItemRef}
                    />
                  )
              )}
          </MenuList>
        </Menu>
      )}
    </>
  )
}
