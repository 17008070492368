import { ApiError } from 'src/api/apiError'
import { getIdToken, getRefreshToken } from 'src/infra/token'

// エラーハンドリングで使っている
export const notLoginedMessage = 'token does not exist.'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
export async function fetchApi(
  path: string,
  options: RequestInit,
  shouldReturnRaw = false,
) {
  const response = await fetch(`${apiEndpoint}/${path}`, options)
  if (!shouldReturnRaw) {
    const json = await response.json()
    if (!response.ok) {
      throw new ApiError(response.status, json.message || 'API Error')
    }
    return json
  }

  if (!response.ok) {
    throw new ApiError(response.status, 'API Error')
  }
  return response
}

export async function authFetchApi(
  path: string,
  options: RequestInit,
  shouldReturnRaw = false,
) {
  const idToken = getIdToken()
  const refreshToken = getRefreshToken()
  if (!idToken || !refreshToken) {
    throw new Error(notLoginedMessage)
  }

  options.headers = {
    'x-refresh-token': `${refreshToken}`,
    ...options.headers,
    ...{ Authorization: `Bearer ${idToken}` },
  }

  return await fetchApi(path, options, shouldReturnRaw)
}

export const authGraphqlApi = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
): (() => Promise<TData>) => {
  return async () => {
    const idToken = getIdToken()
    const refreshToken = getRefreshToken()
    if (!idToken || !refreshToken) {
      throw new Error(notLoginedMessage)
    }

    const json = await fetchApi('graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-refresh-token': `${refreshToken}`,
        ...{ Authorization: `Bearer ${idToken}` },
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    return json.data
  }
}
