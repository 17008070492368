import { Box, Flex } from '@chakra-ui/react'
import HeaderWithSidebar from '../views/HeaderWithSidebar'
import TopHeading from '../templates/TopHeading'
import DocumentsFetch from '../views/documents/DocumentsFetch'
import SearchPopover from '../views/documents/SearchPopover'
import CsvDownloadButton from '../views/documents/CsvDownloadButton'
import DetailModal from '../views/documents/DetailEditModal'
import { useDisclosure } from '@chakra-ui/react'
import PwAlert from '../templates/documents/PwAlert'

function Documents() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <HeaderWithSidebar>
      <Box h="100%">
        <PwAlert />
        <TopHeading title="帳票一覧" />
        <Flex my="4" justifyContent={'space-between'}>
          <CsvDownloadButton />
          <SearchPopover />
        </Flex>
        <DocumentsFetch onOpen={onOpen} />
      </Box>
      {isOpen && <DetailModal isOpen={isOpen} onClose={onClose} />}
    </HeaderWithSidebar>
  )
}

export default Documents
