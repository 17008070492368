import { Point2D } from 'src/ocrParser/ocrType'
import { muluchEditPoints, clickPointEnum, Bbox } from 'src/ocrParser/ocrType'

export type mulchEditModeEnum = 'editPoint' | 'translation' | 'create'

export type mulchEditModeType = {
  mode: mulchEditModeEnum
  pointName: clickPointEnum | null
}

const distanceSquared = (a: Point2D, b: Point2D): number => {
  // 二つの座標の距離を計算する関数
  return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)
}

const findClosestIndex = (
  points: muluchEditPoints,
  target: Point2D,
): clickPointEnum => {
  // クリックした座標に一番近い、bboxの編集点を探す関数

  let closestIndex = 'topLeft' as clickPointEnum
  let minDistance = distanceSquared(points.topLeft, target)

  Object.entries(points).forEach(([key, value]) => {
    if (key != 'center') {
      const dist = distanceSquared(value, target)
      if (dist < minDistance) {
        minDistance = dist
        closestIndex = key as clickPointEnum
      }
    }
  })

  return closestIndex
}

const isClosestEditPoint = (
  editPoint: Point2D,
  clickPosition: Point2D,
  th = 5,
): boolean => {
  // 編集点をクリックしたと判定ができる範囲でクリックされたかを判断する関数
  const isIncludX =
    editPoint.x - th <= clickPosition.x && clickPosition.x <= editPoint.x + th
  const isIncludY =
    editPoint.y - th <= clickPosition.y && clickPosition.y <= editPoint.y + th

  return isIncludX && isIncludY
}

const isIncludEditBbox = (bbox: Bbox, clickPosition: Point2D): boolean => {
  // 複数編集座標の領域内をクリックしたか判定する関数
  const isIncludX = bbox.xmin <= clickPosition.x && clickPosition.x <= bbox.xmax
  const isIncludY = bbox.ymin <= clickPosition.y && clickPosition.y <= bbox.ymax

  return isIncludX && isIncludY
}

/**
 * 複数編集座標の編集モードを決める関数
 * @param bbox 複数編集bboxの座標
 * @param clickPosition clickした座標
 */
export const getEditMode = (
  bbox: Bbox,
  clickPosition: Point2D,
): mulchEditModeType => {
  const points = bbox.editPoints()
  const closestEditPoint = findClosestIndex(points, clickPosition)

  // クリックした場所が編集点の近くだった場合
  if (isClosestEditPoint(points[closestEditPoint], clickPosition)) {
    return {
      mode: 'editPoint',
      pointName: closestEditPoint,
    }
  }

  // クリックした場所が複数編集座標の領域内の場合
  if (isIncludEditBbox(bbox, clickPosition)) {
    return {
      mode: 'translation',
      pointName: null,
    }
  }

  // クリックした場所が複数編集座標の領域外の場合
  return {
    mode: 'create',
    pointName: null,
  }
}
