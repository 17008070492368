import { Checkbox } from '@chakra-ui/react'
import { orderCheckboxState } from 'src/state'
import { useRecoilState } from 'recoil'
import { DocumentTypeId, getDocumentTypeLabel } from 'src/values'

const OrderCheckbox = () => {
  const [isChecked, setChecked] = useRecoilState(orderCheckboxState)

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setChecked(e.target.checked)}
    >
      {getDocumentTypeLabel(DocumentTypeId.ORDER).text}
    </Checkbox>
  )
}

export default OrderCheckbox
