import { countPerPage } from 'src/values'
import { format } from 'date-fns'
import { GetDocumentsByPageQuery } from 'src/graphql/generated'

type SearchDocumentsProps = {
  from: number
  tenantId?: number
  clientName?: string
  documentTypeIdList?: number[]
  createdAtGte?: number
  createdAtLte?: number
  statusList?: number[]
  isDownloadedCsvList?: number[]
  isCheckedList?: number[]
  sort: [{ id: string }]
}

export interface SearchResponseBody<TDocument = unknown> {
  took: number
  timed_out: boolean
  _shards: {
    total: number
    successful: number
    skipped: number
    failed: number
  }
  hits: {
    total: {
      value: number
      relation: string
    }
    max_score: number
    hits: {
      _index: string
      _type: string
      _id: string
      _score: number
      _source: TDocument
      sort: number[]
    }[]
  }
}

export type SearchDocument = {
  downloadby?: number
  isdeleted: number
  filepath: string
  '@timestamp': string
  documenttypeid: number
  isdownloadedcsv: number
  clientname?: string
  id: number
  tenantid: number
  ocrresultid: number
  filename: string
  createdat: string
  numberofpages: number
  updatedat: string
  status: number
  ischecked: number
  updatedby: number
  createdby: number
}

type hits = SearchResponseBody<SearchDocument>['hits']['hits']
type documentsType = GetDocumentsByPageQuery['documentsByPage']
export const factoryDocuments = (data: hits | undefined): documentsType => {
  if (!data) return data
  return data.map((v) => {
    return {
      __typename: 'Document',
      id: v._source.id,
      numberOfPages: v._source.numberofpages,
      createdAt: Date.parse(v._source.createdat),
      isDownloadedCsv: v._source.isdownloadedcsv,
      fileName: v._source.filename,
      documentOcr: {
        documentId: v._source.id,
        tenantId: v._source.tenantid,
        ocrResultId: v._source.ocrresultid,
        documentTypeId: v._source.documenttypeid,
        clientName: v._source.clientname,
        isChecked: v._source.ischecked,
        status: v._source.status,
      },
    }
  })
}

type wildcard = ReturnType<typeof createWildCard>
type range = ReturnType<typeof createRange>
export const createPayload = ({
  from,
  tenantId,
  clientName,
  documentTypeIdList,
  createdAtGte,
  createdAtLte,
  statusList,
  isDownloadedCsvList,
  isCheckedList,
  sort,
}: SearchDocumentsProps) => {
  const base: {
    sort: [{ id: string }]
    query: {
      bool: {
        must?: (wildcard | range)[]
        filter?: {
          terms: {
            [key: string]: number[]
          }
        }[]
      }
    }
    size: number
    from: number
  } = {
    sort,
    query: {
      bool: {},
    },
    size: countPerPage,
    from: Math.max(from - 1, 0) * countPerPage,
  }

  if (tenantId) {
    base.query.bool.filter ?? (base.query.bool.filter = [])
    base.query.bool.filter.push({
      terms: { tenantid: [tenantId] },
    })
  }

  if (clientName) {
    base.query.bool.must ?? (base.query.bool.must = [])
    for (const name of clientName.split(' ')) {
      base.query.bool.must.push(createWildCard('clientname', name))
    }
  }

  if (createdAtGte || createdAtLte) {
    base.query.bool.must ?? (base.query.bool.must = [])
    const gte = createdAtGte ? format(createdAtGte, 'yyyy-MM-dd') : undefined
    const lte = createdAtLte ? format(createdAtLte, 'yyyy-MM-dd') : undefined
    base.query.bool.must.push(createRange('createdat', gte, lte))
  }

  if (documentTypeIdList?.length) {
    base.query.bool.filter ?? (base.query.bool.filter = [])
    base.query.bool.filter.push({
      terms: {
        documenttypeid: documentTypeIdList.sort(),
      },
    })
  }

  if (statusList?.length) {
    base.query.bool.filter ?? (base.query.bool.filter = [])
    base.query.bool.filter.push({
      terms: {
        status: statusList.sort(),
      },
    })
  }

  if (isDownloadedCsvList?.length) {
    base.query.bool.filter ?? (base.query.bool.filter = [])
    base.query.bool.filter.push({
      terms: {
        isdownloadedcsv: isDownloadedCsvList.sort(),
      },
    })
  }

  if (isCheckedList?.length) {
    base.query.bool.filter ?? (base.query.bool.filter = [])
    base.query.bool.filter.push({
      terms: {
        ischecked: isCheckedList.sort(),
      },
    })
  }

  base.query.bool.filter ?? (base.query.bool.filter = [])
  base.query.bool.filter.push({
    terms: { isdeleted: [0] },
  })
  return base
}

const createWildCard = (key: string, value: string) => {
  return {
    wildcard: {
      [key]: {
        value: `*${value}*`
      },
    },
  }
}

const createRange = (
  key: string,
  gte?: string | number,
  lte?: string | number,
) => {
  return {
    range: {
      [key]: {
        gte,
        lte,
        boost: '2.0',
      },
    },
  }
}
