import { Input, Td, VStack, Flex, Tr } from '@chakra-ui/react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useEffect, useRef, useState } from 'react'
import {
  bboxListState,
  boundingBoxType,
  currentChoiceOcrItemId,
  editStateInCurrentPage,
  pointImgCanvasSizeState,
} from 'src/state'
import { getElementId } from './OcrResultItemList'
import OcrPointImageViewer from './OcrPointImageViewer'

type OcrResultItemProps = {
  id: number
  pageNumber: number
  ocrItemName: string | null | undefined
  displayText: string | null | undefined
  isEdited: boolean
}
function OcrResultItem({
  id,
  ocrItemName,
  displayText,
  pageNumber,
  isEdited,
}: OcrResultItemProps) {
  // 座標画像のcanvasサイズの設定
  const inputRef = useRef<HTMLInputElement>(null)
  const setCanvasMaxSizeState = useSetRecoilState(pointImgCanvasSizeState)

  // 編集済みフラグの設定
  const markEdited = useSetRecoilState(editStateInCurrentPage)

  const setOcrItemId = useSetRecoilState(currentChoiceOcrItemId)
  const [isFocus, setFocusFlag] = useState(false)

  useEffect(() => {
    if (inputRef.current) {
      setCanvasMaxSizeState({
        width: inputRef.current.offsetWidth,
        height: inputRef.current.offsetHeight,
      })
    }
  }, [inputRef.current])

  const [bboxState, setbboxState] = useRecoilState(bboxListState)

  const changeText = (text: string) => {
    const newBboxs: boundingBoxType[] = []
    bboxState[pageNumber].forEach((item) => {
      if (item.id == id) {
        newBboxs.push({
          ...item,
          text: text,
          isEdit: true,
        })
      } else {
        newBboxs.push(item)
      }
    })

    setbboxState({
      ...bboxState,
      [pageNumber]: newBboxs,
    })

    markEdited(true)
  }

  const onFocusOcrResultItem = (ocrItemId: number | undefined) => {
    setFocusFlag(true)

    if (ocrItemId) {
      setOcrItemId(ocrItemId)
    }
  }

  const onBlur = () => {
    setFocusFlag(false)
  }

  return (
    <>
      <Tr
        bg={isFocus ? 'linkedin.100' : '#FFFFFF'}
        onFocus={() => {
          onFocusOcrResultItem(id)
        }}
        onBlur={onBlur}
        _hover={{ bg: 'gray.100' }}
      >
        <Td fontWeight="bold" color="gray.600">
          {ocrItemName}
        </Td>
        <Td>
          <VStack>
            <Flex w={'100%'}>
              <OcrPointImageViewer id={id} pageNumber={pageNumber} />
            </Flex>
            <Input
              data-private
              ref={inputRef}
              id={getElementId(id)}
              type="text"
              backgroundColor={isEdited ? 'blue.50' : ''}
              value={displayText || ''}
              onChange={(e) => changeText(e.target.value)}
            />
          </VStack>
        </Td>
      </Tr>
    </>
  )
}

export default OcrResultItem
