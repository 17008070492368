import { useRecoilState, useSetRecoilState } from 'recoil'
import { documentsState, documentCountState, profileState } from 'src/state'
import { useQuery } from '@tanstack/react-query'
import { getUserProfile } from 'src/api/auth'
import { useEffect } from 'react'
import DocumentList from '../DocumentList'
import {
  SearchDocument,
  SearchResponseBody,
  createPayload,
  factoryDocuments,
} from 'src/api/searchDocuments'
import { authFetchApi, userProfileQueryKey } from 'src/values'
import { getBpoTenantId } from 'src/values/bpoTenantId'

type DocumentsSearchFetchProps = {
  onOpen: () => void
  from: number
  searchParamsString: string
  clientName?: string
  documentTypeIdList?: number[]
  createdAtGte?: number
  createdAtLte?: number
  statusList?: number[]
  isDownloadedCsvList?: number[]
  isCheckedList?: number[]
}
function DocumentsSearchFetch({
  onOpen,
  from,
  searchParamsString,
  clientName,
  createdAtGte,
  createdAtLte,
  documentTypeIdList = [],
  statusList = [],
  isDownloadedCsvList = [],
  isCheckedList = [],
}: DocumentsSearchFetchProps) {
  const [documents, setDocuments] = useRecoilState(documentsState)
  const [allDocumentCount, setAllDocumentCount] =
    useRecoilState(documentCountState)
  const setProfile = useSetRecoilState(profileState)

  let tenantId = getBpoTenantId()

  const { isLoading: isProfileLoading, data: profileData } = useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async (profile) => {
      setProfile(profile)
    },
    refetchOnWindowFocus: false,
  })
  if (!isProfileLoading && !tenantId) {
    tenantId = Number(profileData?.tenantId)
  }

  const { isLoading: isDocumentLoading, data } = useQuery({
    queryKey: [`/documents/search/${tenantId}/${from}?${searchParamsString}`],
    queryFn: async (): Promise<SearchResponseBody<SearchDocument>> => {
      return await authFetchApi('search?index=document_ocr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          createPayload({
            from,
            tenantId,
            clientName,
            documentTypeIdList: documentTypeIdList,
            createdAtGte,
            createdAtLte,
            statusList: statusList,
            isDownloadedCsvList: isDownloadedCsvList,
            isCheckedList: isCheckedList,
            sort: [{ id: 'desc' }],
          }),
        ),
      })
    },
    enabled: !isProfileLoading,
  })

  useEffect(() => {
    if (data) {
      setDocuments(factoryDocuments(data.hits.hits))
      setAllDocumentCount(data.hits.total.value)
    }
  }, [data])
  return (
    <>
      <DocumentList
        onOpen={onOpen}
        documents={documents}
        allDocumentCount={allDocumentCount}
        isLoading={isDocumentLoading}
        pageNum={from}
        path={'/documents/search'}
      />
    </>
  )
}

export default DocumentsSearchFetch
