import { Bbox, Point2D, clickPointEnum, Size2D } from 'src/ocrParser/ocrType'
import { mulchEditModeType } from './calcMulchEditBboxEditMode'

export const changeEditBboxScale = (
  currentEditBBox: Bbox,
  canvasRatio: Size2D,
) => {
  return new Bbox(
    Math.round(currentEditBBox.xmin * canvasRatio.width),
    Math.round(currentEditBBox.xmax * canvasRatio.width),
    Math.round(currentEditBBox.ymin * canvasRatio.height),
    Math.round(currentEditBBox.ymax * canvasRatio.height),
  )
}

export const createBbox = (
  startPosition: Point2D,
  clickPosition: Point2D,
): Bbox => {
  return new Bbox(
    Math.min(startPosition.x, clickPosition.x),
    Math.max(startPosition.x, clickPosition.x),
    Math.min(startPosition.y, clickPosition.y),
    Math.max(startPosition.y, clickPosition.y),
  )
}

const updateByEditPoint = (
  bbox: Bbox,
  clickPosition: Point2D,
  pointName: clickPointEnum | null,
): Bbox => {
  if (!pointName) {
    throw new Error('point name is null')
  }

  switch (pointName) {
    case 'topLeft': {
      return new Bbox(clickPosition.x, bbox.xmax, clickPosition.y, bbox.ymax)
    }
    case 'topRight': {
      return new Bbox(bbox.xmin, clickPosition.x, clickPosition.y, bbox.ymax)
    }
    case 'bottomRight': {
      return new Bbox(bbox.xmin, clickPosition.x, bbox.ymin, clickPosition.y)
    }
    case 'bottomLeft': {
      return new Bbox(clickPosition.x, bbox.xmax, bbox.ymin, clickPosition.y)
    }
    case 'topCenter': {
      return new Bbox(bbox.xmin, bbox.xmax, clickPosition.y, bbox.ymax)
    }
    case 'bottomCenter': {
      return new Bbox(bbox.xmin, bbox.xmax, bbox.ymin, clickPosition.y)
    }
    case 'leftCenter': {
      return new Bbox(clickPosition.x, bbox.xmax, bbox.ymin, bbox.ymax)
    }
    case 'rightCenter': {
      return new Bbox(bbox.xmin, clickPosition.x, bbox.ymin, bbox.ymax)
    }
    default: {
      throw new Error('unknow point name: ' + pointName)
    }
  }
}

const updateByTranslation = (
  bbox: Bbox,
  startPosition: Point2D,
  clickPosition: Point2D,
): Bbox => {
  const moveX = clickPosition.x - startPosition.x
  const moveY = clickPosition.y - startPosition.y

  return new Bbox(
    bbox.xmin + moveX,
    bbox.xmax + moveX,
    bbox.ymin + moveY,
    bbox.ymax + moveY,
  )
}

export const calcUpdateMulchEditBbox = (
  bbox: Bbox,
  startPosition: Point2D,
  clickPosition: Point2D,
  editMode: mulchEditModeType,
): Bbox => {
  switch (editMode.mode) {
    case 'create': {
      return createBbox(startPosition, clickPosition)
    }
    case 'editPoint': {
      return updateByEditPoint(bbox, clickPosition, editMode.pointName)
    }
    case 'translation': {
      return updateByTranslation(bbox, startPosition, clickPosition)
    }
    default: {
      throw new Error('unknow edit mode: ' + editMode.mode)
    }
  }
}
