import { Checkbox } from '@chakra-ui/react'
import { analyzeErrorCheckboxState } from 'src/state'
import { useRecoilState } from 'recoil'
import { DocumentOcrStatus, getDocumentOcrStatusLabel } from 'src/values'

const AnalyzeErrorCheckbox = () => {
  const [isChecked, setChecked] = useRecoilState(analyzeErrorCheckboxState)

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setChecked(e.target.checked)}
    >
      {getDocumentOcrStatusLabel(DocumentOcrStatus.ANALYZE_ERROR[0])}
    </Checkbox>
  )
}

export default AnalyzeErrorCheckbox
