import { authFetchApi } from 'src/values'

type PostOcrExecute = {
  tenantId: number
  documentId: number
  ocrFormatId: number
  rotate: number
  pageNumber: number
}

type PostResponce = {
  currentOcrResultId: number
  isSuccess: boolean
}

export const postOcrExecute = async (
  data: PostOcrExecute,
): Promise<PostResponce> => {
  return await authFetchApi('ocr-execute', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}
