export const excludeCharacters = (
  input: string,
  excludedCharacters: string,
) => {
  const regExp = excludedCharacters
    .replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // 正規表現に使われる文字は事前にエスケープしておく
    .replace(/(?<!,),(?!,)/g, '|') // カンマ一文字で区切っている場合は|に置換（前方及び後方のルックアラウンドを使用）
    .replace(/,{2,}/g, '|,|') // カンマ自体が除外文字（カンマが2文字以上連続）の場合
    .replace(/(^,)(.*)(,$)/g, '$2') // ,Tel, のような場合は両端の,を削除
    .replace(/^\|/g, '') // 先頭の|は削除
    .replace(/\|$/g, '') // 末尾の|は削除
  return input.replace(new RegExp(`${regExp}`, 'g'), '')
}
