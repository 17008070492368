export const validateAccept = (
  accept: string,
  fileName: string,
  fileType: string,
) => {
  if (!accept) return true

  const acceptedTypes = accept.split(',').map((type) => type.trim())
  const fileExtension = '.' + fileName.split('.').pop()?.toLowerCase() ?? ''

  const isValidType = acceptedTypes.some((type) => {
    if (type.startsWith('.')) {
      return type === fileExtension
    } else if (type.includes('/*')) {
      const category = type.split('/')[0]
      return fileType.startsWith(category + '/')
    } else {
      return type === fileType
    }
  })

  return isValidType
}
