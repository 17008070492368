import { boundingBoxType } from 'src/state'
import { mulchEditModeType } from './calcMulchEditBboxEditMode'
import { Bbox, Point2D, Size2D } from 'src/ocrParser/ocrType'
import { createBbox } from './bboxCanvasTools'

export const changeBBoxScale = (
  canvasRatio: { width: number; height: number },
  currentBBox: boundingBoxType[],
) => {
  // 座標のスケール更新
  const newBBoxInfo: boundingBoxType[] = []
  currentBBox.forEach((data) => {
    newBBoxInfo.push({
      ...data,
      xmin: data.rawXmin * canvasRatio.width,
      xmax: data.rawXmax * canvasRatio.width,
      ymin: data.rawYmin * canvasRatio.height,
      ymax: data.rawYmax * canvasRatio.height,
    })
  })

  return newBBoxInfo
}

export const newOcrItemBboxArray = (
  ocrItemBboxs: boundingBoxType[],
  currentOcrItemId: number,
  startPosition: Point2D,
  clickPosition: Point2D,
  canvasPointRatio: Size2D,
) => {
  const bboxList: boundingBoxType[] = []
  const newBbox = createBbox(startPosition, clickPosition)

  ocrItemBboxs.forEach((item) => {
    if (item.id == currentOcrItemId) {
      bboxList.push({
        ...item,
        rawXmin: Math.round(newBbox.xmin / canvasPointRatio.width),
        rawXmax: Math.round(newBbox.xmax / canvasPointRatio.width),
        rawYmin: Math.round(newBbox.ymin / canvasPointRatio.height),
        rawYmax: Math.round(newBbox.ymax / canvasPointRatio.height),
        xmin: newBbox.xmin,
        xmax: newBbox.xmax,
        ymin: newBbox.ymin,
        ymax: newBbox.ymax,
      })
    } else {
      bboxList.push(item)
    }
  })

  return bboxList
}

// 複数座標編集ボックス内にocrItemBboxが含まれるかを判定
const updateOcrItemBboxIsInclude = (
  editBbox: Bbox,
  ocrItemBboxs: boundingBoxType[],
) => {
  return ocrItemBboxs.map((value) => {
    const ocrItemBbox = new Bbox(value.xmin, value.xmax, value.ymin, value.ymax)

    if (editBbox.is_overlapping(ocrItemBbox)) {
      return {
        ...value,
        isMulchSelected: true,
      }
    }
    return {
      ...value,
      isMulchSelected: false,
    }
  })
}

// 複数座標編集ボックス内のocrItemBboxを変形
const updateOcrItemBboxByEditPoint = (
  oldEditBbox: Bbox,
  newEditBbox: Bbox,
  ocrItemBboxs: boundingBoxType[],
  canvasPointRatio: Size2D,
) => {
  const oldEditBboxWidth = oldEditBbox.width()
  const oldEditBboxHeight = oldEditBbox.height()

  const newEditBboxWidth = newEditBbox.width()
  const newEditBboxHeight = newEditBbox.height()

  return ocrItemBboxs.map((value) => {
    // 複数座標編集ボックス内に含まれていないならスルー
    if (!value.isMulchSelected) return value

    // 変形前の複数座標編集ボックス内での座標の比率を計算
    const xminRatio = (value.xmin - oldEditBbox.xmin) / oldEditBboxWidth
    const xmaxRatio = (value.xmax - oldEditBbox.xmin) / oldEditBboxWidth
    const yminRatio = (value.ymin - oldEditBbox.ymin) / oldEditBboxHeight
    const ymaxRatio = (value.ymax - oldEditBbox.ymin) / oldEditBboxHeight

    // 変形後の複数座標編集ボックス内の座標の位置を計算
    const newXmin = Math.round(newEditBboxWidth * xminRatio)
    const newXmax = Math.round(newEditBboxWidth * xmaxRatio)
    const newYmin = Math.round(newEditBboxHeight * yminRatio)
    const newYmax = Math.round(newEditBboxHeight * ymaxRatio)

    // pdf上の座標に変換
    const xmin = newXmin + newEditBbox.xmin
    const xmax = newXmax + newEditBbox.xmin
    const ymin = newYmin + newEditBbox.ymin
    const ymax = newYmax + newEditBbox.ymin

    return {
      ...value,
      xmin: xmin,
      xmax: xmax,
      ymin: ymin,
      ymax: ymax,
      rawXmin: Math.round(xmin / canvasPointRatio.width),
      rawXmax: Math.round(xmax / canvasPointRatio.width),
      rawYmin: Math.round(ymin / canvasPointRatio.height),
      rawYmax: Math.round(ymax / canvasPointRatio.height),
    }
  })
}

const updateOcrItemBboxByTranslation = (
  oldEditBbox: Bbox,
  newEditBbox: Bbox,
  ocrItemBboxs: boundingBoxType[],
  canvasPointRatio: Size2D,
) => {
  const moveX = newEditBbox.xmin - oldEditBbox.xmin
  const moveY = newEditBbox.ymin - oldEditBbox.ymin

  return ocrItemBboxs.map((value) => {
    // 複数座標編集ボックス内に含まれていないならスルー
    if (!value.isMulchSelected) return value

    // 変形後の複数座標編集ボックス内で同じ比率になるような座標の位置を計算
    const newXmin = value.xmin + moveX
    const newXmax = value.xmax + moveX
    const newYmin = value.ymin + moveY
    const newYmax = value.ymax + moveY

    return {
      ...value,
      xmin: newXmin,
      xmax: newXmax,
      ymin: newYmin,
      ymax: newYmax,
      rawXmin: Math.round(newXmin / canvasPointRatio.width),
      rawXmax: Math.round(newXmax / canvasPointRatio.width),
      rawYmin: Math.round(newYmin / canvasPointRatio.height),
      rawYmax: Math.round(newYmax / canvasPointRatio.height),
    }
  })
}

export const updateOcrItemBbox = (
  oldEditBbox: Bbox | null,
  newEditBbox: Bbox,
  ocrItemBboxs: boundingBoxType[],
  mulchEditMode: mulchEditModeType | null,
  canvasPointRatio: Size2D,
) => {
  if (!oldEditBbox) return updateOcrItemBboxIsInclude(newEditBbox, ocrItemBboxs)

  switch (mulchEditMode?.mode) {
    case 'create': {
      return updateOcrItemBboxIsInclude(newEditBbox, ocrItemBboxs)
    }
    case 'editPoint': {
      return updateOcrItemBboxByEditPoint(
        oldEditBbox,
        newEditBbox,
        ocrItemBboxs,
        canvasPointRatio,
      )
    }
    case 'translation': {
      return updateOcrItemBboxByTranslation(
        oldEditBbox,
        newEditBbox,
        ocrItemBboxs,
        canvasPointRatio,
      )
    }
    default: {
      throw new Error('unknow edit mode: ' + mulchEditMode?.mode)
    }
  }
}
