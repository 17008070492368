import { Box, Button } from '@chakra-ui/react'
import { GetDocumentsByPageQuery } from 'src/graphql/generated'
import { FiDownload } from 'react-icons/fi'
import { useMutation } from '@tanstack/react-query'
import { onSuccessDownloadCsv, postDownloadCsv } from 'src/api/downloadCsv'
import { useRecoilState, useRecoilValue } from 'recoil'
import { checkedListState, documentsState, profileState } from 'src/state'
import { getBpoTenantId } from 'src/values/bpoTenantId'
import { didAnalyze, getCsvFileName } from 'src/values'
import { DocumentTypeId } from 'src/values'

type Documents = GetDocumentsByPageQuery['documentsByPage']

function CsvDownloadButton() {
  const checkedList = useRecoilValue(checkedListState)
  const [documents, setDocuments] = useRecoilState(documentsState)
  const processingList: number[] = []

  const profile = useRecoilValue(profileState)
  const tenantId = getBpoTenantId() || profile.tenantId

  const downloadCsvMutation = useMutation(postDownloadCsv, {
    onSuccess: async (data, variables) => {
      await onSuccessDownloadCsv(data, variables)
      if (processingList.indexOf(variables.documentTypeId) !== -1) {
        processingList.splice(
          processingList.indexOf(variables.documentTypeId),
          1
        )
      }
      if (processingList.length === 0) {
        const clonedDocuments: Documents = structuredClone(documents)
        checkedList.forEach((isChecked, index) => {
          if (isChecked && clonedDocuments && clonedDocuments[index]) {
            clonedDocuments[index].isDownloadedCsv = 1
          }
        })
        setDocuments(clonedDocuments)
      }
    },
  })

  const getCheckedIds = (): number[] => {
    if (!documents) return []

    const checkedIds: number[] = []

    checkedList.forEach((value, index) => {
      if (value) {
        const documentId = documents[index].id
        const status = documents[index].documentOcr?.status

        if (status && didAnalyze(status)) {
          checkedIds.push(documentId)
        }
      }
    })

    return checkedIds
  }


  const downloadCSV = async () => {
    if (!documents) return
    const documentIds = getCheckedIds()
    for (const documentTypeId of Object.values(DocumentTypeId)) {
      const clientName = () => {
        if (documentIds.length === 1) {
          const firstDocument = documents.find(
            (document) => document.id === documentIds[0]
          )
          return firstDocument?.documentOcr?.clientName
        }
      }
      const fileName = getCsvFileName(documentTypeId, clientName())
      processingList.push(documentTypeId)
      downloadCsvMutation.mutate({
        documentIds,
        documentTypeId,
        fileName,
        tenantId,
      })
    }
  }

  return (
    <Box>
      <Button
        size="sm"
        colorScheme="blue"
        variant="outline"
        leftIcon={<FiDownload />}
        onClick={downloadCSV}
      >
        まとめてCSVダウンロード
      </Button>
    </Box>
  )
}

export default CsvDownloadButton
