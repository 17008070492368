import { Button, HStack, Flex } from '@chakra-ui/react'
import { RefObject } from 'react'
import {
  analyzingCheckboxState,
  analyzedCheckboxState,
  analyzeErrorCheckboxState,
  deliveryCheckboxState,
  invoiceCheckboxState,
  isDownloadedCsvCheckboxState,
  notIsDownloadedCsvCheckboxState,
  orderCheckboxState,
  isCheckedCheckboxState,
  notIsCheckedCheckboxState,
} from 'src/state'
type FormButtonGroupProps = {
  onClose: () => void
  from: number
  clientNameInputRef: RefObject<HTMLInputElement>
  createdAtGteInputRef: RefObject<HTMLInputElement>
  createdAtLteInputRef: RefObject<HTMLInputElement>
}
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { DocumentOcrStatus, DocumentTypeId, IsDownloadCsv } from 'src/values'
import { IsChecked } from 'src/values/isChecked'
const FormButtonGroup = ({
  onClose,
  from,
  clientNameInputRef,
  createdAtGteInputRef,
  createdAtLteInputRef,
}: FormButtonGroupProps) => {
  const [isCheckedDelivery, setDeliveryCheckbox] = useRecoilState(
    deliveryCheckboxState,
  )
  const [isCheckedInvoice, setInvoiceCheckbox] =
    useRecoilState(invoiceCheckboxState)
  const [isCheckedOrder, setOrderCheckbox] = useRecoilState(orderCheckboxState)
  const [isCheckedAnalyzing, setAnalyzingCheckbox] = useRecoilState(
    analyzingCheckboxState,
  )
  const [isCheckedAnalyzed, setAnalyzedCheckbox] = useRecoilState(
    analyzedCheckboxState,
  )
  const [isCheckedAnalyzeError, setAnalyzeErrorCheckbox] = useRecoilState(
    analyzeErrorCheckboxState,
  )
  const [isCheckedIsDownloadedCsv, setIsDownloadedCheckbox] = useRecoilState(
    isDownloadedCsvCheckboxState,
  )
  const [notICheckedIsDownloadedCsv, setNotIsDownloadedCheckbox] =
    useRecoilState(notIsDownloadedCsvCheckboxState)
  const [isCheckedFlag, setIsCheckedFlag] = useRecoilState(
    isCheckedCheckboxState,
  )
  const [notIsCheckedFlag, setNotIsCheckedFlag] = useRecoilState(
    notIsCheckedCheckboxState,
  )

  const navigate = useNavigate()

  const onClickSearch = () => {
    const searchParams = new URLSearchParams()
    if (clientNameInputRef.current?.value)
      searchParams.append('clientName', clientNameInputRef.current?.value)
    if (createdAtGteInputRef.current?.value)
      searchParams.append(
        'createdAtGte',
        Date.parse(createdAtGteInputRef.current?.value).toString(),
      )
    if (createdAtLteInputRef.current?.value)
      searchParams.append(
        'createdAtLte',
        Date.parse(createdAtLteInputRef.current?.value).toString(),
      )

    const documentTypeIdList = []
    if (isCheckedDelivery) documentTypeIdList.push(DocumentTypeId.DELIVERY)
    if (isCheckedInvoice) documentTypeIdList.push(DocumentTypeId.INVOICE)
    if (isCheckedOrder) documentTypeIdList.push(DocumentTypeId.ORDER)
    if (documentTypeIdList.length > 0) {
      searchParams.append('documentTypeId', documentTypeIdList.join(','))
    }

    const statusList = []
    if (isCheckedAnalyzing) statusList.push(DocumentOcrStatus.ANALYZING)
    if (isCheckedAnalyzed) statusList.push(DocumentOcrStatus.ANALYZED)
    if (isCheckedAnalyzeError) statusList.push(DocumentOcrStatus.ANALYZE_ERROR)
    if (statusList.length > 0) {
      searchParams.append('status', statusList.join(','))
    }

    const isDownloadedCsvList = []
    if (isCheckedIsDownloadedCsv) isDownloadedCsvList.push(IsDownloadCsv.YES)
    if (notICheckedIsDownloadedCsv) isDownloadedCsvList.push(IsDownloadCsv.NO)
    if (isDownloadedCsvList.length > 0) {
      searchParams.append('isDownloadedCsv', isDownloadedCsvList.join(','))
    }

    const isCheckedList = []
    if (isCheckedFlag) isCheckedList.push(IsChecked.YES)
    if (notIsCheckedFlag) isCheckedList.push(IsChecked.NO)
    if (isCheckedList.length > 0) {
      searchParams.append('isChecked', isCheckedList.join(','))
    }
    onClose()
    const query = searchParams.toString()
    navigate(`/documents/search/${from}?${query}`)
  }
  const onClickClear = () => {
    if (clientNameInputRef.current) clientNameInputRef.current.value = ''
    if (createdAtGteInputRef.current) createdAtGteInputRef.current.value = ''
    if (createdAtLteInputRef.current) createdAtLteInputRef.current.value = ''
    setDeliveryCheckbox(false)
    setInvoiceCheckbox(false)
    setOrderCheckbox(false)
    setAnalyzingCheckbox(false)
    setAnalyzedCheckbox(false)
    setAnalyzeErrorCheckbox(false)
    setIsDownloadedCheckbox(false)
    setNotIsDownloadedCheckbox(false)
    setIsCheckedFlag(false)
    setNotIsCheckedFlag(false)
  }
  return (
    <Flex mt={4} align="center" justifyContent="center">
      <HStack spacing={4} w="100%">
        <Button
          colorScheme="blue"
          w="50%"
          type="submit"
          onClick={onClickSearch}
        >
          この条件で検索
        </Button>
        <Button
          colorScheme="blue"
          variant="outline"
          w="50%"
          onClick={onClickClear}
        >
          条件をクリア
        </Button>
      </HStack>
    </Flex>
  )
}
export default FormButtonGroup
