import { authFetchApi } from 'src/values'

export const registerDocument = async (data: FormData) => {
  const response = await authFetchApi('ocr-execute/file-upload', {
    method: 'POST',
    headers: {},
    body: data,
  })

  return response
}
