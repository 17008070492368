import { MenuItem } from '@chakra-ui/react'
import { FiAlertTriangle } from 'react-icons/fi'

type DetailMenuItemProps = {
  onOpen: () => void
  isDisabled: boolean
}
function DetailMenuItem({ onOpen, isDisabled }: DetailMenuItemProps) {
  return (
    <>
      <MenuItem
        icon={<FiAlertTriangle />}
        isDisabled={isDisabled}
        onClick={onOpen}
      >
        詳細
      </MenuItem>
    </>
  )
}

export default DetailMenuItem
