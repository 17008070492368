import { Tag } from '@chakra-ui/react'
import { getDocumentTypeLabel } from '../../values'

const DocumentTypeLabel = ({ documentTypeId }: { documentTypeId: number }) => {
  const documentTypeLabel = getDocumentTypeLabel(documentTypeId)
  return (
    <Tag size="sm" variant="subtle" colorScheme={documentTypeLabel.color}>
      {documentTypeLabel.text} ▼
    </Tag>
  )
}
export default DocumentTypeLabel
