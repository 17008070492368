import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  TableContainer,
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react'
import Pagination from '../Pagination'
import TableBody from './TableBody'
import TableHeadCheckbox from './TableHeadCheckbox'
import { countPerPage } from 'src/values'
import { GetDocumentsByPageQuery } from 'src/graphql/generated'

type documentsType = GetDocumentsByPageQuery['documentsByPage']

function DocumentList({
  onOpen,
  documents,
  isLoading,
  allDocumentCount,
  pageNum,
  path,
}: {
  onOpen: () => void
  documents: documentsType
  isLoading: boolean
  allDocumentCount: number | undefined
  pageNum: number
  path: string
}) {
  return (
    <>
      {isLoading ? (
        <Box w="100%">
          <Center minH="520px">
            <Spinner />
          </Center>
        </Box>
      ) : (
        <TableContainer h="70vh" overflowY={'auto'}>
          <Table variant="simple">
            <Thead position={'sticky'} top={0} backgroundColor={'white'}>
              <Tr>
                <Th>
                  <TableHeadCheckbox />
                </Th>
                <Th>企業名</Th>
                <Th>種別</Th>
                <Th>枚数</Th>
                <Th>登録日時</Th>
                <Th>解析ステータス</Th>
                <Th>DL済</Th>
                <Th>確認済</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                allDocumentCount !== undefined &&
                allDocumentCount === 0 && (
                  <Tr>
                    <Td colSpan={9} textAlign={'center'}>
                      No Data
                    </Td>
                  </Tr>
                )}
              {documents && <TableBody onOpen={onOpen} documents={documents} />}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && allDocumentCount !== undefined && allDocumentCount > 0 && (
        <Box mt={4}>
          <Center>
            <Pagination
              allCount={allDocumentCount}
              currentPage={pageNum}
              countPerPage={countPerPage}
              link={path}
            />
          </Center>
        </Box>
      )}
    </>
  )
}

export default DocumentList
