import { deflate } from 'fflate'

/**
 * ファイルを圧縮する関数
 * @param {File} file - inputから取得したFileListオブジェクト
 * @return {Promise<Uint8Array>} - 圧縮されたファイルデータの配列を解決するPromise
 */
export const compressFile = (file: File): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    
    reader.onload = (e) => {
      const binaryData = new Uint8Array(e.target?.result as ArrayBuffer)
      deflate(binaryData, (err, compressedData) => {
        if (err) {
          reject(`Compression error for file "${file.name}": ${err}`)
          return;
        }
        resolve(compressedData)
      })
    }

    reader.onerror = () => {
      reject(`Read error for file "${file.name}"`);
    }

    reader.readAsArrayBuffer(file)
  })
}
  