import { atom } from 'recoil'
import { ocrResultPageType } from 'src/values/document'

export const checkedListState = atom<boolean[]>({
  key: 'documentList.checkedList',
  default: [],
})

export const allCheckboxActionState = atom<{ action: 'on' | 'off' }>({
  key: 'documentList.allCheckboxAction',
  default: { action: 'off' },
})

export const ocrResultPagesState = atom<{
  [key: number]: ocrResultPageType
}>({
  key: 'documentList.ocrResultPages',
  default: {},
})

export const deliveryCheckboxState = atom<boolean>({
  key: 'documentList.deliveryCheckbox',
  default: false,
})

export const invoiceCheckboxState = atom<boolean>({
  key: 'documentList.invoiceCheckbox',
  default: false,
})

export const orderCheckboxState = atom<boolean>({
  key: 'documentList.orderCheckbox',
  default: false,
})

export const analyzingCheckboxState = atom<boolean>({
  key: 'documentList.analyzingCheckbox',
  default: false,
})

export const analyzedCheckboxState = atom<boolean>({
  key: 'documentList.analyzedCheckbox',
  default: false,
})

export const analyzeErrorCheckboxState = atom<boolean>({
  key: 'documentList.analyzeErrorCheckbox',
  default: false,
})

export const isDownloadedCsvCheckboxState = atom<boolean>({
  key: 'documentList.isDownloadedCsvCheckbox',
  default: false,
})

export const notIsDownloadedCsvCheckboxState = atom<boolean>({
  key: 'documentList.notIsDownloadedCsvCheckbox',
  default: false,
})

export const isCheckedCheckboxState = atom<boolean>({
  key: 'documentList.isCheckedCheckboxState',
  default: false,
})

export const notIsCheckedCheckboxState = atom<boolean>({
  key: 'documentList.notIsCheckedCheckboxState',
  default: false,
})

export const numberOfPageState = atom<number>({
  key: 'documentList.numberOfPage',
  default: 1,
})

export const currentPageNumState = atom<number>({
  key: 'documentList.currentPageNum',
  default: 1,
})

export type DetailModalProps = {
  documentId: number
  createdAt: number
  numberOfPages: number
  isDownloadedCsv: number
  fileName: string
  status: number
  tenantId: number
  ocrResultId: number
}
export const detailModalPropsState = atom<DetailModalProps | null>({
  key: 'documentList.detailModalProps',
  default: null,
})

// モーダルクローズ時のダイアログ表示チェックに使用
export const editStateInCurrentPage = atom<boolean>({
  key: 'documentList.hasEditItemInCurrentPage',
  default: false,
})
