import { VStack, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { OcrParse } from 'src/ocrParser/ocrParser'
import {
  ocrResultPagesState,
  bboxListState,
  currentPageNumState,
} from 'src/state'
import DocumentTypeLabel from '../../DocumentTypeLabel'
import OcrResultItem from './OcrResultItem'
import OcrEditSubmitButton from './OcrEditSubmitButton'

export const getElementId = (id: number | undefined) =>
  id ? `document-detail-display-text-input-${id}` : ''

type OcrResultItemListProps = {
  tenantId: number
  documentId: number
  closeModal: () => void
  ocrParser: OcrParse | null | undefined
}

function OcrResultItemList({
  tenantId,
  documentId,
  closeModal,
  ocrParser,
}: OcrResultItemListProps) {
  const ocrResultPages = useRecoilValue(ocrResultPagesState)
  const currentPageNumber = useRecoilValue(currentPageNumState)
  const ocrResultPage = ocrResultPages?.[currentPageNumber]

  const bboxState = useRecoilValue(bboxListState)

  return (
    <>
      {ocrResultPage && (
        <VStack w={'100%'}>
          <Table align="left" pt="24px" w={'100%'} layout={'fixed'}>
            <Tbody>
              <Tr>
                <Td fontWeight="bold" color="gray.600" w={'25%'}>
                  帳票
                </Td>
                <Td>
                  {ocrResultPage?.documentTypeId && (
                    <DocumentTypeLabel
                      documentTypeId={ocrResultPage.documentTypeId}
                    />
                  )}
                </Td>
              </Tr>
              {bboxState[currentPageNumber] &&
                bboxState[currentPageNumber].map((data) => (
                  <OcrResultItem
                    key={data.id}
                    id={data.id}
                    pageNumber={currentPageNumber}
                    ocrItemName={data.ocrItemName}
                    displayText={data.text}
                    isEdited={data.isEdit}
                  />
                ))}
            </Tbody>
          </Table>
          <OcrEditSubmitButton
            tenantId={tenantId}
            documentId={documentId}
            closeModal={closeModal}
            ocrParser={ocrParser}
          />
        </VStack>
      )}
    </>
  )
}

export default OcrResultItemList
