import { Box, Button } from '@chakra-ui/react'
import {
  newOcrResultItem,
  postRetryPatternMatch,
} from 'src/api/retryPatternMatch'
import {
  ocrResultPagesState,
  bboxListState,
  canvasRatioState,
  isRetryPatternState,
  pdfImageSizeState,
  selectOcrFormatState,
  documentsState,
  currentPageNumState,
  numberOfPageState,
  editStateInCurrentPage,
} from 'src/state'
import { useState } from 'react'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { OcrParse } from 'src/ocrParser/ocrParser'
import {
  useUpdateEditOcrResultItemMutation,
  useUpdateEditOcrPageFormatMutation,
  GetDocumentsByPageQuery,
  useUpdateDocumentOcrClientNameMutation,
  UpdateOcrResultItemMutation,
  useUpdateDocumentOcrIsCheckedMutation,
} from 'src/graphql/generated'
import { clientNameOcrItemIds } from 'src/values'

export type ocrEditSubmitButtonProp = {
  tenantId: number
  documentId: number
  closeModal: () => void
  ocrParser: OcrParse | null | undefined
}
type documentsType = GetDocumentsByPageQuery['documentsByPage']

function OcrEditSubmitButton({
  tenantId,
  documentId,
  closeModal,
  ocrParser,
}: ocrEditSubmitButtonProp) {
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

  // PDF ページ管理
  const [currentPageNumber, setCurrentPageNumber] =
    useRecoilState(currentPageNumState)
  const numberOfPages = useRecoilValue(numberOfPageState)

  // ocr パターンマッチ再実行フラグ
  const isRetryPattern = useRecoilValue(isRetryPatternState)
  const selectedOcrFormatId = useRecoilValue(selectOcrFormatState)

  // ocr結果
  const ocrResultPages = useRecoilValue(ocrResultPagesState)
  const ocrResultPage = ocrResultPages?.[currentPageNumber]

  // ocr画像サイズ情報
  const ocrImageSize = ocrParser?.ocrSize
  const pdfImageSize = useRecoilValue(pdfImageSizeState)
  const canvasRatio = useRecoilValue(canvasRatioState)
  const bboxState = useRecoilValue(bboxListState)

  // 帳票一覧
  const setDocuments = useSetRecoilState(documentsState)

  // 編集済みフラグ
  const resetEditState = useResetRecoilState(editStateInCurrentPage)

  const updateOcrResultItemMutation = useUpdateEditOcrResultItemMutation()
  const updateOcrPageEditFormatId = useUpdateEditOcrPageFormatMutation()
  const updateClientName = useUpdateDocumentOcrClientNameMutation()
  const updateIsChecked = useUpdateDocumentOcrIsCheckedMutation()

  const isLastPage = currentPageNumber === numberOfPages

  const onClickSubmitButton = async () => {
    setIsLoadingUpdate(true)

    // パターンマッチ再実行ケース
    if (isRetryPattern) {
      // リクエストデータのチェック
      if (ocrResultPage?.ocrResultId && ocrResultPage?.id && ocrImageSize) {
        // 帳票パターンマッチ再実行時のDB登録リクエスト
        const newOcrResultItems: newOcrResultItem[] = []

        const pdf2ocrImageRatio = {
          width: ocrImageSize.width / pdfImageSize.width,
          height: ocrImageSize.height / pdfImageSize.height,
        }

        // ocr実行画像サイズにbboxを変換
        bboxState[currentPageNumber].forEach((item) => {
          const text = item.text ? item.text : ''
          newOcrResultItems.push({
            ocrFormatItemId: item.ocrFormatItemId,
            ocrItemId: item.ocrItemId,
            text: text,
            xmin: Math.floor(item.xmin * pdf2ocrImageRatio.width),
            xmax: Math.floor(item.xmax * pdf2ocrImageRatio.width),
            ymin: Math.floor(item.ymin * pdf2ocrImageRatio.height),
            ymax: Math.floor(item.ymax * pdf2ocrImageRatio.height),
          })
        })

        const documentTypeId = selectedOcrFormatId[currentPageNumber].documentTypeId

        if (ocrResultPage && documentTypeId) {
          // DB登録リクエスト
          const responce = await postRetryPatternMatch({
            tenantId: tenantId,
            documentId: documentId,
            pageNumber: currentPageNumber,
            documentTypeId: documentTypeId,
            ocrResultId: ocrResultPage.ocrResultId,
            ocrResultPageId: ocrResultPage.id,
            newOcrResultItems: newOcrResultItems,
          })

          // ocr format idの更新
          updateOcrPageEditFormatId.mutate({
            id: ocrResultPage.id,
            ocrFormatId: selectedOcrFormatId[currentPageNumber].id,
          })

          if (!responce.status) {
            console.error('error request')
          }
        }
      } else {
        // リクエストデータに不備がある場合
        console.error('リクエストデータに不備があります')
      }
    } else {
      // 帳票パターンマッチ再実行してない場合のDB登録リクエスト
      const promiseList: Promise<UpdateOcrResultItemMutation>[] = []
      bboxState[currentPageNumber].forEach((data) => {
        if (!data) return
        if (data.isEdit) {
          promiseList.push(
            updateOcrResultItemMutation.mutateAsync({
              tenantId: tenantId,
              id: data.id,
              displayText: data.text ? data.text : '',
              isEdited: 1,
              xMin: Math.floor(data.xmin / canvasRatio.width),
              xMax: Math.floor(data.xmax / canvasRatio.width),
              yMin: Math.floor(data.ymin / canvasRatio.height),
              yMax: Math.floor(data.ymax / canvasRatio.height),
            }),
          )

          if (
            data.text &&
            data.ocrItemId &&
            currentPageNumber == 1 &&
            clientNameOcrItemIds.includes(data.ocrItemId)
          ) {
            // DocumentOcrの更新
            updateClientName.mutate(
              {
                documentId: documentId,
                clientName: data.text,
              },
              {
                onSuccess(data) {
                  // 最後のページはチェック済みフラグ更新時に再レンダリングされるためスキップする
                  if (isLastPage) return

                  setDocuments((documents) => {
                    const clonedDocuments: documentsType =
                      structuredClone(documents)
                    if (!clonedDocuments) return documents
                    const index = clonedDocuments?.findIndex(
                      (document) => document.id === documentId,
                    )
                    if (index === -1) return documents
                    clonedDocuments[index].documentOcr =
                      data.updateDocumentOcrClientName
                    return clonedDocuments
                  })
                },
              },
            )
          }
        }
      })
      await Promise.all(promiseList)
    }

    // 編集済みフラグのリセット
    resetEditState()

    if (!isLastPage) {
      setCurrentPageNumber(currentPageNumber + 1)
      setIsLoadingUpdate(false)
      const el = document.getElementById('ocrResultItemList')
      el?.scrollTo(0, 0)
      return
    }

    // チェック済みフラグの更新
    updateIsChecked.mutate(
      {
        documentId: documentId,
        isChecked: 1,
      },
      {
        onSuccess(data) {
          setDocuments((documents) => {
            const clonedDocuments: documentsType = structuredClone(documents)
            if (!clonedDocuments) return documents
            const index = clonedDocuments?.findIndex(
              (document) => document.id === documentId,
            )
            if (index === -1) return documents
            clonedDocuments[index].documentOcr = data.updateDocumentOcrIsChecked
            return clonedDocuments
          })
          setIsLoadingUpdate(false)
          closeModal()
        },
      },
    )
  }

  return (
    <Box mt={4} mb="64px" w={'100%'}>
      <Button
        colorScheme="blue"
        w="100%"
        type="submit"
        borderRadius="0"
        py="32px"
        onClick={onClickSubmitButton}
        isLoading={isLoadingUpdate}
      >
        {currentPageNumber === numberOfPages
          ? '更新して閉じる'
          : '保存して次へ'}
      </Button>
    </Box>
  )
}

export default OcrEditSubmitButton
