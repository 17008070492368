import { Flex, Text, Box, Link } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { removeBpoTenantId } from 'src/values/bpoTenantId'
import { removeTokens } from 'src/infra/token'

function AppHeader() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const onClickLogout = () => {
    removeTokens()

    removeBpoTenantId()
    queryClient.clear()

    window.scrollTo(0, 0)
    navigate('/login')
  }

  return (
    <header>
      <Flex color="white" h="4.5rem">
        <Box flex="1" bg="#2F6B9F" h="4.5rem" py="4" pos="fixed" w="full">
          <Box pl="8">
            <Text fontSize="2xl" as="b">
              Tomoraku OCR
            </Text>
          </Box>
          <Box pr="2">
            <Text textAlign="right">
              <Link onClick={onClickLogout}>ログアウト</Link>
            </Text>
          </Box>
        </Box>
      </Flex>
    </header>
  )
}

export default AppHeader
