import { getUserProfile } from 'src/api/auth'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { userProfileQueryKey } from 'src/values'

function Root() {
  const navigate = useNavigate()
  useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async () => {
      navigate('/documents')
    },
    refetchOnWindowFocus: false,
  })

  return <></>
}

export default Root
