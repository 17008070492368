import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { getDocumentPdfUrl } from 'src/api/documentPdfUrl'
import DocumentEditBox from './DocumentEditBox'

type DocumentContainerProps = {
  tenantId: number
  documentId: number
  fileName: string
  closeModal: () => void
}
function DocumentEditContainer({
  tenantId,
  documentId,
  fileName,
  closeModal,
}: DocumentContainerProps) {
  const [pdfUrl, setPdfUrl] = useState<string>('')

  const { data } = useQuery({
    queryKey: [`document-pdf-url-${documentId}`],
    queryFn: async () =>
      await getDocumentPdfUrl({ tenantId, documentId, fileName }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })
  useEffect(() => {
    if (data) {
      setPdfUrl(data.presignedUrl)
    }
  }, [data])

  return (
    <>
      {pdfUrl && (
        <DocumentEditBox
          pdfUrl={pdfUrl}
          closeModal={closeModal}
          tenantId={tenantId}
          documentId={documentId}
        />
      )}
    </>
  )
}

export default DocumentEditContainer
