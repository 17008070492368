import { pdfjs } from 'react-pdf'

export const getPageRotate = async (fileUrl: string, pageNumber: number) => {
  try {
    const loadingTask = pdfjs.getDocument(fileUrl)
    const pdfDocument = await loadingTask.promise
    const page = await pdfDocument.getPage(pageNumber)

    return page.rotate
  } catch (error) {
    console.error('Error fetching rotation:', error)
    return 0
  }
}
