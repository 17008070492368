import { getDocumentTypeLabel } from 'src/values'
import { format } from 'date-fns'

export const getCsvFileName = (
  documentTypeId: number,
  clientName: string | null | undefined
) => {
  const array = []

  array.push('tomorakuocr')
  array.push(getDocumentTypeLabel(documentTypeId).text)
  if (clientName) array.push(clientName)
  array.push(format(new Date(), 'yyyyMMddHHmmss'))
  return `${array.join('_')}.csv`
}
