import { Input } from '@chakra-ui/react'
import { LegacyRef } from 'react'
import { format } from 'date-fns'

const CreatedAtGteInput = (
  {
    createdAtGte,
  }: {
    createdAtGte: number | undefined
  },
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <>
      <Input
        type="date"
        defaultValue={
          createdAtGte
            ? format(new Date(createdAtGte), 'yyyy-MM-dd')
            : undefined
        }
        ref={ref}
      />
    </>
  )
}
export default CreatedAtGteInput
