import { Box, Center } from '@chakra-ui/react'
import { useRef, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { getPageRotate } from 'src/pdfTools/getPageRotate'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  ocrResultPagesState,
  bboxListState,
  boundingBoxType,
  canvasRatioState,
  ocrRawResultType,
  pdfImageSizeState,
  rotateType,
  currentPageNumState,
  ocrPdfCanvas,
  mulchEditBboxRatioState,
  mulchEditBboxState,
} from 'src/state'
import { DrawingPage } from './DrawingPdfPage'
import 'src/styles/pdf.css'
import { changeBBoxScale } from 'src/drawingCanvasTools/ocrItemBboxTools'
import { changeEditBboxScale } from 'src/drawingCanvasTools/bboxCanvasTools'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type PdfProps = {
  url: string
  scale: number
  rotate: rotateType
  setRotate: React.Dispatch<React.SetStateAction<rotateType>>
  rawOcrResults: ocrRawResultType | null
}

const pdfOptions = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
}

function Pdf({ url, scale, rotate, setRotate, rawOcrResults }: PdfProps) {
  const currentPageNumber = useRecoilValue(currentPageNumState)
  const [isPdfRotateLoading, setPdfRotateLoading] = useState(false)

  // 回転情報を持っていない場合、pdfから回転情報を取得
  const fetchRotate = async () => {
    if (currentPageNumber in rotate == false && isPdfRotateLoading === false) {
      setPdfRotateLoading(true) // getPageRotate 実行中に再レンダリングが走り何度もリクエストを送ってしまうことを防ぐ
      const intiRotate = await getPageRotate(url, currentPageNumber)
      setPdfRotateLoading(false)
      setRotate({
        ...rotate,
        [currentPageNumber]: { viewRotate: intiRotate, editRotate: 0 },
      })
    }
  }

  // pdfレンダリングごとに回転情報を取得
  fetchRotate()

  //canva infomations
  const pageCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const setOcrPdfCanvas = useSetRecoilState(ocrPdfCanvas)

  // init set canvas context
  useEffect(() => {
    if (pageCanvasRef.current) {
      setOcrPdfCanvas(pageCanvasRef.current)
    }
  }, [pageCanvasRef.current])

  // ocr result infomations
  const ocrResultPages = useRecoilValue(ocrResultPagesState)
  const [boundingBoxState, setBBoxState] = useRecoilState(bboxListState)
  const ocrResultPage = ocrResultPages?.[currentPageNumber]
  const ocrFormat = ocrResultPage?.ocrFormat
  const [pdfImageSize, setpdfImageSize] = useRecoilState(pdfImageSizeState)
  const setcanvasPointRatio = useSetRecoilState(canvasRatioState)
  const ocrResultItems = ocrResultPage?.ocrResultItems

  // mulch edit bbox
  const setMulchEditBbox = useSetRecoilState(mulchEditBboxState)
  const mulchEditRatioBbox = useRecoilValue(mulchEditBboxRatioState)

  const cretateBBoxs = (canvasRatio: { width: number; height: number }) => {
    // 座標と読み取り情報の初期更新
    const ocrReultBBoxs: boundingBoxType[] = []
    ocrResultItems?.forEach((data) => {
      if (
        data &&
        data.xMin &&
        data.xMax &&
        data.yMin &&
        data.yMax &&
        data.ocrItem?.id &&
        data.ocrFormatItemId &&
        data.ocrItemId
      ) {
        ocrReultBBoxs.push({
          id: data.id,
          text: data.displayText,
          ocrFormatItemId: data.ocrFormatItemId,
          ocrItemId: data.ocrItemId,
          ocrItemName: data.ocrItem?.name,
          xmin: data.xMin * canvasRatio.width,
          xmax: data.xMax * canvasRatio.width,
          ymin: data.yMin * canvasRatio.height,
          ymax: data.yMax * canvasRatio.height,
          rawXmin: data.xMin,
          rawXmax: data.xMax,
          rawYmin: data.yMin,
          rawYmax: data.yMax,
          isEdit: data.isEdited ? true : false,
          isMulchSelected: false,
        })
      }
    })
    return ocrReultBBoxs
  }

  const handleRender = () => {
    if (pageCanvasRef.current) {
      const computedStyles = window.getComputedStyle(pageCanvasRef.current)

      const width = Number(computedStyles.width.replace('px', ''))
      const height = Number(computedStyles.height.replace('px', ''))

      setpdfImageSize({
        width: width,
        height: height,
      })

      if (ocrFormat?.width && ocrFormat?.height) {
        const canvasPointRatio = {
          width: width / ocrFormat.width,
          height: height / ocrFormat.height,
        }

        setcanvasPointRatio(canvasPointRatio)

        if (mulchEditRatioBbox) {
          setMulchEditBbox(
            changeEditBboxScale(mulchEditRatioBbox, canvasPointRatio),
          )
        }

        if (currentPageNumber in boundingBoxState) {
          // 現在のページのデータがある場合は座標スケールのみ更新
          setBBoxState({
            ...boundingBoxState,
            [currentPageNumber]: changeBBoxScale(
              canvasPointRatio,
              boundingBoxState[currentPageNumber],
            ),
          })
        } else {
          // 現在のページのデータがない場合はデータを追加
          setBBoxState({
            ...boundingBoxState,
            [currentPageNumber]: cretateBBoxs(canvasPointRatio),
          })
        }
      }
    }
  }

  return (
    <>
      <Box h="100%" style={{ position: 'relative' }}>
        <Center maxWidth={'100%'}>
          <Document
            data-private
            className={['react-pdf__Document', 'custom-react-pdf__Document']}
            file={url}
            options={pdfOptions}
          >
            <DrawingPage
              imageSize={pdfImageSize}
              ocrResultParser={rawOcrResults?.[currentPageNumber.toString()]}
            />
            {rotate[currentPageNumber] && (
              <Page
                canvasRef={pageCanvasRef}
                onRenderSuccess={handleRender}
                pageNumber={currentPageNumber}
                scale={scale}
                rotate={rotate[currentPageNumber].viewRotate}
              />
            )}
          </Document>
        </Center>
      </Box>
    </>
  )
}

export default Pdf
