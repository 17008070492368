import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  useGetTenantQuery,
  useGetTenantUserByUserIdQuery,
} from 'src/graphql/generated'
import { format, differenceInCalendarDays } from 'date-fns'

function PwAlert() {
  const { data } = useGetTenantUserByUserIdQuery({})
  const { data: tenant } = useGetTenantQuery({})
  const [remainDays, setRemainDays] = useState<number>(999)
  const [expireDate, setExpireDate] = useState<Date>(new Date())

  useEffect(() => {
    if (tenant?.tenant?.isCheckPwExpire && data?.tenantUserByUserId?.pwExpire) {
      const pwExpire = new Date(parseInt(data.tenantUserByUserId.pwExpire))
      setExpireDate(pwExpire)

      const now = new Date()
      const diff = differenceInCalendarDays(pwExpire, now)
      setRemainDays(diff)
    }
  }, [data])
  return (
    <>
      {remainDays <= 15 && (
        <Alert status={remainDays <= 5 ? 'error' : 'warning'} mb="4">
          <AlertIcon />
          <AlertTitle>パスワードを更新してください</AlertTitle>
          <AlertDescription>
            パスワードの有効期限は{format(expireDate, 'yyyy年MM月dd日')}です。
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}

export default PwAlert
