import { authFetchApi } from 'src/values'

export const registerPattern = async (data: FormData) => {
  const response = await authFetchApi('regist-patterns', {
    method: 'POST',
    headers: {},
    body: data,
  })

  return response
}
