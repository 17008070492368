import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import HeaderWithSidebar from '../views/HeaderWithSidebar'
import TopHeading from '../templates/TopHeading'
import { useParams, useSearchParams } from 'react-router-dom'
import SearchPopover from '../views/documents/SearchPopover'
import CsvDownloadButton from '../views/documents/CsvDownloadButton'
import DetailModal from '../views/documents/DetailEditModal'
import DocumentsSearchFetch from '../views/documents/search/DocumentsSearchFetch'

function DocumentsSearch() {
  const { page } = useParams()
  const pageNum = (() => {
    if (!page) return 1
    const pageNum = parseInt(page, 10)
    return isNaN(pageNum) ? 1 : pageNum
  })()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [searchParams] = useSearchParams()
  const clientName = searchParams.get('clientName') ?? undefined
  const createParam = (name: string) => {
    const m = searchParams.get(name)
    if (!m) return undefined
    const n = parseInt(m, 10)
    return isNaN(n) ? undefined : n
  }
  const createdAtGte = createParam('createdAtGte')
  const createdAtLte = createParam('createdAtLte')
  const documentTypeIdList = searchParams.get('documentTypeId')?.split(',')
  const statusList = searchParams.get('status')?.split(',')
  const isDownloadedCsvList = searchParams.get('isDownloadedCsv')?.split(',')
  const isCheckedList = searchParams.get('isChecked')?.split(',')

  // string を number に変換
  const documentTypeIdListNumber = documentTypeIdList?.map((v) =>
    parseInt(v, 10),
  )
  const statusListNumber = statusList?.map((v) => parseInt(v, 10))
  const isDownloadedCsvListNumber = isDownloadedCsvList?.map((v) =>
    parseInt(v, 10),
  )
  const isCheckedListNumber = isCheckedList?.map((v) => parseInt(v, 10))

  return (
    <HeaderWithSidebar>
      <Box h="100%">
        <TopHeading title="帳票一覧" />
        <Flex my="4" justifyContent={'space-between'}>
          <CsvDownloadButton />
          <SearchPopover
            from={pageNum}
            clientName={clientName}
            documentTypeIdList={documentTypeIdListNumber}
            createdAtGte={createdAtGte}
            createdAtLte={createdAtLte}
            statusList={statusListNumber}
            isDownloadedCsvList={isDownloadedCsvListNumber}
            isCheckedList={isCheckedListNumber}
          />
        </Flex>
        <DocumentsSearchFetch
          onOpen={onOpen}
          from={pageNum}
          searchParamsString={searchParams.toString()}
          clientName={clientName}
          documentTypeIdList={documentTypeIdListNumber}
          createdAtGte={createdAtGte}
          createdAtLte={createdAtLte}
          statusList={statusListNumber}
          isDownloadedCsvList={isDownloadedCsvListNumber}
          isCheckedList={isCheckedListNumber}
        />
      </Box>
      {isOpen && <DetailModal isOpen={isOpen} onClose={onClose} />}
    </HeaderWithSidebar>
  )
}

export default DocumentsSearch
