import { storeTokens } from 'src/infra/token'
import { authFetchApi, fetchApi } from 'src/values'

export type userAuthForm = {
  username: string
  password: string
}

export type userAuthResponse = {
  AccessToken: string
  ExpiresIn: number
  RefreshToken: string
  IdToken: string
  TokenType: string
}

export type userProfileResponse = {
  userId: number
  username: string
  tenantId: number
} & Omit<userAuthResponse, 'ExpiresIn' | 'TokenType'>

export const postAuthLogin = async (
  data: userAuthForm,
): Promise<userAuthResponse & userProfileResponse> => {
  const userAuthBasic = window.btoa(`${data.username}:${data.password}`)

  const response = await fetchApi('auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${userAuthBasic}`,
    },
  })

  storeTokens({
    accessToken: response.AccessToken,
    refreshToken: response.RefreshToken,
    idToken: response.IdToken,
  })

  return response
}

export const getUserProfile = async (): Promise<userProfileResponse> => {
  const response = await authFetchApi('auth/profile', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  storeTokens({
    accessToken: response.AccessToken,
    refreshToken: response.RefreshToken,
    idToken: response.IdToken,
  })

  return response
}
