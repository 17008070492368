const key = 'bpoTenantId'
export const getBpoTenantId = () => {
  const bpoTenantId = sessionStorage.getItem(key)
  return bpoTenantId ? Number(bpoTenantId) : undefined
}

export const setBpoTenantId = (bpoTenantId: number) => {
  sessionStorage.setItem(key, bpoTenantId.toString())
}

export const removeBpoTenantId = () => {
  sessionStorage.removeItem(key)
}
