export type Point2D = {
  x: number
  y: number
}

export type Size2D = {
  width: number
  height: number
}

export type clickPointEnum = 
  "topLeft" | 
  "topRight" |
  "topCenter" |
  "leftCenter" |
  "rightCenter" |
  "bottomLeft" |
  "bottomRight"|
  "bottomCenter"


export type muluchEditPoints = {
  [key in clickPointEnum]: Point2D
}

export class Bbox {
  constructor(
    public xmin: number,
    public xmax:number,
    public ymin:number,
    public ymax:number
  ) {}

  static fromAzure(bounding_box: number[]) {
    return new Bbox(
      bounding_box[0],
      bounding_box[4],
      bounding_box[1],
      bounding_box[5]
    )
  }

  static fromArray(bounding_box: number[]) {
    return new Bbox(
      bounding_box[0],
      bounding_box[1],
      bounding_box[2],
      bounding_box[3]
    )
  }

  static empty() {
    return new Bbox(0, 0, 0, 0)
  }

  width(): number {
    return Math.abs(this.xmax - this.xmin)
  }

  height(): number {
    return Math.abs(this.ymax - this.ymin)
  }

  center(): Point2D {
    const width = this.width()
    const height = this.height()

    const center_x = Math.floor(this.xmax - (width / 2))
    const center_y = Math.floor(this.ymax - (height / 2))

    return {x: center_x, y: center_y}
  }

  is_includ(bbox: Bbox, th=5): boolean {
    const center = bbox.center()

    const isIncludX = this.xmin - th <= center.x && center.x <= this.xmax + th
    const isIncludY = this.ymin - th <= center.y && center.y <= this.ymax + th

    return isIncludX && isIncludY
  }

  is_overlapping(bbox: Bbox): boolean {
    // x軸で重なっていない場合、falseを返す
    if (this.xmax < bbox.xmin || bbox.xmax < this.xmin) {
        return false
    }

    // y軸で重なっていない場合、falseを返す
    if (this.ymax < bbox.ymin || bbox.ymax < this.ymin) {
        return false
    }

    // それ以外の場合、重なっている
    return true
  }

  editPoints(): muluchEditPoints {
    const center = this.center()

    return {
      topLeft: {x: this.xmin, y: this.ymin},
      topRight: {x: this.xmax, y: this.ymin},
      topCenter: {x: center.x, y: this.ymin},
      leftCenter: {x: this.xmin, y: center.y},
      rightCenter: {x: this.xmax, y: center.y},
      bottomLeft: {x: this.xmin, y: this.ymax},
      bottomRight: {x: this.xmax, y: this.ymax},
      bottomCenter: {x: center.x, y: this.ymax},
    }
  }

}


export class BBoxVector {
  vec: Bbox[]

  constructor(vec: Bbox[] | null) {
    if (vec) {
      this.vec = vec
    } else {
      this.vec = []
    }
  }

  push(bbox: Bbox) {
    this.vec.push(bbox)
  }

  centers(): Point2D[] {
    const centers:Point2D[] = []
    this.vec.forEach((bbox) => {
      centers.push(bbox.center())
    })

    return centers
  }

  joinBbox(): Bbox {
    const xmins: number[] = []
    const xmaxs: number[] = []
    const ymins: number[] = []
    const ymaxs: number[] = []

    this.vec.forEach((bbox) => {
      xmins.push(bbox.xmin)
      xmaxs.push(bbox.xmax)
      ymins.push(bbox.ymin)
      ymaxs.push(bbox.ymax)
    })

    return new Bbox(
      Math.min(...xmins),
      Math.max(...xmaxs),
      Math.min(...ymins),
      Math.max(...ymaxs),
    )
  }
}