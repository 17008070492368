import {
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import {
  FiCheckCircle,
  FiDownload,
  FiMinus,
  FiMoreHorizontal,
} from 'react-icons/fi'
import { formatTime } from '../../../util'
import {
  didAnalyze,
  isAnalyzing,
  getCsvFileName,
  getDocumentOcrStatusLabel,
  DocumentTypeId
} from '../../../values'
import DocumentTypeLabel from '../../templates/DocumentTypeLabel'
import DetailMenuItem from './DetailEditMenuItem'
import TableItemCheckbox from './TableItemCheckbox'
import { useMutation } from '@tanstack/react-query'
import { onSuccessDownloadCsv, postDownloadCsv } from 'src/api/downloadCsv'
import DeleteMenuItem from './DeleteMenuItem'
import { DocumentProps, factoryDocument } from 'src/values/document'
import { useSetRecoilState } from 'recoil'
import { detailModalPropsState, documentsState } from 'src/state'
import { GetDocumentsByPageQuery } from 'src/graphql/generated'
import CheckMenuItem from './CheckMenuItem'
import IsDownloadedCsvMenuItem from './IsCsvDownloadMenuItem'
import PdfDownloadMenuItem from './PdfDownloadMenuItem'

type TableItemProps = {
  onOpen: () => void
} & DocumentProps
const TableItem = ({
  onOpen,
  index,
  documentId,
  tenantId,
  ocrResultId,
  clientName,
  documentTypeId,
  documentNumber,
  numberOfPages,
  createdAt,
  dealtAt,
  totalMoneyAmount,
  status,
  isDownloadedCsv,
  isChecked,
  fileName,
}: TableItemProps) => {
  const isUnanalyzed = !didAnalyze(status)
  const setDocuments = useSetRecoilState(documentsState)
  const setDetailModalProps = useSetRecoilState(detailModalPropsState)

  const handleOpen = () => {
    if (isUnanalyzed) {
      return
    }
    setDetailModalProps({
      documentId,
      tenantId,
      ocrResultId,
      fileName,
      createdAt,
      numberOfPages,
      status,
      isDownloadedCsv,
    })
    onOpen()
  }

  type Documents = GetDocumentsByPageQuery['documentsByPage']
  const downloadCsvMutation = useMutation(postDownloadCsv, {
    onSuccess: async (data, variables) => {
      await onSuccessDownloadCsv(data, variables)
      const document = factoryDocument({
        documentId,
        tenantId,
        ocrResultId,
        clientName,
        documentTypeId,
        documentNumber,
        numberOfPages,
        createdAt,
        dealtAt,
        totalMoneyAmount,
        status,
        isDownloadedCsv: 1,
        isChecked,
        fileName,
      })
      setDocuments((documents) => {
        const clonedDocuments: Documents = structuredClone(documents)
        if (!clonedDocuments) return documents
        const i = clonedDocuments?.findIndex(
          (document) => document.id === documentId,
        )
        if (index === -1) return documents
        clonedDocuments[i] = document
        return clonedDocuments
      })
    },
  })

  return (
    <>
      <Tr cursor="pointer" _hover={{ bg: 'gray.100' }} onClick={handleOpen}>
        <Td>
          <TableItemCheckbox index={index} isDisabled={isUnanalyzed} />
        </Td>
        <Td>{clientName}</Td>
        <Td>
          {documentTypeId && (
            <DocumentTypeLabel documentTypeId={documentTypeId} />
          )}
        </Td>
        <Td>{numberOfPages}</Td>
        <Td>{createdAt && formatTime(new Date(createdAt))}</Td>
        <Td>
          <HStack spacing={4}>
            <Text>{getDocumentOcrStatusLabel(status)}</Text>
            {isAnalyzing(status) && <Spinner />}
          </HStack>
        </Td>
        <Td>
          {isDownloadedCsv === 1 ? (
            <Icon as={FiCheckCircle} color="blue.600" />
          ) : (
            <Icon as={FiMinus} />
          )}
        </Td>
        <Td>
          {isChecked === 1 ? (
            <Icon as={FiCheckCircle} color="blue.600" />
          ) : (
            <Icon as={FiMinus} />
          )}
        </Td>
        <Td>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<FiMoreHorizontal />}
              variant="ghost"
              onClick={(e) => e.stopPropagation()}
            ></MenuButton>
            <MenuList>
              <MenuItem
                icon={<FiDownload />}
                onClick={(e) => {
                  e.stopPropagation()

                  Object.values(DocumentTypeId).forEach(documentTypeId => {
                    const fileName = getCsvFileName(documentTypeId, clientName)
                    downloadCsvMutation.mutate({
                      documentIds: [documentId],
                      documentTypeId,
                      fileName,
                      tenantId,
                    })
                  })
                }}
                isDisabled={isUnanalyzed}
              >
                CSVをダウンロード
              </MenuItem>
              <PdfDownloadMenuItem documentId={documentId} tenantId={tenantId} fileName={fileName} />
              <DeleteMenuItem documentId={documentId} tenantId={tenantId} />
              <DetailMenuItem onOpen={onOpen} isDisabled={isUnanalyzed} />
              <CheckMenuItem
                documentId={documentId}
                isChecked={isChecked}
                isDisabled={isUnanalyzed}
              />
              <IsDownloadedCsvMenuItem
                documentId={documentId}
                isDownloadedCsv={isDownloadedCsv}
                isDisabled={isUnanalyzed}
              />
            </MenuList>
          </Menu>
        </Td>
      </Tr>
    </>
  )
}

export default TableItem
