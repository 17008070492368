import { authFetchApi } from 'src/values'
import { getAccessToken } from 'src/infra/token'

type ChangePasswordProps = {
  oldPassword: string
  newPassword: string
}

export const changePassword = async (data: ChangePasswordProps) => {
  const accessToken = getAccessToken()
  return await authFetchApi('auth/change-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': `${accessToken}`,
    },
    body: JSON.stringify(data),
  })
}
