import { Checkbox } from '@chakra-ui/react'
import { invoiceCheckboxState } from 'src/state'
import { useRecoilState } from 'recoil'
import { DocumentTypeId, getDocumentTypeLabel } from 'src/values'

const InvoiceCheckbox = () => {
  const [isChecked, setChecked] = useRecoilState(invoiceCheckboxState)

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setChecked(e.target.checked)}
    >
      {getDocumentTypeLabel(DocumentTypeId.INVOICE).text}
    </Checkbox>
  )
}

export default InvoiceCheckbox
