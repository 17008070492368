// 画像サイズの片方の長さを制限長さにしたときの、もう一方の長さを計算する
const adjustLargeLength = (
  limitLength: number,
  imageLongSide: number,
  imageShortSide: number
) => {
  const imageRatio = imageShortSide / imageLongSide
  return Math.round(limitLength * imageRatio)
}

// 画像サイズを制限サイズ内に比率を保ったまま変換する
const calcAdjustImageSize = (
  limitLongSide: number,
  limitShortSide: number,
  imageLongSide: number,
  imageShortSide: number
) => {
  const res = {
    longSide: 0,
    shortSide: 0,
  }
  // 長辺をlimitの長辺の長さにした時の高さを計算
  const adjustShortSide = adjustLargeLength(
    limitLongSide,
    imageLongSide,
    imageShortSide
  )

  // 計算された短辺がlimitを越していないかチェック
  if (adjustShortSide > limitShortSide) {
    const adjustLongSide = adjustLargeLength(
      limitShortSide,
      adjustShortSide,
      limitLongSide
    )

    res.longSide = adjustLongSide
    res.shortSide = limitShortSide
  } else {
    res.longSide = limitLongSide
    res.shortSide = adjustShortSide
  }

  return res
}

export function calcImageSize(
  imgWidth: number,
  imgHeight: number,
  maxWidth: number,
  maxHeight: number,
  margin = 2
): { width: number; height: number } {
  // マージンを考慮した制限サイズを計算
  const limitWidth = maxWidth - margin
  const limitHeight = maxHeight - margin
  const aspect_ratio = imgWidth / imgHeight

  const newImageSize = {
    width: 0,
    height: 0,
  }

  if (imgWidth > imgHeight) {
    // 細長い座標のための画像サイズ調整
    // 表表示になったら、再度サイズ調整修正する
    const widthRatio = aspect_ratio > 10 ? 1.2 : 1
    const heigthRatio = aspect_ratio > 10 ? 1.5 : 1

    const adjustImageSize = calcAdjustImageSize(
      limitWidth * widthRatio,
      limitHeight * heigthRatio,
      imgWidth,
      imgHeight
    )

    newImageSize.width = adjustImageSize.longSide
    newImageSize.height = adjustImageSize.shortSide
  } else {
    const adjustImageSize = calcAdjustImageSize(
      limitHeight,
      limitWidth,
      imgHeight,
      imgWidth
    )

    newImageSize.width = adjustImageSize.shortSide
    newImageSize.height = adjustImageSize.longSide
  }

  return newImageSize
}
