import { useEffect } from 'react'
export const historyState = { page: 'documentDetail' }

export const useBackButtonGuard = (handlePopstate: () => void) => {
  useEffect(() => {
    // 繰り返し帳票詳細を開くと何度もpushされるためすでにpushされている場合はpushしない
    if (!history.state || history.state.page !== 'documentDetail') {
      // これをしないと popstate が発火しない
      history.pushState(historyState, '', null)
    }
    window.addEventListener('popstate', handlePopstate, false)
    return () => {
      window.removeEventListener('popstate', handlePopstate, false)
    }
  }, [])
}
