import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiRotateCcw, FiRotateCw, FiMaximize } from 'react-icons/fi'
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
import {
  ocrResultPagesState,
  currentRotatestate,
  ocrRawResultType,
  currentPageNumState,
  numberOfPageState,
  editStateInCurrentPage,
  isEditMulchBboxState,
} from 'src/state'
import Pdf from './Pdf'

type PdfViewerProps = {
  pdfUrl: string
  rawOcrResults: ocrRawResultType | null
}
function PdfViewer({ pdfUrl, rawOcrResults }: PdfViewerProps) {
  const confirmMessage =
    'ページ遷移してよいですか？編集した項目を保存するためには保存ボタンを押す必要があります。'

  const [currentScaleIndex, setScaleIndex] = useState(2)
  const [currentRotate, setRotate] = useRecoilState(currentRotatestate)

  const [currentPageNumber, setPageNumber] = useRecoilState(currentPageNumState)
  const currentDeg = currentRotate[currentPageNumber]

  const [isEditMulchBbox, setIsEditMulchBbox] =
    useRecoilState(isEditMulchBboxState)

  const numberOfPages = useRecoilValue(numberOfPageState)
  const ocrResultPages = useRecoilValue(ocrResultPagesState)

  // 編集済みフラグ
  const resetEditState = useResetRecoilState(editStateInCurrentPage)

  const scaleList = [
    { scale: 0.5, text: '50%' },
    { scale: 0.75, text: '75%' },
    { scale: 1.0, text: '100%' },
    { scale: 1.25, text: '125%' },
    { scale: 1.5, text: '150%' },
    { scale: 2.0, text: '200%' },
    { scale: 3.0, text: '300%' },
    { scale: 4.0, text: '400%' },
  ]

  const getEditStatus = useRecoilCallback<[], Promise<boolean>>(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(editStateInCurrentPage)
      },
    [],
  )

  const Pagination = () => {
    const onClickLeft = async () => {
      const hasEditItem = await getEditStatus()
      if (!hasEditItem || confirm(confirmMessage)) {
        setPageNumber(Math.max(1, currentPageNumber - 1))
        resetEditState()
      }
    }
    const onClickRight = async () => {
      const hasEditItem = await getEditStatus()
      if (!hasEditItem || confirm(confirmMessage)) {
        setPageNumber(Math.min(currentPageNumber + 1, numberOfPages))
        resetEditState()
      }
    }
    const onInputPageNumber = (input: string) => {
      const pageNumber = Number(input)
      if (
        !Number.isInteger(pageNumber) ||
        pageNumber < 1 ||
        pageNumber > numberOfPages
      ) {
        return
      }
      setPageNumber(pageNumber)
      resetEditState()
    }
    return (
      <>
        <HStack justifyContent="center" borderRight="solid 1px white" h="100%">
          <Button
            isDisabled={currentPageNumber <= 1}
            color="white"
            aria-label="previous"
            variant="ghost"
            fontSize="2xl"
            onClick={onClickLeft}
          >
            ＜
          </Button>
          <Input
            type="number"
            color="white"
            backgroundColor="#403f3f"
            px="8px"
            py="2px"
            w="48px"
            border="none"
            height="70%"
            borderRadius={0}
            textAlign={'center'}
            defaultValue={currentPageNumber}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                // エンターキー押した時の処理
                onInputPageNumber(e.currentTarget.value)
              }
            }}
            onBlur={(e) => {
              onInputPageNumber(e.currentTarget.value)
            }}
          />
          <Text color="white" as="span">
            /
          </Text>
          <Text color="white" as="span">
            {numberOfPages}
          </Text>
          <Button
            isDisabled={currentPageNumber >= numberOfPages}
            color="white"
            aria-label="next"
            variant="ghost"
            fontSize="2xl"
            onClick={onClickRight}
          >
            ＞
          </Button>
        </HStack>
      </>
    )
  }

  const ScaleController = () => {
    const scaleListLength = scaleList.length
    const onClickScaleDown = () => {
      setScaleIndex(Math.max(0, currentScaleIndex - 1))
    }
    const onClickScaleUp = () => {
      setScaleIndex(Math.min(currentScaleIndex + 1, scaleListLength - 1))
    }
    return (
      <>
        <HStack justifyContent="center" borderRight="solid 1px white" h="100%">
          <Button
            isDisabled={currentScaleIndex <= 0}
            color="white"
            aria-label="previous"
            variant="ghost"
            onClick={onClickScaleDown}
          >
            ー
          </Button>
          <Text
            color="white"
            as="span"
            backgroundColor="#403f3f"
            px="8px"
            py="2px"
          >
            {scaleList[currentScaleIndex].text}
          </Text>
          <Button
            isDisabled={currentScaleIndex >= scaleListLength - 1}
            color="white"
            aria-label="next"
            variant="ghost"
            onClick={onClickScaleUp}
          >
            ＋
          </Button>
        </HStack>
      </>
    )
  }

  function normalizeAngle(angle: number): number {
    let normalizedAngle = angle % 360
    if (normalizedAngle < 0) {
      normalizedAngle += 360
    }
    return normalizedAngle
  }

  const RotateController = () => {
    const onClickRotateRight = () => {
      setRotate({
        ...currentRotate,
        [currentPageNumber]: {
          viewRotate: (currentDeg.viewRotate + 90) % 360,
          editRotate: normalizeAngle(currentDeg.editRotate + 90),
        },
      })
    }

    //console.log(currentDeg)

    const onClickRotateLeft = () => {
      setRotate({
        ...currentRotate,
        [currentPageNumber]: {
          viewRotate: (currentDeg.viewRotate + 270) % 360,
          editRotate: normalizeAngle(currentDeg.editRotate + 270),
        },
      })
    }

    const onClickEditMulch = () => {
      setIsEditMulchBbox(!isEditMulchBbox)
    }

    return (
      <>
        <HStack justifyContent="center" h="100%">
          <IconButton
            color="white"
            aria-label="previous"
            variant="ghost"
            icon={<FiRotateCw />}
            onClick={onClickRotateRight}
          />
          <IconButton
            color="white"
            aria-label="next"
            variant="ghost"
            icon={<FiRotateCcw />}
            onClick={onClickRotateLeft}
          />
          <IconButton
            colorScheme="blue"
            color="white"
            aria-label="next"
            variant={isEditMulchBbox ? 'solid' : 'ghost'}
            icon={<FiMaximize />}
            onClick={onClickEditMulch}
          />
        </HStack>
      </>
    )
  }

  return (
    <>
      {pdfUrl && (
        <VStack
          w={ocrResultPages ? '60%' : '100%'}
          backgroundColor="#242424"
          h="100%"
          px="8px"
        >
          <Box
            flex="1"
            backgroundColor="#686868"
            h="3.5rem"
            py="0"
            pos="sticky"
            top="64px"
            zIndex="20"
            w="full"
          >
            <Flex justifyContent="space-around">
              <Box w="33%">
                <Pagination />
              </Box>
              <Box w="33%">
                <ScaleController />
              </Box>
              <Box w="34%">
                <RotateController />
              </Box>
            </Flex>
          </Box>
          <Box overflow="scroll" h="100%" w="100%">
            <Pdf
              url={pdfUrl}
              scale={scaleList[currentScaleIndex].scale}
              rotate={currentRotate}
              setRotate={setRotate}
              rawOcrResults={rawOcrResults}
            />
          </Box>
        </VStack>
      )}
    </>
  )
}

export default PdfViewer
