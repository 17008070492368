import { Checkbox } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { checkedListState, allCheckboxActionState } from 'src/state'

const TableHeadCheckbox = () => {
  const [isChecked, setChecked] = useState(false)
  const [checkedList, setCheckedList] = useRecoilState(checkedListState)
  const dispatch = useSetRecoilState(allCheckboxActionState)
  const isAllChecked =
    checkedList.length === 0 ? false : checkedList.every(Boolean) // every は length 0 のとき無条件で true になるため
  const isIndeterminate = checkedList.some(Boolean) && !isAllChecked
  useEffect(() => {
    setChecked(isAllChecked)
  }, [checkedList])

  return (
    <Checkbox
      isChecked={isChecked}
      isIndeterminate={isIndeterminate}
      onChange={() => {
        const length = checkedList.length
        isChecked
          ? setCheckedList(Array(length).fill(false))
          : setCheckedList(Array(length).fill(true))
        dispatch({ action: isChecked ? 'off' : 'on' })
        setChecked(!isChecked)
      }}
    />
  )
}

export default TableHeadCheckbox
