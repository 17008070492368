import { atom } from 'recoil'
import { OcrParse } from 'src/ocrParser/ocrParser'
import { Bbox, Size2D } from 'src/ocrParser/ocrType'

export type boundingBoxType = {
  id: number // ocrResultItemId
  ocrFormatItemId: number
  ocrItemId: number
  text: string | null | undefined
  ocrItemName: string | null | undefined
  xmin: number
  xmax: number
  ymin: number
  ymax: number
  rawXmin: number
  rawXmax: number
  rawYmin: number
  rawYmax: number
  isEdit: boolean
  isMulchSelected: boolean
}

export type ocrRawResultType = {
  [index: string]: OcrParse | null
}

export type boundingBoxTypeByPage = {
  [key: number]: boundingBoxType[]
}

export type rotateType = {
  [key: number]: { viewRotate: number; editRotate: number }
}

export const currentRotatestate = atom<rotateType>({
  key: 'bpoDocumentList.currentRotate',
  default: {},
})

export const isEditMulchBboxState = atom<boolean>({
  key: 'bpoDocumentList.isEditMulchBbox',
  default: false,
})

export const mulchEditBboxState = atom<Bbox | null>({
  key: 'bpoDocumentList.mulchEditBbox',
  default: null,
})

// PDF拡大時にしようする複数座標編集ボックスの比率を保つState
export const mulchEditBboxRatioState = atom<Bbox | null>({
  key: 'bpoDocumentList.mulchEditBboxState',
  default: null,
})

export const bboxListState = atom<boundingBoxTypeByPage>({
  key: 'bpoDocumentList.bboxListState',
  default: {},
})

export const allPageOcrParsers = atom<ocrRawResultType | null>({
  key: 'bpoDocumentList.rawOcrResult',
  default: null,
})

export const currentChoiceOcrItemId = atom<number>({
  key: 'bpoDocumentList.currentChoiceOcrItemId',
  default: -1,
})

export const canvasRatioState = atom<Size2D>({
  key: 'bpoDocumentList.canvasRatio',
  default: { width: 0, height: 0 },
})

export const pdfImageSizeState = atom<Size2D>({
  key: 'bpoDocumentList.pdfImageSize',
  default: { width: 0, height: 0 },
})

export const pointImgCanvasSizeState = atom<Size2D>({
  key: 'bpoDocumentList.pointImgCanvasSizeState',
  default: { width: 0, height: 0 },
})

export const isRetryPatternState = atom<boolean>({
  key: 'bpoDocumentList.isRetryPattern',
  default: false,
})

export const oldOcrResultItemIdState = atom<number[]>({
  key: 'bpoDocumentList.oldOcrResultItemId',
  default: [],
})

export const currentOcrResultIdState = atom<number>({
  key: 'bpoDocumentList.retryOcrExecute',
  default: -1,
})

export type OcrFormatInterface = {
  __typename?: 'OcrFormat' | undefined
  id: number
  fileName?: string | null | undefined
  clientName?: string | null | undefined
  documentTypeId?: number | null | undefined
}

export const selectOcrFormatState = atom<{ [key: number]: OcrFormatInterface }>(
  {
    key: 'bpoDocumentList.selectedItemState',
    default: { 1: { id: -1, fileName: '', clientName: '',  documentTypeId: null} },
  },
)

export const currentOcrFormatIdState = atom<number | null>({
  key: 'bpoDocumentList.currentOcrFormatIdState',
  default: null
})

export const ocrRetryPageIdState = atom<number | undefined>({
  key: 'ocrRetryPageId',
  default: undefined,
})

export const ocrRetryCurrentPageNum = atom<number>({
  key: 'ocrRetryCurrentPageNum',
  default: 0,
})

export const ocrPdfCanvas = atom<HTMLCanvasElement | null>({
  key: 'ocrPdfCanvas',
  default: null,
})
