import { Input } from '@chakra-ui/react'
import { LegacyRef } from 'react'

const ClientNameInput = (
  {
    clientName,
  }: {
    clientName: string | undefined
  },
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <>
      <Input type="text" defaultValue={clientName} ref={ref} />
    </>
  )
}
export default ClientNameInput
