import { atom } from 'recoil'
import { userProfileResponse } from 'src/api/auth'

export const profileState = atom<userProfileResponse>({
  key: 'profile',
  default: {
    userId: 0,
    username: '',
    tenantId: 0,
    AccessToken: '',
    RefreshToken: '',
    IdToken: '',
  },
})
