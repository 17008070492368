import { Td, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useGetNotCheckedDocumentOcrQuery } from 'src/graphql/generated'
import { setBpoTenantId } from 'src/values/bpoTenantId'

type TableItemProps = {
  index: number
  tenantName: string
}

const TableItem = ({ index, tenantName }: TableItemProps) => {
  const navigate = useNavigate()

  const handleRowClick = (destination: string, index: number) => {
    setBpoTenantId(index)
    navigate(destination)
  }

  const fetchNotCheckedDocument = (index: number) => {
    const { data } = useGetNotCheckedDocumentOcrQuery({
      tenantId: index,
    })
    return data
  }
  const notCheckedDocumentOcrData = fetchNotCheckedDocument(index)
  const checkedDocumentCount =
    notCheckedDocumentOcrData?.getNotCheckedDocumentOcr
      ? notCheckedDocumentOcrData?.getNotCheckedDocumentOcr
      : 0

  return (
    <Tr
      cursor="pointer"
      _hover={{ bg: 'gray.100' }}
      onClick={() => handleRowClick('/documents', index)}
    >
      <Td>{tenantName}</Td>
      <Td>{checkedDocumentCount}</Td>
    </Tr>
  )
}

export default TableItem
