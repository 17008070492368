import TableItem from './TableItem'
import { GetDocumentsByPageQuery } from '../../../graphql/generated'
import { useSetRecoilState } from 'recoil'
import { checkedListState, allCheckboxActionState } from 'src/state'
import { useEffect } from 'react'

type documentsType = GetDocumentsByPageQuery['documentsByPage']
type TableBodyProps = {
  onOpen: () => void
  documents: documentsType
}
function TableBody({ onOpen, documents }: TableBodyProps) {
  const setCheckedList = useSetRecoilState(checkedListState)
  const dispatch = useSetRecoilState(allCheckboxActionState)
  useEffect(() => {
    setCheckedList(Array(documents?.length).fill(false))
    dispatch({ action: 'off' })
  }, [documents])

  return (
    <>
      {documents &&
        documents.map(
          (item, index) =>
            item &&
            item.documentOcr && (
              <TableItem
                data-private
                key={index}
                index={index}
                onOpen={onOpen}
                documentId={item.id}
                tenantId={item.documentOcr.tenantId}
                ocrResultId={item.documentOcr.ocrResultId}
                clientName={item.documentOcr.clientName}
                documentTypeId={item.documentOcr.documentTypeId}
                documentNumber={item.documentOcr.documentNumber}
                numberOfPages={item.numberOfPages}
                createdAt={item.createdAt}
                dealtAt={item.documentOcr.dealtAt}
                totalMoneyAmount={item.documentOcr.totalMoneyAmount}
                status={item.documentOcr.status}
                isDownloadedCsv={item.isDownloadedCsv}
                isChecked={item.documentOcr.isChecked}
                fileName={item.fileName}
              />
            )
        )}
    </>
  )
}

export default TableBody
