import { Checkbox } from '@chakra-ui/react'
import { isDownloadedCsvCheckboxState } from 'src/state'
import { useRecoilState } from 'recoil'

const IsDownloadedCsvCheckbox = () => {
  const [isChecked, setChecked] = useRecoilState(isDownloadedCsvCheckboxState)

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setChecked(e.target.checked)}
    >
      済
    </Checkbox>
  )
}

export default IsDownloadedCsvCheckbox
