import { MenuItem } from '@chakra-ui/react'
import { FiDownload } from 'react-icons/fi'
import { getDocumentPdfUrl } from 'src/api/documentPdfUrl'

type PdfDownloadMenuItemProps = {
  tenantId: number
  documentId: number
  fileName: string
}

const PdfDownloadMenuItem = ({
  tenantId,
  documentId,
  fileName,
}: PdfDownloadMenuItemProps) => {
  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation()

    // S3のpdf urlを取得
    const data = await getDocumentPdfUrl({ tenantId, documentId, fileName })
    const pdfUrl = data.presignedUrl

    // pdfをダウンロード
    const response = await fetch(pdfUrl)
    const blob = await response.blob()

    // ダウンロード用の仮リンクを作成
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName

    // 仮リンクをクリックしてダウンロードを開始
    document.body.appendChild(a)
    a.click()

    // ダウンロード完了後、仮リンクを削除
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
  return (
    <>
      <MenuItem
        icon={<FiDownload />}
        isDisabled={false}
        onClick={(e) => handleClick(e)}
      >
        PDFをダウンロード
      </MenuItem>
    </>
  )
}

export default PdfDownloadMenuItem
