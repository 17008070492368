import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query'
import { authGraphqlApi } from 'src/values/api'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: number; output: number }
}

export type Document = {
  __typename?: 'Document'
  createdAt: Scalars['BigInt']['output']
  createdBy: Scalars['BigInt']['output']
  documentOcr?: Maybe<DocumentOcr>
  fileName: Scalars['String']['output']
  filePath: Scalars['String']['output']
  id: Scalars['BigInt']['output']
  isDeleted: Scalars['Int']['output']
  isDownloadedCsv: Scalars['Int']['output']
  numberOfPages: Scalars['Int']['output']
  ocrResult?: Maybe<Array<Maybe<OcrResult>>>
  tenantId: Scalars['Int']['output']
  updatedAt: Scalars['BigInt']['output']
  updatedBy: Scalars['BigInt']['output']
}

export type DocumentOcr = {
  __typename?: 'DocumentOcr'
  checkBy: Scalars['BigInt']['output']
  clientName?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['BigInt']['output']
  dealtAt?: Maybe<Scalars['BigInt']['output']>
  documentId: Scalars['BigInt']['output']
  documentNumber?: Maybe<Scalars['String']['output']>
  documentTypeId?: Maybe<Scalars['Int']['output']>
  isChecked: Scalars['Int']['output']
  ocrResultId: Scalars['BigInt']['output']
  status: Scalars['Int']['output']
  tenantId: Scalars['Int']['output']
  totalMoneyAmount?: Maybe<Scalars['Int']['output']>
  updatedAt: Scalars['BigInt']['output']
}

export type DocumentType = {
  __typename?: 'DocumentType'
  OcrFormat?: Maybe<Array<Maybe<OcrFormat>>>
  OcrItemFormatTable?: Maybe<Array<Maybe<OcrItemFormatTable>>>
  code?: Maybe<Scalars['String']['output']>
  colorCode?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  displayOrder?: Maybe<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  logicalDelete?: Maybe<Document>
  updateDocumentOcrClientName?: Maybe<DocumentOcr>
  updateDocumentOcrIsChecked?: Maybe<DocumentOcr>
  updateEditOcrResultItem?: Maybe<OcrResultItem>
  updateIsDownloadedCsv: Document
  updateOcrResultItem?: Maybe<OcrResultItem>
  updateResultOcrFormatId?: Maybe<OcrResultPage>
}

export type MutationLogicalDeleteArgs = {
  id: Scalars['BigInt']['input']
  tenantId: Scalars['Int']['input']
}

export type MutationUpdateDocumentOcrClientNameArgs = {
  clientName: Scalars['String']['input']
  documentId: Scalars['BigInt']['input']
}

export type MutationUpdateDocumentOcrIsCheckedArgs = {
  documentId: Scalars['BigInt']['input']
  isChecked: Scalars['Int']['input']
}

export type MutationUpdateEditOcrResultItemArgs = {
  displayText: Scalars['String']['input']
  id: Scalars['BigInt']['input']
  isEdited: Scalars['Int']['input']
  tenantId: Scalars['Int']['input']
  xMax: Scalars['Int']['input']
  xMin: Scalars['Int']['input']
  yMax: Scalars['Int']['input']
  yMin: Scalars['Int']['input']
}

export type MutationUpdateIsDownloadedCsvArgs = {
  id: Scalars['BigInt']['input']
  isDownloadedCsv: Scalars['Int']['input']
}

export type MutationUpdateOcrResultItemArgs = {
  displayText: Scalars['String']['input']
  id: Scalars['BigInt']['input']
  isEdited: Scalars['Int']['input']
  tenantId: Scalars['Int']['input']
}

export type MutationUpdateResultOcrFormatIdArgs = {
  id: Scalars['BigInt']['input']
  ocrFormatId: Scalars['BigInt']['input']
}

export type OcrFormat = {
  __typename?: 'OcrFormat'
  OcrFormatItem?: Maybe<Array<Maybe<OcrFormatItem>>>
  OcrResultPage?: Maybe<Array<Maybe<OcrResultPage>>>
  clientName?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  createdBy?: Maybe<Scalars['BigInt']['output']>
  documentTypeId?: Maybe<Scalars['Int']['output']>
  fileName: Scalars['String']['output']
  filePath?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['Int']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  width?: Maybe<Scalars['Int']['output']>
}

export type OcrFormatItem = {
  __typename?: 'OcrFormatItem'
  OcrItem?: Maybe<OcrItem>
  OcrResultItem?: Maybe<Array<Maybe<OcrResultItem>>>
  createdAt?: Maybe<Scalars['String']['output']>
  displayOrder?: Maybe<Scalars['Int']['output']>
  excludedCharacters?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  level?: Maybe<Scalars['Int']['output']>
  ocrFormatId?: Maybe<Scalars['BigInt']['output']>
  ocrItemId?: Maybe<Scalars['BigInt']['output']>
  row?: Maybe<Scalars['Int']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  xMax?: Maybe<Scalars['Int']['output']>
  xMin?: Maybe<Scalars['Int']['output']>
  yMax?: Maybe<Scalars['Int']['output']>
  yMin?: Maybe<Scalars['Int']['output']>
}

export type OcrItem = {
  __typename?: 'OcrItem'
  OcrFormatItem?: Maybe<Array<Maybe<OcrFormatItem>>>
  OcrResultItem?: Maybe<Array<Maybe<OcrResultItem>>>
  createdAt?: Maybe<Scalars['String']['output']>
  displayOrder?: Maybe<Scalars['Int']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  ocrItemFormatTable?: Maybe<Array<Maybe<OcrItemFormatTable>>>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type OcrItemFormatTable = {
  __typename?: 'OcrItemFormatTable'
  createdAt?: Maybe<Scalars['String']['output']>
  createdBy?: Maybe<Scalars['BigInt']['output']>
  displayOrder?: Maybe<Scalars['Int']['output']>
  documentTypeId?: Maybe<Scalars['Int']['output']>
  isDeleted?: Maybe<Scalars['Int']['output']>
  ocrItemId?: Maybe<Scalars['BigInt']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  updatedBy?: Maybe<Scalars['BigInt']['output']>
}

export type OcrResult = {
  __typename?: 'OcrResult'
  DocumentOcr?: Maybe<Array<Maybe<DocumentOcr>>>
  OcrResultItem?: Maybe<Array<Maybe<OcrResultItem>>>
  OcrResultPage?: Maybe<Array<Maybe<OcrResultPage>>>
  clientName?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  dealtAt?: Maybe<Scalars['String']['output']>
  documentId?: Maybe<Scalars['BigInt']['output']>
  documentNumber?: Maybe<Scalars['String']['output']>
  documentTypeId?: Maybe<Scalars['Int']['output']>
  id: Scalars['BigInt']['output']
  status?: Maybe<Scalars['Int']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  totalMoneyAmount?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type OcrResultItem = {
  __typename?: 'OcrResultItem'
  createdAt?: Maybe<Scalars['String']['output']>
  displayText?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  isEdited?: Maybe<Scalars['Int']['output']>
  ocrFormatItemId?: Maybe<Scalars['BigInt']['output']>
  ocrItemId?: Maybe<Scalars['BigInt']['output']>
  ocrResultId?: Maybe<Scalars['BigInt']['output']>
  ocrResultPageId?: Maybe<Scalars['BigInt']['output']>
  readText?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  xMax?: Maybe<Scalars['Int']['output']>
  xMin?: Maybe<Scalars['Int']['output']>
  yMax?: Maybe<Scalars['Int']['output']>
  yMin?: Maybe<Scalars['Int']['output']>
}

export type OcrResultItemIncludeOcrItem = {
  __typename?: 'OcrResultItemIncludeOcrItem'
  createdAt?: Maybe<Scalars['String']['output']>
  displayText?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  isEdited?: Maybe<Scalars['Int']['output']>
  ocrFormatItemId?: Maybe<Scalars['BigInt']['output']>
  ocrItem?: Maybe<OcrItem>
  ocrItemId?: Maybe<Scalars['BigInt']['output']>
  ocrResultId?: Maybe<Scalars['BigInt']['output']>
  ocrResultPageId?: Maybe<Scalars['BigInt']['output']>
  readText?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  xMax?: Maybe<Scalars['Int']['output']>
  xMin?: Maybe<Scalars['Int']['output']>
  yMax?: Maybe<Scalars['Int']['output']>
  yMin?: Maybe<Scalars['Int']['output']>
}

export type OcrResultPage = {
  __typename?: 'OcrResultPage'
  createdAt?: Maybe<Scalars['String']['output']>
  documentTypeId?: Maybe<Scalars['Int']['output']>
  id: Scalars['BigInt']['output']
  ocrFormat?: Maybe<OcrFormat>
  ocrFormatId?: Maybe<Scalars['BigInt']['output']>
  ocrResultId?: Maybe<Scalars['BigInt']['output']>
  ocrResultItems?: Maybe<Array<Maybe<OcrResultItemIncludeOcrItem>>>
  pageNumber?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  allTenant: Array<Maybe<Tenant>>
  document?: Maybe<Document>
  documentCount: Scalars['Int']['output']
  documentOcr?: Maybe<DocumentOcr>
  documentType?: Maybe<DocumentType>
  documentsByPage?: Maybe<Array<Document>>
  getNotCheckedDocumentOcr: Scalars['Int']['output']
  ocrFormat?: Maybe<OcrFormat>
  ocrFormatByTenantId?: Maybe<Array<OcrFormat>>
  ocrFormatItem?: Maybe<OcrFormatItem>
  ocrItem?: Maybe<OcrItem>
  ocrItemFormatTable?: Maybe<OcrItemFormatTable>
  ocrResult?: Maybe<OcrResult>
  ocrResultItem?: Maybe<OcrResultItem>
  ocrResultPagesByOcrResultId?: Maybe<Array<Maybe<OcrResultPage>>>
  ocrResultPagesByOcrResultIdAndPageNumber?: Maybe<OcrResultPage>
  tenant?: Maybe<Tenant>
  tenantByPage?: Maybe<Array<Tenant>>
  tenantCount: Scalars['Int']['output']
  tenantSettingDocument?: Maybe<TenantSettingDocument>
  tenantUser?: Maybe<TenantUser>
  tenantUserByUserId?: Maybe<TenantUser>
  user?: Maybe<User>
  userToken?: Maybe<UserToken>
}

export type QueryDocumentArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryDocumentCountArgs = {
  tenantId: Scalars['Int']['input']
}

export type QueryDocumentOcrArgs = {
  documentId: Scalars['BigInt']['input']
}

export type QueryDocumentTypeArgs = {
  id: Scalars['Int']['input']
}

export type QueryDocumentsByPageArgs = {
  page: Scalars['Int']['input']
  tenantId: Scalars['Int']['input']
}

export type QueryGetNotCheckedDocumentOcrArgs = {
  tenantId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOcrFormatArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryOcrFormatByTenantIdArgs = {
  tenantId: Scalars['Int']['input']
}

export type QueryOcrFormatItemArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryOcrItemArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryOcrItemFormatTableArgs = {
  documentTypeId: Scalars['Int']['input']
  ocrItemId: Scalars['BigInt']['input']
  tenantId: Scalars['Int']['input']
}

export type QueryOcrResultArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryOcrResultItemArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryOcrResultPagesByOcrResultIdArgs = {
  ocrResultId: Scalars['BigInt']['input']
  tenantId: Scalars['Int']['input']
}

export type QueryOcrResultPagesByOcrResultIdAndPageNumberArgs = {
  ocrResultId: Scalars['BigInt']['input']
  pageNumber: Scalars['Int']['input']
  tenantId: Scalars['Int']['input']
}

export type QueryTenantByPageArgs = {
  page: Scalars['Int']['input']
}

export type QueryTenantSettingDocumentArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryTenantUserArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryUserArgs = {
  id: Scalars['BigInt']['input']
}

export type QueryUserTokenArgs = {
  id: Scalars['BigInt']['input']
}

export type Tenant = {
  __typename?: 'Tenant'
  Document?: Maybe<Array<Maybe<Document>>>
  DocumentOcr?: Maybe<Array<Maybe<DocumentOcr>>>
  OcrFormat?: Maybe<Array<Maybe<OcrFormat>>>
  OcrFormatItem?: Maybe<Array<Maybe<OcrFormatItem>>>
  OcrItemFormatTable?: Maybe<Array<Maybe<OcrItemFormatTable>>>
  OcrResult?: Maybe<Array<Maybe<OcrResult>>>
  createdAt?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  isCheckPwExpire: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  tenantSettingDocument?: Maybe<Array<Maybe<TenantSettingDocument>>>
  tenantUser?: Maybe<Array<Maybe<TenantUser>>>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type TenantSettingDocument = {
  __typename?: 'TenantSettingDocument'
  createdAt?: Maybe<Scalars['String']['output']>
  emailInbound?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  isDeleted?: Maybe<Scalars['Int']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type TenantUser = {
  __typename?: 'TenantUser'
  createdAt?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  invitedByUserId?: Maybe<Scalars['BigInt']['output']>
  pwExpire?: Maybe<Scalars['String']['output']>
  roleId?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['Int']['output']>
  tenantId?: Maybe<Scalars['Int']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['BigInt']['output']>
}

export type User = {
  __typename?: 'User'
  createdAt?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  name?: Maybe<Scalars['String']['output']>
  profileId?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Scalars['Int']['output']>
  tenantUser?: Maybe<Array<Maybe<TenantUser>>>
  updatedAt?: Maybe<Scalars['String']['output']>
  userToken?: Maybe<UserToken>
}

export type UserToken = {
  __typename?: 'UserToken'
  createdAt?: Maybe<Scalars['String']['output']>
  expiredAt?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  isDisabled?: Maybe<Scalars['Int']['output']>
  refreshToken?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
  userId?: Maybe<Scalars['BigInt']['output']>
}

export type GetNotCheckedDocumentOcrQueryVariables = Exact<{
  tenantId: Scalars['Int']['input']
}>

export type GetNotCheckedDocumentOcrQuery = {
  __typename?: 'Query'
  getNotCheckedDocumentOcr: number
}

export type GetdocumentOcrStateByDocumetIdQueryVariables = Exact<{
  documentId: Scalars['BigInt']['input']
}>

export type GetdocumentOcrStateByDocumetIdQuery = {
  __typename?: 'Query'
  documentOcr?: {
    __typename?: 'DocumentOcr'
    ocrResultId: number
    status: number
  } | null
}

export type UpdateDocumentOcrIsCheckedMutationVariables = Exact<{
  documentId: Scalars['BigInt']['input']
  isChecked: Scalars['Int']['input']
}>

export type UpdateDocumentOcrIsCheckedMutation = {
  __typename?: 'Mutation'
  updateDocumentOcrIsChecked?: {
    __typename?: 'DocumentOcr'
    clientName?: string | null
    dealtAt?: number | null
    documentId: number
    documentNumber?: string | null
    documentTypeId?: number | null
    isChecked: number
    ocrResultId: number
    status: number
    tenantId: number
    totalMoneyAmount?: number | null
  } | null
}

export type UpdateDocumentOcrClientNameMutationVariables = Exact<{
  documentId: Scalars['BigInt']['input']
  clientName: Scalars['String']['input']
}>

export type UpdateDocumentOcrClientNameMutation = {
  __typename?: 'Mutation'
  updateDocumentOcrClientName?: {
    __typename?: 'DocumentOcr'
    clientName?: string | null
    dealtAt?: number | null
    documentId: number
    documentNumber?: string | null
    documentTypeId?: number | null
    isChecked: number
    ocrResultId: number
    status: number
    tenantId: number
    totalMoneyAmount?: number | null
  } | null
}

export type GetDocumentQueryVariables = Exact<{
  id: Scalars['BigInt']['input']
}>

export type GetDocumentQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    id: number
    numberOfPages: number
    createdAt: number
    isDownloadedCsv: number
    fileName: string
    documentOcr?: {
      __typename?: 'DocumentOcr'
      documentId: number
      documentTypeId?: number | null
      documentNumber?: string | null
      clientName?: string | null
      dealtAt?: number | null
      totalMoneyAmount?: number | null
      status: number
    } | null
  } | null
}

export type GetDocumentsByPageQueryVariables = Exact<{
  tenantId: Scalars['Int']['input']
  page: Scalars['Int']['input']
}>

export type GetDocumentsByPageQuery = {
  __typename?: 'Query'
  documentCount: number
  documentsByPage?: Array<{
    __typename?: 'Document'
    id: number
    numberOfPages: number
    createdAt: number
    isDownloadedCsv: number
    fileName: string
    documentOcr?: {
      __typename?: 'DocumentOcr'
      documentId: number
      tenantId: number
      ocrResultId: number
      documentTypeId?: number | null
      documentNumber?: string | null
      clientName?: string | null
      dealtAt?: number | null
      totalMoneyAmount?: number | null
      isChecked: number
      status: number
    } | null
  }> | null
}

export type LogicalDeleteMutationVariables = Exact<{
  id: Scalars['BigInt']['input']
  tenantId: Scalars['Int']['input']
}>

export type LogicalDeleteMutation = {
  __typename?: 'Mutation'
  logicalDelete?: { __typename?: 'Document'; id: number } | null
}

export type UpdateIsDownloadedCsvMutationVariables = Exact<{
  id: Scalars['BigInt']['input']
  isDownloadedCsv: Scalars['Int']['input']
}>

export type UpdateIsDownloadedCsvMutation = {
  __typename?: 'Mutation'
  updateIsDownloadedCsv: {
    __typename?: 'Document'
    id: number
    isDownloadedCsv: number
  }
}

export type GetOcrFormatByTenantIdQueryVariables = Exact<{
  tenantId: Scalars['Int']['input']
}>

export type GetOcrFormatByTenantIdQuery = {
  __typename?: 'Query'
  ocrFormatByTenantId?: Array<{
    __typename?: 'OcrFormat'
    id: number
    fileName: string
    clientName?: string | null
    documentTypeId?: number | null
  }> | null
}

export type GetOcrFormatByIdQueryVariables = Exact<{
  ocrFormatId: Scalars['BigInt']['input']
}>

export type GetOcrFormatByIdQuery = {
  __typename?: 'Query'
  ocrFormat?: {
    __typename?: 'OcrFormat'
    width?: number | null
    height?: number | null
    documentTypeId?: number | null
    OcrFormatItem?: Array<{
      __typename?: 'OcrFormatItem'
      id: number
      ocrItemId?: number | null
      xMin?: number | null
      yMin?: number | null
      xMax?: number | null
      yMax?: number | null
      excludedCharacters?: string | null
      OcrItem?: {
        __typename?: 'OcrItem'
        id: number
        name?: string | null
      } | null
    } | null> | null
  } | null
}

export type UpdateOcrResultItemMutationVariables = Exact<{
  tenantId: Scalars['Int']['input']
  id: Scalars['BigInt']['input']
  displayText: Scalars['String']['input']
  isEdited: Scalars['Int']['input']
}>

export type UpdateOcrResultItemMutation = {
  __typename?: 'Mutation'
  updateOcrResultItem?: { __typename?: 'OcrResultItem'; id: number } | null
}

export type UpdateEditOcrResultItemMutationVariables = Exact<{
  tenantId: Scalars['Int']['input']
  id: Scalars['BigInt']['input']
  displayText: Scalars['String']['input']
  isEdited: Scalars['Int']['input']
  xMin: Scalars['Int']['input']
  xMax: Scalars['Int']['input']
  yMin: Scalars['Int']['input']
  yMax: Scalars['Int']['input']
}>

export type UpdateEditOcrResultItemMutation = {
  __typename?: 'Mutation'
  updateEditOcrResultItem?: { __typename?: 'OcrResultItem'; id: number } | null
}

export type GetOcrResultPagesByOcrResultIdQueryVariables = Exact<{
  tenantId: Scalars['Int']['input']
  ocrResultId: Scalars['BigInt']['input']
}>

export type GetOcrResultPagesByOcrResultIdQuery = {
  __typename?: 'Query'
  ocrResultPagesByOcrResultId?: Array<{
    __typename?: 'OcrResultPage'
    id: number
    ocrResultId?: number | null
    ocrFormatId?: number | null
    documentTypeId?: number | null
    status?: number | null
    createdAt?: string | null
    updatedAt?: string | null
    pageNumber?: number | null
    ocrFormat?: {
      __typename?: 'OcrFormat'
      fileName: string
      clientName?: string | null
      width?: number | null
      height?: number | null
    } | null
    ocrResultItems?: Array<{
      __typename?: 'OcrResultItemIncludeOcrItem'
      id: number
      ocrResultId?: number | null
      ocrResultPageId?: number | null
      ocrFormatItemId?: number | null
      ocrItemId?: number | null
      xMin?: number | null
      yMin?: number | null
      xMax?: number | null
      yMax?: number | null
      readText?: string | null
      displayText?: string | null
      isEdited?: number | null
      createdAt?: string | null
      updatedAt?: string | null
      ocrItem?: {
        __typename?: 'OcrItem'
        id: number
        name?: string | null
        displayOrder?: number | null
        createdAt?: string | null
        updatedAt?: string | null
        isDeleted?: number | null
      } | null
    } | null> | null
  } | null> | null
}

export type GetOcrResultPageByOcrResultIdAndPageNumberQueryVariables = Exact<{
  tenantId: Scalars['Int']['input']
  ocrResultId: Scalars['BigInt']['input']
  pageNumber: Scalars['Int']['input']
}>

export type GetOcrResultPageByOcrResultIdAndPageNumberQuery = {
  __typename?: 'Query'
  ocrResultPagesByOcrResultIdAndPageNumber?: {
    __typename?: 'OcrResultPage'
    id: number
    ocrResultId?: number | null
    status?: number | null
  } | null
}

export type UpdateEditOcrPageFormatMutationVariables = Exact<{
  id: Scalars['BigInt']['input']
  ocrFormatId: Scalars['BigInt']['input']
}>

export type UpdateEditOcrPageFormatMutation = {
  __typename?: 'Mutation'
  updateResultOcrFormatId?: { __typename?: 'OcrResultPage'; id: number } | null
}

export type GetTenantUserByUserIdQueryVariables = Exact<{
  [key: string]: never
}>

export type GetTenantUserByUserIdQuery = {
  __typename?: 'Query'
  tenantUserByUserId?: {
    __typename?: 'TenantUser'
    pwExpire?: string | null
  } | null
}

export type GetAllTenantQueryVariables = Exact<{ [key: string]: never }>

export type GetAllTenantQuery = {
  __typename?: 'Query'
  allTenant: Array<{
    __typename?: 'Tenant'
    id: number
    name?: string | null
  } | null>
}

export type GetTenantByPageQueryVariables = Exact<{
  page: Scalars['Int']['input']
}>

export type GetTenantByPageQuery = {
  __typename?: 'Query'
  tenantCount: number
  tenantByPage?: Array<{
    __typename?: 'Tenant'
    id: number
    name?: string | null
  }> | null
}

export type GetTenantQueryVariables = Exact<{ [key: string]: never }>

export type GetTenantQuery = {
  __typename?: 'Query'
  tenant?: {
    __typename?: 'Tenant'
    id: number
    name?: string | null
    isCheckPwExpire: number
  } | null
}

export const GetNotCheckedDocumentOcrDocument = `
    query GetNotCheckedDocumentOcr($tenantId: Int!) {
  getNotCheckedDocumentOcr(tenantId: $tenantId)
}
    `
export const useGetNotCheckedDocumentOcrQuery = <
  TData = GetNotCheckedDocumentOcrQuery,
  TError = unknown,
>(
  variables: GetNotCheckedDocumentOcrQueryVariables,
  options?: UseQueryOptions<GetNotCheckedDocumentOcrQuery, TError, TData>,
) =>
  useQuery<GetNotCheckedDocumentOcrQuery, TError, TData>(
    ['GetNotCheckedDocumentOcr', variables],
    authGraphqlApi<
      GetNotCheckedDocumentOcrQuery,
      GetNotCheckedDocumentOcrQueryVariables
    >(GetNotCheckedDocumentOcrDocument, variables),
    options,
  )
export const GetdocumentOcrStateByDocumetIdDocument = `
    query GetdocumentOcrStateByDocumetId($documentId: BigInt!) {
  documentOcr(documentId: $documentId) {
    ocrResultId
    status
  }
}
    `
export const useGetdocumentOcrStateByDocumetIdQuery = <
  TData = GetdocumentOcrStateByDocumetIdQuery,
  TError = unknown,
>(
  variables: GetdocumentOcrStateByDocumetIdQueryVariables,
  options?: UseQueryOptions<GetdocumentOcrStateByDocumetIdQuery, TError, TData>,
) =>
  useQuery<GetdocumentOcrStateByDocumetIdQuery, TError, TData>(
    ['GetdocumentOcrStateByDocumetId', variables],
    authGraphqlApi<
      GetdocumentOcrStateByDocumetIdQuery,
      GetdocumentOcrStateByDocumetIdQueryVariables
    >(GetdocumentOcrStateByDocumetIdDocument, variables),
    options,
  )
export const UpdateDocumentOcrIsCheckedDocument = `
    mutation UpdateDocumentOcrIsChecked($documentId: BigInt!, $isChecked: Int!) {
  updateDocumentOcrIsChecked(documentId: $documentId, isChecked: $isChecked) {
    clientName
    dealtAt
    documentId
    documentNumber
    documentTypeId
    isChecked
    ocrResultId
    status
    tenantId
    totalMoneyAmount
  }
}
    `
export const useUpdateDocumentOcrIsCheckedMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDocumentOcrIsCheckedMutation,
    TError,
    UpdateDocumentOcrIsCheckedMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateDocumentOcrIsCheckedMutation,
    TError,
    UpdateDocumentOcrIsCheckedMutationVariables,
    TContext
  >(
    ['UpdateDocumentOcrIsChecked'],
    (variables?: UpdateDocumentOcrIsCheckedMutationVariables) =>
      authGraphqlApi<
        UpdateDocumentOcrIsCheckedMutation,
        UpdateDocumentOcrIsCheckedMutationVariables
      >(UpdateDocumentOcrIsCheckedDocument, variables)(),
    options,
  )
export const UpdateDocumentOcrClientNameDocument = `
    mutation UpdateDocumentOcrClientName($documentId: BigInt!, $clientName: String!) {
  updateDocumentOcrClientName(documentId: $documentId, clientName: $clientName) {
    clientName
    dealtAt
    documentId
    documentNumber
    documentTypeId
    isChecked
    ocrResultId
    status
    tenantId
    totalMoneyAmount
  }
}
    `
export const useUpdateDocumentOcrClientNameMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateDocumentOcrClientNameMutation,
    TError,
    UpdateDocumentOcrClientNameMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateDocumentOcrClientNameMutation,
    TError,
    UpdateDocumentOcrClientNameMutationVariables,
    TContext
  >(
    ['UpdateDocumentOcrClientName'],
    (variables?: UpdateDocumentOcrClientNameMutationVariables) =>
      authGraphqlApi<
        UpdateDocumentOcrClientNameMutation,
        UpdateDocumentOcrClientNameMutationVariables
      >(UpdateDocumentOcrClientNameDocument, variables)(),
    options,
  )
export const GetDocumentDocument = `
    query GetDocument($id: BigInt!) {
  document(id: $id) {
    id
    numberOfPages
    createdAt
    isDownloadedCsv
    fileName
    documentOcr {
      documentId
      documentTypeId
      documentNumber
      clientName
      dealtAt
      totalMoneyAmount
      status
    }
  }
}
    `
export const useGetDocumentQuery = <TData = GetDocumentQuery, TError = unknown>(
  variables: GetDocumentQueryVariables,
  options?: UseQueryOptions<GetDocumentQuery, TError, TData>,
) =>
  useQuery<GetDocumentQuery, TError, TData>(
    ['GetDocument', variables],
    authGraphqlApi<GetDocumentQuery, GetDocumentQueryVariables>(
      GetDocumentDocument,
      variables,
    ),
    options,
  )
export const GetDocumentsByPageDocument = `
    query GetDocumentsByPage($tenantId: Int!, $page: Int!) {
  documentCount(tenantId: $tenantId)
  documentsByPage(tenantId: $tenantId, page: $page) {
    id
    numberOfPages
    createdAt
    isDownloadedCsv
    fileName
    documentOcr {
      documentId
      tenantId
      ocrResultId
      documentTypeId
      documentNumber
      clientName
      dealtAt
      totalMoneyAmount
      isChecked
      status
    }
  }
}
    `
export const useGetDocumentsByPageQuery = <
  TData = GetDocumentsByPageQuery,
  TError = unknown,
>(
  variables: GetDocumentsByPageQueryVariables,
  options?: UseQueryOptions<GetDocumentsByPageQuery, TError, TData>,
) =>
  useQuery<GetDocumentsByPageQuery, TError, TData>(
    ['GetDocumentsByPage', variables],
    authGraphqlApi<GetDocumentsByPageQuery, GetDocumentsByPageQueryVariables>(
      GetDocumentsByPageDocument,
      variables,
    ),
    options,
  )
export const LogicalDeleteDocument = `
    mutation LogicalDelete($id: BigInt!, $tenantId: Int!) {
  logicalDelete(id: $id, tenantId: $tenantId) {
    id
  }
}
    `
export const useLogicalDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LogicalDeleteMutation,
    TError,
    LogicalDeleteMutationVariables,
    TContext
  >,
) =>
  useMutation<
    LogicalDeleteMutation,
    TError,
    LogicalDeleteMutationVariables,
    TContext
  >(
    ['LogicalDelete'],
    (variables?: LogicalDeleteMutationVariables) =>
      authGraphqlApi<LogicalDeleteMutation, LogicalDeleteMutationVariables>(
        LogicalDeleteDocument,
        variables,
      )(),
    options,
  )
export const UpdateIsDownloadedCsvDocument = `
    mutation updateIsDownloadedCsv($id: BigInt!, $isDownloadedCsv: Int!) {
  updateIsDownloadedCsv(id: $id, isDownloadedCsv: $isDownloadedCsv) {
    id
    isDownloadedCsv
  }
}
    `
export const useUpdateIsDownloadedCsvMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateIsDownloadedCsvMutation,
    TError,
    UpdateIsDownloadedCsvMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateIsDownloadedCsvMutation,
    TError,
    UpdateIsDownloadedCsvMutationVariables,
    TContext
  >(
    ['updateIsDownloadedCsv'],
    (variables?: UpdateIsDownloadedCsvMutationVariables) =>
      authGraphqlApi<
        UpdateIsDownloadedCsvMutation,
        UpdateIsDownloadedCsvMutationVariables
      >(UpdateIsDownloadedCsvDocument, variables)(),
    options,
  )
export const GetOcrFormatByTenantIdDocument = `
    query GetOcrFormatByTenantId($tenantId: Int!) {
  ocrFormatByTenantId(tenantId: $tenantId) {
    id
    fileName
    clientName
    documentTypeId
  }
}
    `
export const useGetOcrFormatByTenantIdQuery = <
  TData = GetOcrFormatByTenantIdQuery,
  TError = unknown,
>(
  variables: GetOcrFormatByTenantIdQueryVariables,
  options?: UseQueryOptions<GetOcrFormatByTenantIdQuery, TError, TData>,
) =>
  useQuery<GetOcrFormatByTenantIdQuery, TError, TData>(
    ['GetOcrFormatByTenantId', variables],
    authGraphqlApi<
      GetOcrFormatByTenantIdQuery,
      GetOcrFormatByTenantIdQueryVariables
    >(GetOcrFormatByTenantIdDocument, variables),
    options,
  )
export const GetOcrFormatByIdDocument = `
    query GetOcrFormatById($ocrFormatId: BigInt!) {
  ocrFormat(id: $ocrFormatId) {
    width
    height
    documentTypeId
    OcrFormatItem {
      id
      ocrItemId
      xMin
      yMin
      xMax
      yMax
      excludedCharacters
      OcrItem {
        id
        name
      }
    }
  }
}
    `
export const useGetOcrFormatByIdQuery = <
  TData = GetOcrFormatByIdQuery,
  TError = unknown,
>(
  variables: GetOcrFormatByIdQueryVariables,
  options?: UseQueryOptions<GetOcrFormatByIdQuery, TError, TData>,
) =>
  useQuery<GetOcrFormatByIdQuery, TError, TData>(
    ['GetOcrFormatById', variables],
    authGraphqlApi<GetOcrFormatByIdQuery, GetOcrFormatByIdQueryVariables>(
      GetOcrFormatByIdDocument,
      variables,
    ),
    options,
  )
export const UpdateOcrResultItemDocument = `
    mutation UpdateOcrResultItem($tenantId: Int!, $id: BigInt!, $displayText: String!, $isEdited: Int!) {
  updateOcrResultItem(
    tenantId: $tenantId
    id: $id
    displayText: $displayText
    isEdited: $isEdited
  ) {
    id
  }
}
    `
export const useUpdateOcrResultItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateOcrResultItemMutation,
    TError,
    UpdateOcrResultItemMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateOcrResultItemMutation,
    TError,
    UpdateOcrResultItemMutationVariables,
    TContext
  >(
    ['UpdateOcrResultItem'],
    (variables?: UpdateOcrResultItemMutationVariables) =>
      authGraphqlApi<
        UpdateOcrResultItemMutation,
        UpdateOcrResultItemMutationVariables
      >(UpdateOcrResultItemDocument, variables)(),
    options,
  )
export const UpdateEditOcrResultItemDocument = `
    mutation UpdateEditOcrResultItem($tenantId: Int!, $id: BigInt!, $displayText: String!, $isEdited: Int!, $xMin: Int!, $xMax: Int!, $yMin: Int!, $yMax: Int!) {
  updateEditOcrResultItem(
    tenantId: $tenantId
    id: $id
    displayText: $displayText
    isEdited: $isEdited
    xMin: $xMin
    xMax: $xMax
    yMin: $yMin
    yMax: $yMax
  ) {
    id
  }
}
    `
export const useUpdateEditOcrResultItemMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateEditOcrResultItemMutation,
    TError,
    UpdateEditOcrResultItemMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateEditOcrResultItemMutation,
    TError,
    UpdateEditOcrResultItemMutationVariables,
    TContext
  >(
    ['UpdateEditOcrResultItem'],
    (variables?: UpdateEditOcrResultItemMutationVariables) =>
      authGraphqlApi<
        UpdateEditOcrResultItemMutation,
        UpdateEditOcrResultItemMutationVariables
      >(UpdateEditOcrResultItemDocument, variables)(),
    options,
  )
export const GetOcrResultPagesByOcrResultIdDocument = `
    query GetOcrResultPagesByOcrResultId($tenantId: Int!, $ocrResultId: BigInt!) {
  ocrResultPagesByOcrResultId(tenantId: $tenantId, ocrResultId: $ocrResultId) {
    id
    ocrResultId
    ocrFormatId
    documentTypeId
    status
    createdAt
    updatedAt
    pageNumber
    ocrFormat {
      fileName
      clientName
      width
      height
    }
    ocrResultItems {
      id
      ocrResultId
      ocrResultPageId
      ocrFormatItemId
      ocrItemId
      xMin
      yMin
      xMax
      yMax
      readText
      displayText
      isEdited
      createdAt
      updatedAt
      ocrItem {
        id
        name
        displayOrder
        createdAt
        updatedAt
        isDeleted
      }
    }
  }
}
    `
export const useGetOcrResultPagesByOcrResultIdQuery = <
  TData = GetOcrResultPagesByOcrResultIdQuery,
  TError = unknown,
>(
  variables: GetOcrResultPagesByOcrResultIdQueryVariables,
  options?: UseQueryOptions<GetOcrResultPagesByOcrResultIdQuery, TError, TData>,
) =>
  useQuery<GetOcrResultPagesByOcrResultIdQuery, TError, TData>(
    ['GetOcrResultPagesByOcrResultId', variables],
    authGraphqlApi<
      GetOcrResultPagesByOcrResultIdQuery,
      GetOcrResultPagesByOcrResultIdQueryVariables
    >(GetOcrResultPagesByOcrResultIdDocument, variables),
    options,
  )
export const GetOcrResultPageByOcrResultIdAndPageNumberDocument = `
    query GetOcrResultPageByOcrResultIdAndPageNumber($tenantId: Int!, $ocrResultId: BigInt!, $pageNumber: Int!) {
  ocrResultPagesByOcrResultIdAndPageNumber(
    tenantId: $tenantId
    ocrResultId: $ocrResultId
    pageNumber: $pageNumber
  ) {
    id
    ocrResultId
    status
  }
}
    `
export const useGetOcrResultPageByOcrResultIdAndPageNumberQuery = <
  TData = GetOcrResultPageByOcrResultIdAndPageNumberQuery,
  TError = unknown,
>(
  variables: GetOcrResultPageByOcrResultIdAndPageNumberQueryVariables,
  options?: UseQueryOptions<
    GetOcrResultPageByOcrResultIdAndPageNumberQuery,
    TError,
    TData
  >,
) =>
  useQuery<GetOcrResultPageByOcrResultIdAndPageNumberQuery, TError, TData>(
    ['GetOcrResultPageByOcrResultIdAndPageNumber', variables],
    authGraphqlApi<
      GetOcrResultPageByOcrResultIdAndPageNumberQuery,
      GetOcrResultPageByOcrResultIdAndPageNumberQueryVariables
    >(GetOcrResultPageByOcrResultIdAndPageNumberDocument, variables),
    options,
  )
export const UpdateEditOcrPageFormatDocument = `
    mutation UpdateEditOcrPageFormat($id: BigInt!, $ocrFormatId: BigInt!) {
  updateResultOcrFormatId(id: $id, ocrFormatId: $ocrFormatId) {
    id
  }
}
    `
export const useUpdateEditOcrPageFormatMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateEditOcrPageFormatMutation,
    TError,
    UpdateEditOcrPageFormatMutationVariables,
    TContext
  >,
) =>
  useMutation<
    UpdateEditOcrPageFormatMutation,
    TError,
    UpdateEditOcrPageFormatMutationVariables,
    TContext
  >(
    ['UpdateEditOcrPageFormat'],
    (variables?: UpdateEditOcrPageFormatMutationVariables) =>
      authGraphqlApi<
        UpdateEditOcrPageFormatMutation,
        UpdateEditOcrPageFormatMutationVariables
      >(UpdateEditOcrPageFormatDocument, variables)(),
    options,
  )
export const GetTenantUserByUserIdDocument = `
    query GetTenantUserByUserId {
  tenantUserByUserId {
    pwExpire
  }
}
    `
export const useGetTenantUserByUserIdQuery = <
  TData = GetTenantUserByUserIdQuery,
  TError = unknown,
>(
  variables?: GetTenantUserByUserIdQueryVariables,
  options?: UseQueryOptions<GetTenantUserByUserIdQuery, TError, TData>,
) =>
  useQuery<GetTenantUserByUserIdQuery, TError, TData>(
    variables === undefined
      ? ['GetTenantUserByUserId']
      : ['GetTenantUserByUserId', variables],
    authGraphqlApi<
      GetTenantUserByUserIdQuery,
      GetTenantUserByUserIdQueryVariables
    >(GetTenantUserByUserIdDocument, variables),
    options,
  )
export const GetAllTenantDocument = `
    query GetAllTenant {
  allTenant {
    id
    name
  }
}
    `
export const useGetAllTenantQuery = <
  TData = GetAllTenantQuery,
  TError = unknown,
>(
  variables?: GetAllTenantQueryVariables,
  options?: UseQueryOptions<GetAllTenantQuery, TError, TData>,
) =>
  useQuery<GetAllTenantQuery, TError, TData>(
    variables === undefined ? ['GetAllTenant'] : ['GetAllTenant', variables],
    authGraphqlApi<GetAllTenantQuery, GetAllTenantQueryVariables>(
      GetAllTenantDocument,
      variables,
    ),
    options,
  )
export const GetTenantByPageDocument = `
    query GetTenantByPage($page: Int!) {
  tenantCount
  tenantByPage(page: $page) {
    id
    name
  }
}
    `
export const useGetTenantByPageQuery = <
  TData = GetTenantByPageQuery,
  TError = unknown,
>(
  variables: GetTenantByPageQueryVariables,
  options?: UseQueryOptions<GetTenantByPageQuery, TError, TData>,
) =>
  useQuery<GetTenantByPageQuery, TError, TData>(
    ['GetTenantByPage', variables],
    authGraphqlApi<GetTenantByPageQuery, GetTenantByPageQueryVariables>(
      GetTenantByPageDocument,
      variables,
    ),
    options,
  )
export const GetTenantDocument = `
    query GetTenant {
  tenant {
    id
    name
    isCheckPwExpire
  }
}
    `
export const useGetTenantQuery = <TData = GetTenantQuery, TError = unknown>(
  variables?: GetTenantQueryVariables,
  options?: UseQueryOptions<GetTenantQuery, TError, TData>,
) =>
  useQuery<GetTenantQuery, TError, TData>(
    variables === undefined ? ['GetTenant'] : ['GetTenant', variables],
    authGraphqlApi<GetTenantQuery, GetTenantQueryVariables>(
      GetTenantDocument,
      variables,
    ),
    options,
  )
