import { useRef, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { calcImageSize } from 'src/pdfTools/calcPointImageSize'
import {
  ocrPdfCanvas,
  bboxListState,
  pointImgCanvasSizeState,
  pdfImageSizeState,
} from 'src/state'

type OcrPointImageViewerProp = {
  id: number
  pageNumber: number
}

function OcrPointImageViewer({ id, pageNumber }: OcrPointImageViewerProp) {
  const maxCanvasSizeState = useRecoilValue(pointImgCanvasSizeState)
  const maxWidth = maxCanvasSizeState.width
  const maxHeight = maxCanvasSizeState.height

  const bboxState = useRecoilValue(bboxListState)
  const pointImgCanvasRef = useRef<HTMLCanvasElement>(null)

  // pdf canvas関連情報を取得
  const ocrPdfCanvasState = useRecoilValue(ocrPdfCanvas)
  const pdfCanvasSize = useRecoilValue(pdfImageSizeState)

  const getTargetItem = (id: number) => {
    for (const item of bboxState[pageNumber]) {
      if (item.id == id) {
        return item
      }
    }
  }

  useEffect(() => {
    // canvas
    const pointImgCanvas = pointImgCanvasRef.current
    const pointImagContext = pointImgCanvas?.getContext('2d')

    // canvasサイズの初期化
    if (pointImgCanvas) {
      pointImgCanvas.width = 0
      pointImgCanvas.height = 0
    }

    const targetBBox = getTargetItem(id)

    if (targetBBox && maxWidth != 0 && maxHeight != 0) {
      // 座標から画像サイズを計算
      const rawImageWidth = targetBBox.xmax - targetBBox.xmin
      const rawImageHeight = targetBBox.ymax - targetBBox.ymin

      // 画像サイズを計算
      const imageSize = calcImageSize(
        rawImageWidth,
        rawImageHeight,
        maxWidth,
        maxHeight
      )

      // 画像サイズをcanvasサイズとしてセット
      if (pointImgCanvas) {
        pointImgCanvas.width = imageSize.width
        pointImgCanvas.height = imageSize.height
      }

      // pdfを表示しているcanvasから画像を切り出して表示
      if (ocrPdfCanvasState) {
        const canvas2pdfSizeRatio = {
          width: ocrPdfCanvasState.width / pdfCanvasSize.width,
          height: ocrPdfCanvasState.height / pdfCanvasSize.height,
        }

        const imageWidth = rawImageWidth * canvas2pdfSizeRatio.width
        const imageHeight = rawImageHeight * canvas2pdfSizeRatio.height

        // 画像サイズが0以上かチェック
        if (
          imageWidth > 0 &&
          imageHeight > 0 &&
          imageSize.width > 0 &&
          imageSize.height > 0
        ) {
          pointImagContext?.drawImage(
            ocrPdfCanvasState,
            targetBBox.xmin * canvas2pdfSizeRatio.width,
            targetBBox.ymin * canvas2pdfSizeRatio.height,
            imageWidth,
            imageHeight,
            0,
            0,
            imageSize.width,
            imageSize.height
          )
        }
      }
    }
  }, [ocrPdfCanvasState, maxWidth, maxHeight, bboxState[pageNumber]])

  return <canvas ref={pointImgCanvasRef} />
}

export default OcrPointImageViewer
