import { authFetchApi } from 'src/values'

type GetDocumentPdfUrlProps = {
  tenantId: number
  documentId: number
  fileName: string
}

export const getDocumentPdfUrl = async (
  data: GetDocumentPdfUrlProps,
): Promise<{ presignedUrl: string }> => {
  const params = new URLSearchParams({
    tenantId: data.tenantId.toString(),
    documentId: data.documentId.toString(),
    fileName: data.fileName,
  })

  return await authFetchApi('document-pdf-url?' + params, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
