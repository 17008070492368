import { Input } from '@chakra-ui/react'
import { LegacyRef } from 'react'
import { format } from 'date-fns'

const CreatedAtLteInput = (
  {
    createdAtLte,
  }: {
    createdAtLte: number | undefined
  },
  ref: LegacyRef<HTMLInputElement>
) => {
  return (
    <>
      <Input
        type="date"
        defaultValue={
          createdAtLte
            ? format(new Date(createdAtLte), 'yyyy-MM-dd')
            : undefined
        }
        ref={ref}
      />
    </>
  )
}
export default CreatedAtLteInput
