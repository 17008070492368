import { RefObject } from 'react'
import { HStack, MenuItem, Text, VStack } from '@chakra-ui/react'

type ocrFormatItemType = {
  id: number | undefined
  selectedId: number
  clientName: string | null | undefined
  fileName: string | null | undefined
  onClick: () => void
  selectedRef: RefObject<HTMLButtonElement>
}

type setFunc = {
  onClick: () => void
}

export const OcrFormatNotSelectItem = ({ onClick }: setFunc) => {
  return (
    <MenuItem onClick={onClick}>
      <Text>帳票パターンを選択しない</Text>
    </MenuItem>
  )
}

export const OcrFormatItem = ({
  id,
  selectedId,
  clientName,
  fileName,
  onClick,
  selectedRef
}: ocrFormatItemType) => {
  const ref = id == selectedId? selectedRef : null
  return (
    <MenuItem onClick={onClick} ref={ref}>
      <HStack>
        <Text>{id}</Text>
        <VStack>
          <Text>{clientName}</Text>
          <Text>{fileName}</Text>
        </VStack>
      </HStack>
    </MenuItem>
  )
}
