import { Bbox, Point2D } from 'src/ocrParser/ocrType'
import { boundingBoxType } from 'src/state'
import {
  defaultOcrItemColor,
  selectedOcrItemColor,
  mulchEditBboxColor,
  selectedMulchEditColor,
} from 'src/values/ocrItemColor'

const drawRectangle = (
  ctx: CanvasRenderingContext2D,
  strokeStyle: string,
  lineWidth: number,
  x: number,
  y: number,
  width: number,
  height: number,
) => {
  ctx.beginPath()
  ctx.strokeStyle = strokeStyle
  ctx.lineWidth = lineWidth
  ctx.strokeRect(x, y, width, height)
  ctx.stroke()
}

const drawFillRectangle = (
  ctx: CanvasRenderingContext2D,
  color: string,
  center: Point2D,
  lenght = 4,
) => {
  const halfLength = Math.round(lenght / 2)
  const x = center.x - halfLength
  const y = center.y - halfLength

  ctx.beginPath()

  // 白枠の描画
  ctx.fillStyle = 'White'
  ctx.fillRect(x, y, lenght + 1, lenght + 1)

  // 編集点の描画
  ctx.fillStyle = color
  ctx.fillRect(x, y, lenght, lenght)

  ctx.stroke()
}

export const drawOcrItemRectangle = (
  ctx: CanvasRenderingContext2D,
  boudingBox: boundingBoxType[],
  currentOcrItemId: number,
  isMulchEdit: boolean
) => {
  boudingBox.forEach((bbox) => {
    let color = defaultOcrItemColor
    let lineWidth = 1.0

    if (bbox.isMulchSelected && isMulchEdit) {
      color = selectedMulchEditColor
      lineWidth = 2.0
    } else {
      color =
        bbox.id == currentOcrItemId ? selectedOcrItemColor : defaultOcrItemColor
      lineWidth = bbox.id == currentOcrItemId ? 2.0 : 1.0
    }

    drawRectangle(
      ctx,
      color,
      lineWidth,
      bbox.xmin,
      bbox.ymin,
      bbox.xmax - bbox.xmin,
      bbox.ymax - bbox.ymin,
    )
  })
}

export const drawEditRectangle = (
  ctx: CanvasRenderingContext2D,
  edictBbox: Bbox | null,
  ocrItemBbox: boundingBoxType[],
  imageSize: { width: number; height: number },
  isMulchEdit: boolean,
  currentOcrItemId: number,
) => {
  ctx.clearRect(0, 0, imageSize.width, imageSize.height)

  drawOcrItemRectangle(ctx, ocrItemBbox, currentOcrItemId, isMulchEdit)

  if (!isMulchEdit || !edictBbox) return

  // drawing edit bbox
  drawRectangle(
    ctx,
    mulchEditBboxColor,
    1.0,
    edictBbox.xmin,
    edictBbox.ymin,
    edictBbox.xmax - edictBbox.xmin,
    edictBbox.ymax - edictBbox.ymin,
  )

  //drawing edit points
  const editPoints = edictBbox.editPoints()
  Object.entries(editPoints).forEach(([key, values]) => {
    if (key != 'center') {
      drawFillRectangle(ctx, mulchEditBboxColor, values)
    }
  })
}
