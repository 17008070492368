import { Checkbox } from '@chakra-ui/react'
import { notIsDownloadedCsvCheckboxState } from 'src/state'
import { useRecoilState } from 'recoil'

const NotIsDownloadedCsvCheckbox = () => {
  const [isChecked, setChecked] = useRecoilState(
    notIsDownloadedCsvCheckboxState
  )

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setChecked(e.target.checked)}
    >
      未
    </Checkbox>
  )
}

export default NotIsDownloadedCsvCheckbox
