import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from '@chakra-ui/react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { OcrParse } from 'src/ocrParser/ocrParser'
import {
  selectOcrFormatState,
  bboxListState,
  boundingBoxType,
  isRetryPatternState,
  oldOcrResultItemIdState,
  pdfImageSizeState,
  currentPageNumState,
  editStateInCurrentPage,
  ocrResultPagesState,
  currentOcrFormatIdState,
} from 'src/state'

import { useState } from 'react'
import {
  GetOcrFormatByIdQuery,
  useGetOcrFormatByIdQuery,
} from 'src/graphql/generated'
import { excludeCharacters } from 'src/values/excludedCharacters'

type retryPatternMatchProp = {
  ocrResultParser: OcrParse | null | undefined
}

type ocrFormatType = GetOcrFormatByIdQuery['ocrFormat']

export const RetryOcrPatternMatchingButton = ({
  ocrResultParser,
}: retryPatternMatchProp) => {
  const [isClick, setIsClick] = useState(false)
  const setIsRetryPattern = useSetRecoilState(isRetryPatternState)
  const ocrFormatInfo = useRecoilValue(selectOcrFormatState)
  const pdfImageSize = useRecoilValue(pdfImageSizeState)
  const setOldOcrResultItemState = useSetRecoilState(oldOcrResultItemIdState)
  const [boundingBoxState, setBBoxState] = useRecoilState(bboxListState)
  const currentPageNumber = useRecoilValue(currentPageNumState)
  const [ocrResultPages, setOcrResultPages] = useRecoilState(ocrResultPagesState)
  const setCurrentOcrFormatIdState = useSetRecoilState(currentOcrFormatIdState)

  // 編集済みフラグの設定
  const markEdited = useSetRecoilState(editStateInCurrentPage)

  const { refetch } = useGetOcrFormatByIdQuery(
    {
      ocrFormatId: ocrFormatInfo[currentPageNumber]?.id,
    },
    {
      enabled: !!ocrFormatInfo[currentPageNumber] && isClick,
    },
  )

  const updateText = (ocrFormatData: ocrFormatType) => {
    if (ocrResultParser && ocrFormatData) {
      const bboxList: boundingBoxType[] = []

      const ocrFormatWidth = ocrFormatData.width ? ocrFormatData.width : 0
      const ocrFormatHeight = ocrFormatData.height ? ocrFormatData.height : 0

      const ocrFormatToPdfImageSize = {
        width: pdfImageSize.width / ocrFormatWidth,
        height: pdfImageSize.height / ocrFormatHeight,
      }

      let counter = 1
      ocrFormatData.OcrFormatItem?.forEach((item) => {
        const ocrFormatItemId = item?.id ? item?.id : 0
        const xmin = item?.xMin ? item?.xMin : 0
        const xmax = item?.xMax ? item?.xMax : 0
        const ymin = item?.yMin ? item?.yMin : 0
        const ymax = item?.yMax ? item?.yMax : 0
        const ocrItemId = item?.OcrItem?.id ? item?.OcrItem?.id : 1

        const tmpBBoxInfo: boundingBoxType = {
          id: counter,
          text: '',
          ocrFormatItemId: ocrFormatItemId,
          ocrItemId: ocrItemId,
          ocrItemName: item?.OcrItem?.name,
          xmin: xmin * ocrFormatToPdfImageSize.width,
          xmax: xmax * ocrFormatToPdfImageSize.width,
          ymin: ymin * ocrFormatToPdfImageSize.height,
          ymax: ymax * ocrFormatToPdfImageSize.height,
          rawXmin: xmin,
          rawXmax: xmax,
          rawYmin: ymin,
          rawYmax: ymax,
          isEdit: false,
          isMulchSelected: false,
        }

        const parsedText = ocrResultParser.bbox2text(tmpBBoxInfo, pdfImageSize)

        // パターンマッチング再実行時は除外文字削除処理をフロントでやる
        const newText = item?.excludedCharacters
          ? excludeCharacters(parsedText, item?.excludedCharacters)
          : parsedText

        bboxList.push({
          ...tmpBBoxInfo,
          text: newText,
        })

        counter = counter + 1
      })

      const oldOcrResultItemIds: number[] = []
      boundingBoxState[currentPageNumber].forEach((item) => {
        oldOcrResultItemIds.push(item.id)
      })

      setOldOcrResultItemState(oldOcrResultItemIds)
      setBBoxState({
        ...boundingBoxState,
        [currentPageNumber]: bboxList,
      })
      setIsRetryPattern(true)
      markEdited(true)
    }
  }

  const clickHnadler = async () => {
    setIsClick(true)
    const { data } = await refetch()

    if (data?.ocrFormat) {
      updateText(data?.ocrFormat)
    }

    setCurrentOcrFormatIdState(ocrFormatInfo[currentPageNumber]?.id)
    setIsClick(false)

    const currentOcrPage = ocrResultPages?.[currentPageNumber]

    if (currentOcrPage && data?.ocrFormat?.documentTypeId) {
      const newCurrentOcrPage = {
        ...currentOcrPage,
        documentTypeId: data?.ocrFormat?.documentTypeId
      }

      setOcrResultPages({
        ...ocrResultPages,
        [currentPageNumber]: newCurrentOcrPage
      })
    }
  }

  return (
    <>
      {ocrResultPages[currentPageNumber] ? (
        <Button onClick={async () => await clickHnadler()} isLoading={isClick}>
          パターンマッチング再実行
        </Button>
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>このページはOCR解析に失敗しています。</AlertTitle>
          <AlertDescription>OCR再実行を行ってください</AlertDescription>
        </Alert>
      )}
    </>
  )
}
