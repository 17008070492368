import {
  Popover,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Box,
  Button,
  FormControl,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import FormInputGroup from './SearchPopover/FormInputGroup'
import FormButtonGroup from './SearchPopover/FormButtonGroup'
import {
  deliveryCheckboxState,
  invoiceCheckboxState,
  analyzingCheckboxState,
  analyzedCheckboxState,
  analyzeErrorCheckboxState,
  isDownloadedCsvCheckboxState,
  notIsDownloadedCsvCheckboxState,
  orderCheckboxState,
  isCheckedCheckboxState,
  notIsCheckedCheckboxState,
} from 'src/state'
import { useSetRecoilState } from 'recoil'
import { DocumentOcrStatus, DocumentTypeId, IsDownloadCsv } from 'src/values'
import { IsChecked } from 'src/values/isChecked'

type SearchPopoverProps = {
  from?: number
  clientName?: string
  documentTypeIdList?: number[]
  createdAtGte?: number
  createdAtLte?: number
  statusList?: number[]
  isDownloadedCsvList?: number[]
  isCheckedList?: number[]
}

const SearchPopover = ({
  from,
  clientName,
  createdAtGte,
  createdAtLte,
  documentTypeIdList = [],
  statusList = [],
  isDownloadedCsvList = [],
  isCheckedList = [],
}: SearchPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const clientNameInputRef = useRef<HTMLInputElement>(null)
  const createdAtGteInputRef = useRef<HTMLInputElement>(null)
  const createdAtLteInputRef = useRef<HTMLInputElement>(null)

  const setDeliveryCheckbox = useSetRecoilState(deliveryCheckboxState)
  const setInvoiceCheckbox = useSetRecoilState(invoiceCheckboxState)
  const setOrderCheckbox = useSetRecoilState(orderCheckboxState)
  const setAnalyzingCheckbox = useSetRecoilState(analyzingCheckboxState)
  const setAnalyzedCheckbox = useSetRecoilState(analyzedCheckboxState)
  const setAnalyzeErrorCheckbox = useSetRecoilState(analyzeErrorCheckboxState)
  const setIsDownloadedCheckbox = useSetRecoilState(
    isDownloadedCsvCheckboxState,
  )
  const setNotIsDownloadedCheckbox = useSetRecoilState(
    notIsDownloadedCsvCheckboxState,
  )
  const setIsCheckedFlag = useSetRecoilState(isCheckedCheckboxState)
  const setNotIsCheckedFlag = useSetRecoilState(notIsCheckedCheckboxState)

  from = from ?? 1
  useEffect(() => {
    setDeliveryCheckbox(
      (() => {
        if (!documentTypeIdList) return false
        return (
          documentTypeIdList &&
          documentTypeIdList.indexOf(DocumentTypeId.DELIVERY) !== -1
        )
      })(),
    )
    setInvoiceCheckbox(
      (() => {
        if (!documentTypeIdList) return false
        return (
          documentTypeIdList &&
          documentTypeIdList.indexOf(DocumentTypeId.INVOICE) !== -1
        )
      })(),
    )
    setOrderCheckbox(
      (() => {
        if (!documentTypeIdList) return false
        return (
          documentTypeIdList &&
          documentTypeIdList.indexOf(DocumentTypeId.ORDER) !== -1
        )
      })(),
    )
  }, [documentTypeIdList])
  useEffect(() => {
    setAnalyzingCheckbox(
      (() => {
        if (!statusList) return false
        for (const status of DocumentOcrStatus.ANALYZING) {
          if (statusList.indexOf(status) === -1) return false
        }
        return true
      })(),
    )
    setAnalyzedCheckbox(
      (() => {
        if (!statusList) return false
        return statusList.indexOf(DocumentOcrStatus.ANALYZED) !== -1
      })(),
    )
    setAnalyzeErrorCheckbox(
      (() => {
        if (!statusList) return false
        for (const status of DocumentOcrStatus.ANALYZE_ERROR) {
          if (statusList.indexOf(status) === -1) return false
        }
        return true
      })(),
    )
  }, [statusList])
  useEffect(() => {
    setIsDownloadedCheckbox(
      (() => {
        if (!isDownloadedCsvList) return false
        return (
          isDownloadedCsvList &&
          isDownloadedCsvList.indexOf(IsDownloadCsv.YES) !== -1
        )
      })(),
    )
    setNotIsDownloadedCheckbox(
      (() => {
        if (!isDownloadedCsvList) return false
        return (
          isDownloadedCsvList &&
          isDownloadedCsvList.indexOf(IsDownloadCsv.NO) !== -1
        )
      })(),
    )
  }, [isDownloadedCsvList])
  useEffect(() => {
    setIsCheckedFlag(
      (() => {
        if (!isCheckedList) return false
        return isCheckedList && isCheckedList.indexOf(IsChecked.YES) !== -1
      })(),
    )
    setNotIsCheckedFlag(
      (() => {
        if (!isCheckedList) return false
        return isCheckedList && isCheckedList.indexOf(IsChecked.NO) !== -1
      })(),
    )
  }, [isCheckedList])

  return (
    <>
      <Popover onClose={onClose} isOpen={isOpen} onOpen={onOpen}>
        <PopoverTrigger>
          <Box>
            <Button
              size="sm"
              colorScheme="blue"
              variant="outline"
              leftIcon={<FiSearch />}
            >
              検索
            </Button>
          </Box>
        </PopoverTrigger>
        <PopoverContent p={5} w="480px" mr={4}>
          <PopoverArrow />
          <PopoverCloseButton />
          <FormControl>
            <FormInputGroup
              clientName={clientName}
              createdAtGte={createdAtGte}
              createdAtLte={createdAtLte}
              clientNameInputRef={clientNameInputRef}
              createdAtGteInputRef={createdAtGteInputRef}
              createdAtLteInputRef={createdAtLteInputRef}
            />
            <FormButtonGroup
              onClose={onClose}
              from={from}
              clientNameInputRef={clientNameInputRef}
              createdAtGteInputRef={createdAtGteInputRef}
              createdAtLteInputRef={createdAtLteInputRef}
            />
          </FormControl>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default SearchPopover
