import { boundingBoxType } from 'src/state'
import { AzureOcrResult } from './azureOcrType'
import { Bbox, Size2D } from './ocrType'

export type textWithBbox = {
  text: string
  bbox: Bbox
}

export class OcrParse {
  lines: textWithBbox[]
  words: textWithBbox[]
  ocrSize: Size2D

  constructor(data: AzureOcrResult) {
    this.lines = []
    this.words = []
    this.ocrSize = { width: 0, height: 0 }

    const readResult = data.analyze_result?.read_results

    if (readResult) {
      this.ocrSize = {
        width: readResult[0].width,
        height: readResult[0].height,
      }

      readResult.forEach((itme) => {
        itme.lines.forEach((line) => {
          this.lines.push({
            text: line.text,
            bbox: Bbox.fromAzure(line.bounding_box),
          })
          line.words.forEach((word) => {
            this.words.push({
              text: word.text,
              bbox: Bbox.fromAzure(word.bounding_box),
            })
          })
        })
      })
    }
  }

  bbox2text(bbox: boundingBoxType, displayImageSize: Size2D): string {
    const includeText: string[] = []

    const convertRatio = {
      width: this.ocrSize.width / displayImageSize.width,
      height: this.ocrSize.height / displayImageSize.height,
    }

    const convertedBbox = new Bbox(
      bbox.xmin * convertRatio.width,
      bbox.xmax * convertRatio.width,
      bbox.ymin * convertRatio.height,
      bbox.ymax * convertRatio.height
    )

    this.words.forEach((word) => {
      if (convertedBbox.is_includ(word.bbox)) {
        includeText.push(word.text)
      }
    })

    if (includeText.length == 0) {
      return ''
    }

    return includeText.join('')
  }
}
