export const getDocumentOcrStatusLabel = (documentOcrStatus: number) => {
  switch (documentOcrStatus) {
    case 0: // 未解析でも解析中と表示する仕様
    case 10:
      return '解析中'
    case 1: // TODO: ダミーデータ用のため後で消す
    case 20:
      return '解析済'
    case 50:
    case 51:
    case 52:
      return '解析エラー'
    case 53:
      return '容量超過エラー'
    default:
      throw new Error('the document ocr status id is invalid')
  }
}

export const didAnalyze = (documentOcrStatus: number) => {
  return documentOcrStatus === DocumentOcrStatus.ANALYZED
}

export const isAnalyzing = (documentOcrStatus: number) => {
  return DocumentOcrStatus.ANALYZING.includes(documentOcrStatus)
}

export const DocumentOcrStatus = {
  ANALYZING: [0, 10] as ReadonlyArray<number>,
  ANALYZED: 20,
  ANALYZE_ERROR: [50, 51, 52] as ReadonlyArray<number>,
} as const
