import { Box } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { OcrParse } from 'src/ocrParser/ocrParser'
import {
  bboxListState,
  currentPageNumState,
  boundingBoxType,
  currentChoiceOcrItemId,
  editStateInCurrentPage,
  canvasRatioState,
  isEditMulchBboxState,
  mulchEditBboxRatioState,
  mulchEditBboxState,
  currentOcrFormatIdState,
} from 'src/state'
import { Bbox } from 'src/ocrParser/ocrType'
import { drawEditRectangle } from 'src/drawingCanvasTools/drawRectangle'
import {
  handleMouseDownProcess,
  handleMouseMoveProcess,
  handleMouseUpProcess,
} from 'src/drawingCanvasTools/canvasHandles'
import { mulchEditModeType } from 'src/drawingCanvasTools/calcMulchEditBboxEditMode'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export type DrawingCanvasType = {
  imageSize: { width: number; height: number }
  ocrResultParser: OcrParse | null | undefined
}

export const DrawingPage = ({
  imageSize,
  ocrResultParser,
}: DrawingCanvasType) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [boundingBoxState, setBBoxState] = useRecoilState(bboxListState)
  const pageNumber = useRecoilValue(currentPageNumState)
  const [drawing, setDrawing] = useState(false)
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null)
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 })
  const currentOcrFormatId = useRecoilValue(currentOcrFormatIdState)

  // for mulch edit bbox values
  const [mulchEditMode, setmulchEditMode] = useState<mulchEditModeType | null>(
    null,
  )
  const [mulchEditBbox, setMuluchEditBbox] = useRecoilState(mulchEditBboxState)
  const setMuluchEditRatioBbox = useSetRecoilState(mulchEditBboxRatioState)
  const isEditMulchBboxMode = useRecoilValue(isEditMulchBboxState)

  // 座標描画＆選択ocrItem情報
  const currentOcrItemId = useRecoilValue(currentChoiceOcrItemId)
  const canvasPointRatio = useRecoilValue(canvasRatioState)

  // 編集済みフラグの設定
  const markEdited = useSetRecoilState(editStateInCurrentPage)

  // init canvas
  useEffect(() => {
    if (canvasRef.current) {
      const renderCtx = canvasRef.current.getContext('2d')
      if (renderCtx) {
        setContext(renderCtx)
      }
    }
  }, [canvasRef.current])

  // change bounding boxes
  useEffect(() => {
    if (pageNumber in boundingBoxState == false) {
      return
    }

    // 複数編集モードがoffになった時
    if (!isEditMulchBboxMode) {
      setMuluchEditBbox(null)

      // 各ocrItemBboxの選択フラグをfalseにする
      setBBoxState({
        ...boundingBoxState,
        [pageNumber]: boundingBoxState[pageNumber].map((value) => {
          if (value.isMulchSelected) {
            return {
              ...value,
              isMulchSelected: false,
            }
          }
          return value
        }),
      })
    }

    if (context) {
      drawEditRectangle(
        context,
        mulchEditBbox,
        boundingBoxState[pageNumber],
        imageSize,
        isEditMulchBboxMode,
        currentOcrItemId,
      )
    }
  }, [pageNumber, imageSize, currentOcrItemId, isEditMulchBboxMode, currentOcrFormatId])

  // クリックした時の挙動
  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setDrawing(true)
    if (!canvasRef.current) return

    const rect = canvasRef.current.getBoundingClientRect()

    const info = handleMouseDownProcess(
      event,
      rect,
      isEditMulchBboxMode,
      mulchEditBbox,
    )

    if (info.editMode) {
      setmulchEditMode(info.editMode)
    }

    setStartPoint(info.clickPosition)
  }

  // クリックを終了した時の挙動
  const handleMouseUp = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!drawing || !context || !canvasRef.current) return

    const rect = canvasRef.current.getBoundingClientRect()

    const newBboxs = handleMouseUpProcess(
      event,
      rect,
      isEditMulchBboxMode,
      boundingBoxState[pageNumber],
      mulchEditBbox,
      startPoint,
      mulchEditMode,
      currentOcrItemId,
      canvasPointRatio,
    )

    setMuluchEditBbox(newBboxs.editBbox)

    drawEditRectangle(
      context,
      newBboxs.editBbox,
      newBboxs.ocrItemBboxs,
      imageSize,
      isEditMulchBboxMode,
      currentOcrItemId,
    )

    if (newBboxs.editBbox) {
      setMuluchEditRatioBbox(
        new Bbox(
          Math.round(newBboxs.editBbox.xmin / canvasPointRatio.width),
          Math.round(newBboxs.editBbox.xmax / canvasPointRatio.width),
          Math.round(newBboxs.editBbox.ymin / canvasPointRatio.height),
          Math.round(newBboxs.editBbox.ymax / canvasPointRatio.height),
        ),
      )
    }

    setDrawing(false)
    updateText(newBboxs.ocrItemBboxs)
  }

  const updateText = (bbox: boundingBoxType[]) => {
    if (ocrResultParser) {
      const bboxList: boundingBoxType[] = []
      bbox.forEach((item) => {
        if (item.id == currentOcrItemId || item.isMulchSelected) {
          const newText = ocrResultParser.bbox2text(item, imageSize)
          bboxList.push({
            ...item,
            text: newText,
            isEdit: true,
          })
        } else {
          bboxList.push(item)
        }
      })

      setBBoxState({
        ...boundingBoxState,
        [pageNumber]: bboxList,
      })
      markEdited(true)
    }
  }

  // クリックしながら動いている時の処理
  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!drawing || !context || !canvasRef.current) return
    const rect = canvasRef.current.getBoundingClientRect()

    const newBboxs = handleMouseMoveProcess(
      event,
      rect,
      isEditMulchBboxMode,
      boundingBoxState[pageNumber],
      mulchEditBbox,
      startPoint,
      mulchEditMode,
      currentOcrItemId,
      canvasPointRatio,
    )

    drawEditRectangle(
      context,
      newBboxs.editBbox,
      newBboxs.ocrItemBboxs,
      imageSize,
      isEditMulchBboxMode,
      currentOcrItemId,
    )
  }

  return (
    <Box position={'absolute'} zIndex={10}>
      <canvas
        data-private
        ref={canvasRef}
        width={imageSize.width}
        height={imageSize.height}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />
    </Box>
  )
}
