import { useState } from 'react'
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  Box,
  Avatar,
  FormControl,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'
import {
  getUserProfile,
  postAuthLogin,
  userAuthForm,
  userAuthResponse,
  userProfileResponse,
} from 'src/api/auth'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError } from 'src/api/apiError'
import { removeBpoTenantId } from 'src/values/bpoTenantId'
import { userProfileQueryKey } from 'src/values'

function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()

  const login = useMutation<
    userAuthResponse & userProfileResponse,
    Response,
    userAuthForm,
    unknown
  >(postAuthLogin)

  const { isLoading } = useQuery({
    queryKey: [userProfileQueryKey],
    queryFn: async () => await getUserProfile(),
    onSuccess: async () => {
      navigate('/documents')
    },
    refetchOnWindowFocus: false,
  })

  const handleSubmitClick = () => {
    login.mutate(
      { username, password },
      {
        onSuccess: (data) => {
          // ログアウトセずにユーザー切り替えする場合もあるためここでリセットする
          removeBpoTenantId()
          queryClient.clear()

          window.scrollTo(0, 0)
          if (data.tenantId.toString() === process.env.REACT_APP_TOMORAKU_ID) {
            navigate('/bpo/tenant/list')
          } else {
            navigate('/documents')
          }
        },
        onError: async (error) => {
          let description = 'サーバーエラーです。時間をおいてお試しください。'
          if (error instanceof ApiError) {
            if (
              (error.statusCode === 401 &&
                error.errorMessage === 'Incorrect username or password.') ||
              error.errorMessage === 'user not found'
            ) {
              description = 'ユーザーネームまたはパスワードが正しくありません。'
            }
            if (
              error.statusCode === 403 &&
              error.errorMessage === 'Request from unauthorized IP'
            ) {
              description = '許可されていないIPからアクセスしています。'
            }
          }
          toast({
            description,
            status: 'error',
            isClosable: true,
          })
        },
      },
    )
  }

  const handleShowClick = () => setShowPassword(!showPassword)

  return (
    !isLoading && (
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar bg="#2F6B9F" />
          <Heading color="#2F6B9F">TOMORAKU</Heading>
          <Box minW={{ base: '90%', md: '468px' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <FormControl>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      data-private
                      type="username"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300" />
                    <Input
                      data-private
                      type={showPassword ? 'text' : 'password'}
                      placeholder="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  backgroundColor="#2F6B9F"
                  color="white"
                  width="full"
                  onClick={handleSubmitClick}
                >
                  Login
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    )
  )
}

export default Login
