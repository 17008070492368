import { Bbox, Point2D, Size2D } from 'src/ocrParser/ocrType'
import {
  calcUpdateMulchEditBbox,
  createBbox,
} from 'src/drawingCanvasTools/bboxCanvasTools'
import { getEditMode, mulchEditModeType } from './calcMulchEditBboxEditMode'
import { boundingBoxType } from 'src/state'
import { updateOcrItemBbox, newOcrItemBboxArray } from './ocrItemBboxTools'

export type clickPointEnum =
  | 'topLeft'
  | 'topRight'
  | 'topCenter'
  | 'leftCenter'
  | 'rightCenter'
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottomCenter'

const calcClickPoint = (
  event: React.MouseEvent<HTMLCanvasElement>,
  rect: DOMRect,
) => {
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  }
}

/**
 * マウスクリック時に座標の編集モードを決める関数
 * 編集モードは以下のとおり
 *   - ocrItemの座標変更
 *   - 複数座標編集ボックスの座標変更
 *   - 複数座標編集ボックスの拡大縮小
 *   - 複数座標編集ボックスの縦横拡大縮小
 *   - 複数座標編集ボックスの並行移動
 * @param event
 * @param rect
 * @param isEditMulchBboxMode
 * @param mulchEditBbox
 * @returns
 */
export const handleMouseDownProcess = (
  event: React.MouseEvent<HTMLCanvasElement>,
  rect: DOMRect,
  isEditMulchBboxMode: boolean,
  mulchEditBbox: Bbox | null,
): { clickPosition: Point2D; editMode: mulchEditModeType | null } => {
  const clickPosition = calcClickPoint(event, rect)

  let editMode = null
  if (isEditMulchBboxMode) {
    //すでにbboxを持っている場合
    if (mulchEditBbox) {
      // clickされた位置を特定
      editMode = getEditMode(mulchEditBbox, clickPosition)
    }
  }

  return {
    clickPosition: clickPosition,
    editMode: editMode,
  }
}

/**
 * 新しいbboxを作成する関数
 * @param event
 * @param rect
 * @param isEditMulchBboxMode
 * @param ocrItemBboxs
 * @param mulchEditBbox
 * @param startPosition
 * @param mulchEditMode
 * @param currentOcrItemId
 * @param canvasPointRatio
 * @returns
 */
export const handleMouseMoveProcess = (
  event: React.MouseEvent<HTMLCanvasElement>,
  rect: DOMRect,
  isEditMulchBboxMode: boolean,
  ocrItemBboxs: boundingBoxType[],
  mulchEditBbox: Bbox | null,
  startPosition: Point2D,
  mulchEditMode: mulchEditModeType | null,
  currentOcrItemId: number,
  canvasPointRatio: Size2D,
) => {
  const clickPosition = calcClickPoint(event, rect)

  const newBboxs = {
    editBbox: mulchEditBbox,
    ocrItemBboxs: ocrItemBboxs,
  }

  // 複数座標編集モードの場合
  if (isEditMulchBboxMode) {
    // 複数座標編集ボックスがまだない場合
    if (!mulchEditBbox || !mulchEditMode) {
      newBboxs.editBbox = createBbox(startPosition, clickPosition)
    }
    // クリック位置に基づく編集モード情報を持っている場合
    else {
      //新しい複数座標編集ボックスを作成
      newBboxs.editBbox = calcUpdateMulchEditBbox(
        mulchEditBbox,
        startPosition,
        clickPosition,
        mulchEditMode,
      )
    }

    // 複数座標編集ボックス内にocrItemBboxが含まれているか判定
    newBboxs.ocrItemBboxs = updateOcrItemBbox(
      mulchEditBbox,
      newBboxs.editBbox,
      ocrItemBboxs,
      mulchEditMode,
      canvasPointRatio,
    )
  }

  // ocrItemボックスの編集モードの場合
  else {
    newBboxs.ocrItemBboxs = newOcrItemBboxArray(
      ocrItemBboxs,
      currentOcrItemId,
      startPosition,
      clickPosition,
      canvasPointRatio,
    )
  }

  return newBboxs
}

/**
 * マウスクリック終了時に複数座標編集ボックスのサイズを決める処理
 * @param event
 * @param rect
 * @param isEditMulchBboxMode
 * @param ocrItemBboxs
 * @param mulchEditBbox
 * @param startPosition
 * @param mulchEditMode
 * @param currentOcrItemId
 * @param canvasPointRatio
 * @returns
 */
export const handleMouseUpProcess = (
  event: React.MouseEvent<HTMLCanvasElement>,
  rect: DOMRect,
  isEditMulchBboxMode: boolean,
  ocrItemBboxs: boundingBoxType[],
  mulchEditBbox: Bbox | null,
  startPosition: Point2D,
  mulchEditMode: mulchEditModeType | null,
  currentOcrItemId: number,
  canvasPointRatio: Size2D,
) => {
  const newBbox = handleMouseMoveProcess(
    event,
    rect,
    isEditMulchBboxMode,
    ocrItemBboxs,
    mulchEditBbox,
    startPosition,
    mulchEditMode,
    currentOcrItemId,
    canvasPointRatio,
  )

  if (!newBbox.editBbox) return newBbox

  // 複数座標編集ボックス内のocrItemBboxの各点を集計
  const xmins: number[] = []
  const xmaxs: number[] = []
  const ymins: number[] = []
  const ymaxs: number[] = []

  let selectedCount = 0
  newBbox.ocrItemBboxs.forEach((value) => {
    if (value.isMulchSelected) {
      xmins.push(value.xmin)
      xmaxs.push(value.xmax)
      ymins.push(value.ymin)
      ymaxs.push(value.ymax)
      selectedCount += 1
    }
  })

  // 複数座標編集ボックス内のocrItemBboxが無いならサイズ調整は終了
  if (selectedCount == 0) return newBbox

  // 複数座標編集ボックスを編集ボックス内のocrItemBboxの最小最大点に合わせる
  const mostXmin = Math.min(...xmins)
  const mostXmax = Math.max(...xmaxs)
  const mostYmin = Math.min(...ymins)
  const mostYmax = Math.max(...ymaxs)

  newBbox.editBbox = new Bbox(mostXmin, mostXmax, mostYmin, mostYmax)

  return newBbox
}
