import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { RecoilRoot } from 'recoil'
import { RouterProvider, Outlet } from 'react-router-dom'

import { router } from './Routes'
import { ApiError } from './api/apiError'
import { useToast } from '@chakra-ui/react'
import { notLoginedMessage } from './values'
import LogRocket from 'logrocket'

function App() {
  const toast = useToast()
  const id = 'pw-expire-toast'

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60000, // 1000 * 60 * 1
        retry: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        if (error instanceof ApiError) {
          if (error.statusCode === 401 && error.message === 'pw is expired') {
            if (toast.isActive(id)) return
            toast({
              id,
              description:
                'パスワードの有効期限が切れています。パスワードを更新するまで各機能を使うことができません。',
              status: 'error',
              isClosable: true,
            })
          }
        }
        if (error instanceof Error) {
          if (
            error.message === notLoginedMessage &&
            window.location.pathname !== '/login'
          ) {
            window.location.href = '/login'
          }
        }
      },
    }),
  })

  // init log rocket
  const log_rocket_id = '' + process.env.REACT_APP_LOG_ROCKET_ID

  if (process.env.REACT_APP_IS_LOGGING_LOG_ROCKET == 'true') {
    LogRocket.init(log_rocket_id, {
      network: {
        requestSanitizer: (request) => {
          if (
            request.url.toLowerCase().indexOf('graphql') !== -1 ||
            request.url.toLowerCase().indexOf('login') !== -1 ||
            request.url.toLowerCase().indexOf('change-password') !== -1 ||
            request.url.toLowerCase().indexOf('regist-patterns') !== -1
          ) {
            request.body = '[REMOVED]'
          }

          return request
        },
        responseSanitizer: (responce) => {
          responce.body = '[REMOVED]'
          return responce
        },
      },
    })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RouterProvider router={router} />
        <Outlet />
      </RecoilRoot>
    </QueryClientProvider>
  )
}

export default App
